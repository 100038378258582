import InlineEditableText from '../../components/InlineEditableText'
import { makeStyles, Theme } from '@material-ui/core'
import { TaskViewModel } from '../api/task'
import { useTaskMutations } from '../hooks/use-task-mutations'
import { useI18n } from '../../hooks/use-i18n'
import { StringMap } from '../../types/common'

const TaskCardTitle = ({ task }: { task: TaskViewModel }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { updateInfo } = useTaskMutations()
  const onSaveTitle = (title: string) => updateInfo(task.id, { title })

  return (
    <InlineEditableText
      placeholder={translations.titlePlaceholder}
      value={task.title}
      onSave={onSaveTitle}
      TypographyProps={{ variant: 'h5', className: classes.taskTitle }}
      isEditable={task.canUpdateDetails}
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.calendarForm || {}) as StringMap

  const { titlePlaceholder = defaults.titlePlaceholder } = translations

  return {
    titlePlaceholder,
  }
}

const defaultTranslations = {
  titlePlaceholder: 'Enter title',
}

const useStyles = makeStyles((theme: Theme) => ({
  taskTitle: {
    '& span, & textarea': {
      maxWidth: 'none',
      textWrap: 'inherit',
    },
  },
}))

export default TaskCardTitle

type Translations = typeof defaultTranslations
