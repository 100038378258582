import { ICustomerNormalized } from '../../customers/interfaces/customer-normalized'
import { IMembershipNormalized } from '../../memberships/interfaces/membership-normalized'
import { CustomerTypeEnum } from '../../types/customer'
import { ResourceType } from './ResourceManager'

export const adaptMembershipToResource = (membership: IMembershipNormalized): ResourceType => {
  return {
    id: membership.id,
    name: membership.fullname,
  }
}

export const adaptCustomerToResource = (customer: ICustomerNormalized) => {
  if (customer.type === CustomerTypeEnum.organisation) {
    return {
      id: customer.id,
      name: customer.name,
    }
  } else {
    return {
      id: customer.id,
      name: `${customer.firstname} ${customer.lastname}`,
    }
  }
}

export const adaptProjectToResource = (project: { id: string; title: string }): ResourceType => {
  return {
    id: project.id,
    name: project.title,
  }
}

export const adaptTaskToResource = (task: { id: string; title: string }): ResourceType => {
  return {
    id: task.id,
    name: task.title,
  }
}
