import { buildUrl } from '../../utils/url'

// eslint-disable-next-line
export const makeReminderApiEndpoints = (reminderId = ':reminderId') => {
  return {
    reminders: (params?: URLSearchParams) => buildUrl('/reminders', params),
    reminderItem: (id = reminderId) => `/reminders/${id}`,
    reminderRecipients: (id = reminderId) => `/reminders/${id}/recipients`,
    reminderNote: (id = reminderId) => `/reminders/${id}/note`,
    reminderTrigger: (id = reminderId) => `/reminders/${id}/trigger`,
  }
}
