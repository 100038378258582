import { TreeGridColors } from '../../treegrid/types'
import { MaintainerTypeEnum, Status } from '../../types/common'
import { TaskResponse } from '../api/task-response'

export const makeTaskViewModel = (task: TaskResponse): TaskViewModel => {
  const isOrgTask = task.maintainerType === MaintainerTypeEnum.ORG
  const isUserTask = task.maintainerType === MaintainerTypeEnum.USER
  const title = task.title === 'Untitled task' ? '' : task.title
  const shownTaskNumber = task.customTaskNumber || task.taskNumber

  return {
    ...task,
    ganttBarColor: task.ganttBarColor || 'Default',
    title,
    shownTaskNumber,
    isOrgTask,
    isUserTask,
    isCompleted: task.status === Status.COMPLETED,
    isNotStarted: task.status === Status.NOT_STARTED,
    isInProgress: task.status === Status.IN_PROGRESS,
    isRejected: task.status === Status.REJECTED,
  }
}

export type TaskViewModel = TaskResponse & {
  ganttBarColor: TreeGridColors
  shownTaskNumber: string
  isOrgTask: boolean
  isUserTask: boolean
  isCompleted: boolean
  isNotStarted: boolean
  isInProgress: boolean
  isRejected: boolean
}
