import { endOfDay, format, isValid, parse, startOfDay } from 'date-fns'
import { find, includes, isArray } from 'lodash'
import { ISO_DATE_REGEX } from '../constants'
import { countries } from '../constants/countries'
import { TimeZoneType } from '../constants/timezones'
import { getDefaultDateFormat } from '../utils'
import { invariant } from '../utils/invariant'

export class DateTimeService {
  private dateFormat: string
  private timeFormat: string
  private enableTimeComponent: boolean
  private timeZone: TimeZoneType

  private getCountryFromTimezone() {
    const timezone = this.getTimeZone()
    return find(countries, ({ timezones }) => {
      return isArray(timezones) && includes(timezones, timezone)
    })
  }

  static isValidDate(value: Date | string): boolean {
    if (value instanceof Date) return !isNaN(value as any)
    if (typeof value === 'string') return ISO_DATE_REGEX.test(value)
    return false
  }

  static getOsTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneType
  }

  constructor({ enableTimeComponent = false, ...options }: DateTimeServiceOptions = {}) {
    this.enableTimeComponent = enableTimeComponent
    this.timeZone = options.timeZone || (this.getOsTimeZone() as TimeZoneType)
    this.dateFormat = options.dateFormat || this.getDateFormat()
    this.timeFormat = options.timeFormat || this.getTimeFormat()
  }

  format(date: Date | string, formatStr = this.getFormat()) {
    if (DateTimeService.isValidDate(date)) {
      return format(new Date(date), formatStr)
    } else {
      throw new Error('Date is invalid')
    }
  }

  formatWithoutYear(date: Date | string, formatStr = this.getFormat()) {
    if (DateTimeService.isValidDate(date)) {
      let formattedDate = format(new Date(date), formatStr).replace(new Date(date).getFullYear().toString(), '')
      const lastChar = formattedDate.charAt(formattedDate.length - 1)
      const firstChar = formattedDate.charAt(0)
      if (!Number(firstChar) && firstChar !== '0') {
        formattedDate = formattedDate.substring(1)
      }
      if (!Number(lastChar)) {
        formattedDate = formattedDate.substring(0, formattedDate.length - 1)
      }
      return formattedDate
    } else {
      throw new Error('Date is invalid')
    }
  }

  convertToTimeZoneAndFormat(
    date: Date | string,
    formatStr = this.getFormat(),
    timeZone: TimeZoneType = this.timeZone
  ) {
    invariant(DateTimeService.isValidDate(date), 'Date is invalid')
    const timezonedDate = new Date(new Date(date).toLocaleString('en-US', { timeZone }))
    return format(timezonedDate, formatStr)
  }

  getOsTimeZone() {
    return DateTimeService.getOsTimeZone()
  }

  getTimeZone() {
    return this.timeZone
  }

  getDateFormat() {
    const country = this.getCountryFromTimezone()
    return this.dateFormat || country?.preferredDateFormat || getDefaultDateFormat()
  }

  getTimeFormat() {
    return this.timeFormat || 'HH:mm'
  }

  getFormat() {
    if (this.enableTimeComponent) return `${this.dateFormat} ${this.timeFormat}`
    else return `${this.dateFormat}`
  }

  getDate(dateString: string, formatString = this.getFormat(), date = new Date()): Date | null {
    const parsed = parse(dateString, formatString, date)
    const isValidDate = isValid(parsed)
    return isValidDate ? parsed : null
  }

  getEnableTimeComponent(): boolean {
    return this.enableTimeComponent
  }

  setEnableTimeComponent(enableTimeComponent: boolean) {
    this.enableTimeComponent = enableTimeComponent
  }

  getMinutes(date: Date | string, timeZone: TimeZoneType = this.getTimeZone()): number {
    invariant(DateTimeService.isValidDate(date), 'Date is invalid')
    invariant(timeZone, 'Timezone is required')
    return new Date(new Date(date).toLocaleString('en-US', { timeZone })).getMinutes()
  }

  getHours(date: Date | string, timeZone: TimeZoneType = this.getTimeZone()): number {
    invariant(DateTimeService.isValidDate(date), 'Date is invalid')
    invariant(timeZone, 'Timezone is required')
    return new Date(new Date(date).toLocaleString('en-US', { timeZone })).getHours()
  }

  getDoubleDigitTime(date: Date | string, timeZone: TimeZoneType = this.getTimeZone()): string {
    const hours = this.getHours(date, timeZone)
    const minutes = this.getMinutes(date, timeZone)
    const doubleDigitHours = hours < 10 ? `0${hours}` : hours
    const doubleDigitMinutes = minutes < 10 ? `0${minutes}` : minutes
    return `${doubleDigitHours}:${doubleDigitMinutes}`
  }

  isMidnight(date: Date | string, timeZone: TimeZoneType = this.getTimeZone()) {
    invariant(DateTimeService.isValidDate(date), 'Date is invalid')
    const dateInstance = new Date(date)
    const hasMidnightHours = this.getHours(dateInstance, timeZone) === 0
    const hasMidnightMinutes = this.getMinutes(dateInstance, timeZone) === 0
    const hasMidnightSeconds = dateInstance.getSeconds() === 0
    const hasMidnightMilliseconds = dateInstance.getMilliseconds() === 0
    return hasMidnightHours && hasMidnightMinutes && hasMidnightSeconds && hasMidnightMilliseconds
  }

  hasMidnightTime(date: Date | string) {
    if (DateTimeService.isValidDate(date)) {
      const validDate = new Date(date)
      const time = validDate.getMinutes()
      const hour = validDate.getHours()
      return time === 0 && hour === 0
    } else {
      throw new Error('Date is invalid')
    }
  }

  isPastDate(date: Date) {
    return new Date(date) < this.startOfDay(new Date())
  }

  selectedTzAndOsTzAreDifferent() {
    return this.timeZone !== this.getOsTimeZone()
  }

  removeTimezoneOffset(date: Date | string, baseTimeZone: TimeZoneType = this.getOsTimeZone()): Date {
    if (baseTimeZone === this.timeZone) return new Date(date)
    if (DateTimeService.isValidDate(date)) {
      const offset = this.calculateTimezoneOffSet(new Date(date), baseTimeZone, this.timeZone)
      const newDAte = new Date(date).getTime() - offset * 60 * 1000
      return new Date(newDAte)
    } else {
      throw new Error('Date is invalid')
    }
  }

  addTimezoneOffset(date: Date | string, baseTimeZone: TimeZoneType = this.getOsTimeZone()): Date {
    if (baseTimeZone === this.timeZone) return new Date(date)
    if (DateTimeService.isValidDate(date)) {
      const offset = this.calculateTimezoneOffSet(new Date(date), baseTimeZone, this.timeZone)
      const newDAte = new Date(date).getTime() + offset * 60 * 1000
      return new Date(newDAte)
    } else {
      throw new Error('Date is invalid')
    }
  }

  startOfDay(date: Date): Date {
    const timeZoneOffSet = this.calculateTimezoneOffSet(date, this.getOsTimeZone(), this.timeZone)
    const dateInDestinationTz = date.getTime() + timeZoneOffSet * 60 * 1000
    const startOfDayDate = new Date(startOfDay(dateInDestinationTz).getTime() - timeZoneOffSet * 60 * 1000)
    return startOfDayDate
  }

  endOfDay(date: Date): Date {
    const timeZoneOffSet = this.calculateTimezoneOffSet(date, this.getOsTimeZone(), this.timeZone)
    const dateInDestinationTz = date.getTime() + timeZoneOffSet * 60 * 1000
    const endOfDayDate = new Date(endOfDay(dateInDestinationTz).getTime() - timeZoneOffSet * 60 * 1000)
    return endOfDayDate
  }

  calculateTimezoneOffSet(date: Date, baseTimeZone: TimeZoneType, differentTimeZone: TimeZoneType): number {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
    const baseTimeZoneDate = new Date(date.toLocaleString('en-US', { timeZone: baseTimeZone }))
    const differentTimeZoneDate = new Date(date.toLocaleString('en-US', { timeZone: differentTimeZone }))
    const baseTzOffSet = (utcDate.getTime() - baseTimeZoneDate.getTime()) / 6e4
    const differentTzOffSet = (utcDate.getTime() - differentTimeZoneDate.getTime()) / 6e4
    return differentTzOffSet - baseTzOffSet
  }

  getLargestPossibleDate() {
    return new Date(8.64e15)
  }
}

export type DateTimeServiceOptions = {
  dateFormat?: string
  timeFormat?: string
  enableTimeComponent?: boolean
  timeZone?: TimeZoneType
}
