import React from 'react'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import { useTodoMutations } from './use-todo-mutations'
import { StringMap } from '../../types/common'
import { useI18n } from '../../hooks/use-i18n'

export const useDeleteTodo = () => {
  const translations = useTranslations()
  const { deleteTodo } = useTodoMutations()
  const [status, setStatus] = React.useState('idle')

  const onConfirmDeleteTodo = async (todoId: string) => {
    const deleted = await deleteTodo(todoId)
    if (deleted) return setStatus(`deleted:${todoId}`)
    return setStatus(`error:${todoId}`)
  }

  const onCancelDeleteTodo = (todoId: string) => setStatus(`cancelled:${todoId}`)
  const resetStatus = () => setStatus('idle')

  const { confirm: deleteTodoWithConfirmation } = useConfirmDialog({
    onConfirm: onConfirmDeleteTodo,
    onCancel: onCancelDeleteTodo,
    primaryActionButtonLabel: translations.deleteTodoDialog.primaryActionButtonLabel,
    secondaryActionButtonLabel: translations.deleteTodoDialog.secondaryActionButtonLabel,
    title: translations.deleteTodoDialog.title,
    text: translations.deleteTodoDialog.text,
  })

  return {
    deleteTodo: deleteTodoWithConfirmation,
    status,
    resetStatus,
  }
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: todoTranslations } = useI18n('todo')
  const { translations: t } = useI18n('translation')
  const confirmationDialog = t?.confirmationDialog || ({} as StringMap)
  const deleteTodoDialogTranslations = (todoTranslations?.deleteTodoDialog || {}) as StringMap
  const deleteTodoDialog = {
    title: deleteTodoDialogTranslations?.title || defaults.deleteTodoDialog.title,
    text: deleteTodoDialogTranslations?.text || defaults.deleteTodoDialog.text,
    primaryActionButtonLabel:
      deleteTodoDialogTranslations?.primaryActionButtonLabel || defaults.deleteTodoDialog.primaryActionButtonLabel,
    secondaryActionButtonLabel: confirmationDialog.secondaryActionButtonLabel,
  }

  return {
    deleteTodoDialog,
  }
}

const defaultTranslations = {
  deleteTodoDialog: {
    title: 'Do you want to delete this todo?',
    text: 'This action will erase the todo from database and it can not be recovered.',
    primaryActionButtonLabel: 'Delete todo',
    secondaryActionButtonLabel: 'Cancel',
  },
}

type Translations = typeof defaultTranslations
