import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core'

const NotificationSettingItem = ({
  enabled,
  label,
  description,
  onEnableNotification,
  onDisableNotification,
}: NotificationSettingItemProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className="left">
        <Checkbox
          checked={enabled}
          color="primary"
          onChange={(e) => (e.target.checked ? onEnableNotification() : onDisableNotification())}
        />
      </div>
      <div className="right">
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '& .right': {
      marginLeft: theme.spacing(2.5),
    },
    '& .MuiTypography-subtitle1': {
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}))

export default NotificationSettingItem

type NotificationSettingItemProps = {
  enabled: boolean
  label: string
  description: string
  onEnableNotification: () => void
  onDisableNotification: () => void
}
