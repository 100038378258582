import { api } from '../../api'
import { BoardViewModel, makeBoardViewModel } from '../../boards/api/board'
import { BoardResponse } from '../../boards/api/board-response'
import { TodoResponse } from './todo-response'
import { makeTodoApiEndpoints } from './endpoints'
import { TodoViewModel, makeTodoViewModel } from './todo'

const apiEndpoints = makeTodoApiEndpoints()

export const todoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTodos: builder.query<TodoViewModel[], TodoQueryParams | void>({
      query: (params) => ({
        method: 'GET',
        url: apiEndpoints.todos(),
        params: params ? new URLSearchParams(params) : undefined,
      }),
      transformResponse: (response: { todos: TodoResponse[] }) => response.todos.map(makeTodoViewModel),
      providesTags: (result = []) => ['Todo', ...result.map(({ id }) => ({ type: 'Todo', id } as const))],
    }),
    createTodo: builder.mutation<TodoViewModel, NewTodoData>({
      query: (todoData) => ({
        method: 'POST',
        url: apiEndpoints.todos(),
        body: todoData,
      }),
      transformResponse: makeTodoViewModel,
      invalidatesTags: ['Todo'],
    }),
    updateTodoInfo: builder.mutation<TodoViewModel, { todoId: string } & TodoInfoUpdateData>({
      query: ({ todoId, ...todoData }) => ({
        method: 'PUT',
        url: apiEndpoints.todoItem(todoId),
        body: todoData,
      }),
      transformResponse: makeTodoViewModel,
      invalidatesTags: (result) => ['Todo', { type: 'Todo', id: result?.id }],
    }),
    updateTodoStatus: builder.mutation<UpdateTodoWithBoardReturnData, { todoId: string } & TodoStatusUpdateData>({
      query: ({ todoId, ...todoData }) => ({
        method: 'PATCH',
        url: apiEndpoints.todoStatus(todoId),
        body: todoData,
      }),
      transformResponse: transformTodoWithBoard,
      invalidatesTags: (result) => [
        'Todo',
        { type: 'Todo', id: result?.todo.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    updateTodoProject: builder.mutation<UpdateTodoWithBoardReturnData, { todoId: string } & TodoProjectUpdateData>({
      query: ({ todoId, projectId, boardId }) => ({
        method: 'PATCH',
        url: apiEndpoints.projectRelation(todoId),
        body: { projectId, boardId },
      }),
      transformResponse: transformTodoWithBoard,
      invalidatesTags: (result) => [
        'Todo',
        { type: 'Todo', id: result?.todo.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    updateTodoTask: builder.mutation<UpdateTodoWithBoardReturnData, { todoId: string } & TodoTaskUpdateData>({
      query: ({ todoId, taskId, boardId }) => ({
        method: 'PATCH',
        url: apiEndpoints.taskRelation(todoId),
        body: { taskId, boardId },
      }),
      transformResponse: transformTodoWithBoard,
      invalidatesTags: (result) => [
        'Todo',
        { type: 'Todo', id: result?.todo.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    updateTodoWorkspaces: builder.mutation<UpdateTodoWithBoardReturnData, { todoId: string } & TodoWorkspaceUpdateData>(
      {
        query: ({ todoId, workspaces, boardId }) => ({
          method: 'PATCH',
          url: apiEndpoints.workspacesRelation(todoId),
          body: { workspaces, boardId },
        }),
        transformResponse: transformTodoWithBoard,
        invalidatesTags: (result) => [
          'Todo',
          { type: 'Todo', id: result?.todo.id },
          { type: 'Board', id: result?.board?.id },
        ],
      }
    ),
    updateTodoResponsible: builder.mutation<
      UpdateTodoWithBoardReturnData,
      { todoId: string } & TodoResponsibleUpdateData
    >({
      query: ({ todoId, responsible, boardId }) => ({
        method: 'PATCH',
        url: apiEndpoints.responsibleRelation(todoId),
        body: { responsible, boardId },
      }),
      transformResponse: transformTodoWithBoard,
      invalidatesTags: (result) => [
        'Todo',
        { type: 'Todo', id: result?.todo.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    updateTodoCustomers: builder.mutation<UpdateTodoWithBoardReturnData, { todoId: string } & TodoCustomerUpdateData>({
      query: ({ todoId, customers, boardId }) => ({
        method: 'PATCH',
        url: apiEndpoints.customersRelation(todoId),
        body: { customers, boardId },
      }),
      transformResponse: transformTodoWithBoard,
      invalidatesTags: (result) => [
        'Todo',
        { type: 'Todo', id: result?.todo.id },
        { type: 'Board', id: result?.board?.id },
      ],
    }),
    deleteTodo: builder.mutation<boolean, string>({
      query: (todoId) => ({
        method: 'DELETE',
        url: apiEndpoints.todoItem(todoId),
      }),
      transformResponse: () => true,
      invalidatesTags: ['Todo'],
    }),
  }),
})

function transformTodoWithBoard({ todo, board }: UpdateTodoWithBoardResponseData) {
  return {
    todo: makeTodoViewModel(todo),
    board: board ? makeBoardViewModel(board) : null,
  }
}
export const {
  useGetTodosQuery,
  useCreateTodoMutation,
  useUpdateTodoInfoMutation,
  useUpdateTodoStatusMutation,
  useUpdateTodoProjectMutation,
  useUpdateTodoTaskMutation,
  useUpdateTodoWorkspacesMutation,
  useUpdateTodoResponsibleMutation,
  useUpdateTodoCustomersMutation,
  useDeleteTodoMutation,
} = todoApi

type TodoBaseQuery = { completed?: 'true' | 'false' }
type OrgTodoQueryParams = { organisation: string }
type CustomerTodoQueryParams = { customer: string }
type ResponsibleTodoQueryParams = { responsible: string }
type WorkspaceTodoQueryParams = { workspace: string }
type ProjectTodoQueryParams = { project: string }
type TaskTodoQueryParams = { task: string }

export type TodoQueryParams = TodoBaseQuery &
  (
    | {}
    | OrgTodoQueryParams
    | CustomerTodoQueryParams
    | ResponsibleTodoQueryParams
    | WorkspaceTodoQueryParams
    | ProjectTodoQueryParams
    | TaskTodoQueryParams
  )

export type NewTodoData = {
  title: string
  description?: string | null
  organisation?: string | null
  responsible?: string[] // membership id if todo is in organisation, empty if todo is in personal context
  dueDate?: string | null
  project?: string | null
  task?: string | null
  workspaces?: string[]
  customers?: string[]
  enableTimeComponent?: boolean
}

export type TodoInfoUpdateData = {
  title?: string
  description?: string | null
  dueDate?: string | null
  enableTimeComponent?: boolean
  ganttBarColor?: string | null
}

export type TodoStatusUpdateData = {
  completed: boolean
  boardId?: string
}

export type TodoProjectUpdateData = {
  projectId: string | null
  boardId?: string
}

export type TodoTaskUpdateData = {
  taskId: string | null
  boardId?: string
}

export type TodoWorkspaceUpdateData = {
  workspaces: string[]
  boardId?: string
}

export type TodoResponsibleUpdateData = {
  responsible: string[]
  boardId?: string
}

export type TodoCustomerUpdateData = {
  customers: string[]
  boardId?: string
}

type UpdateTodoWithBoardReturnData = {
  todo: TodoViewModel
  board: BoardViewModel | null
}

type UpdateTodoWithBoardResponseData = {
  todo: TodoResponse
  board: BoardResponse | null
}
