import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControlHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import React from 'react'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import DatePicker from '../../components/DatePicker'
import { Trash } from 'react-feather'
import { ReminderViewModel } from '../api/reminder'
import { alpha, makeStyles, RadioGroup, Theme } from '@material-ui/core'
import { useZodSchemas } from '../../hooks/use-zod-schemas'
import { ReminderRecipientRole, ReminderTimeDetails, ReminderTriggerType } from '../api/reminder-response'
import { useReminderMutations } from '../hooks/use-reminder-mutations'
import { DateTimeService } from '../../services/date-time-service'
import { isDate, uniq } from 'lodash'
import { useAuthUserLocale } from '../../users/hooks/use-auth-user-locale'
import { useI18n } from '../../hooks'
import { guessReminderTime } from '../utils/guess-reminder-time'
import { addDays } from 'date-fns'

const PROJECT_ROLE_RECIPIENTS: ReminderRecipientRole[] = ['projectManager', 'projectParticipants']
const TASK_ROLE_RECIPIENTS: ReminderRecipientRole[] = [
  'taskManager',
  'taskParticipants',
  'managerOfParentTask',
  'managersOfDependentTasks',
  'projectManager',
]
const CALENDAR_EVENT_ROLE_RECIPIENTS: ReminderRecipientRole[] = ['calendarEventParticipants']

const ReminderForm = ({ reminder, refStartDate, refEndDate }: ReminderFormProps) => {
  const translations = useTranslations()
  const { recipients, reminderTime } = reminder
  const classes = useStyles()
  const { updateReminderRecipients, updateReminderTrigger, updateReminderNote } = useReminderMutations()
  const { timeZone, dateFormat, dateTimeFormat } = useAuthUserLocale()
  const dateTimeService = new DateTimeService({ timeZone, dateFormat, enableTimeComponent: true })
  const timeZonedReminderTime = reminderTime && dateTimeService.removeTimezoneOffset(reminderTime)
  const formattedReminderTime = timeZonedReminderTime && dateTimeService.format(timeZonedReminderTime)
  const timeZonedMinDate = dateTimeService.removeTimezoneOffset(new Date())
  const initialReminderTimeDetails = getInitialReminderTimeDetails({ reminder, refStartDate, refEndDate })
  const roleRecipientOptions = reminder.isCalendarEventReminder
    ? CALENDAR_EVENT_ROLE_RECIPIENTS
    : reminder.isTaskReminder
    ? TASK_ROLE_RECIPIENTS
    : PROJECT_ROLE_RECIPIENTS

  const handleChangeReminderTimeDetails = (updates: Partial<ReminderTimeDetails>) => {
    const hasPlan = refStartDate || refEndDate
    if (!hasPlan) return
    const updatedReminderTimeDetails = getUpdatedReminderTimeDetails({
      prev: initialReminderTimeDetails,
      updates,
      refStartDate,
      refEndDate,
    })
    updateReminderTrigger(reminder.id, {
      triggerType: 'relativeTime',
      reminderTimeDetails: updatedReminderTimeDetails,
    })
  }

  const toggleRoleRecipient = (role: ReminderRecipientRole) => {
    const prevRoles = reminder.recipients.roles
    const isCurrentlyChecked = prevRoles.includes(role)
    const nextRoles = isCurrentlyChecked ? prevRoles.filter((r) => r !== role) : [...prevRoles, role]
    updateReminderRecipients(reminder.id, { roles: nextRoles })
  }

  const updateRecipientEmails = (emails: string[]) => {
    updateReminderRecipients(reminder.id, { emails })
  }

  const notificationTimeSummary = reminder.triggerStatus
    ? translations.notificationStatusSummary.replace('{status}', translations[reminder.triggerStatus])
    : reminder.isExpired
    ? translations.notificationExpiredSummary
    : `${translations.notificationTimeSummary} ${formattedReminderTime} (${timeZone}).`
  const isRelativeTimeReminderTimeOptionVisible = !!refStartDate || !!refEndDate

  const handleChangeReminderTime = (reminderTime: Date | null) => {
    if (!reminderTime) return
    const timeZonedReminderTime = dateTimeService.addTimezoneOffset(reminderTime)
    updateReminderTrigger(reminder.id, {
      triggerType: 'absoluteTime',
      reminderTime: timeZonedReminderTime.toISOString(),
    })
  }

  const handleChangeReminderTriggerType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newReminderTriggerType = e.target.value as ReminderTriggerType

    if (newReminderTriggerType === 'absoluteTime') {
      updateReminderTrigger(reminder.id, {
        triggerType: 'absoluteTime',
        reminderTime: reminder.reminderTime?.toISOString?.() || addDays(new Date(), 1).toISOString(),
      })
    }

    if (newReminderTriggerType === 'relativeTime') {
      handleChangeReminderTimeDetails({})
    }

    if (newReminderTriggerType === 'statusChange') {
      updateReminderTrigger(reminder.id, { triggerType: 'statusChange', triggerStatus: 'completed' })
    }
  }

  const handleChangeTriggerStatus = (e: React.ChangeEvent<{ value: unknown }>) => {
    updateReminderTrigger(reminder.id, {
      triggerType: 'statusChange',
      triggerStatus: e.target.value as 'completed' | 'inProgress',
    })
  }

  return (
    <Box className={classes.root}>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel className={classes.h6FormLabel}>{translations.notificationTimeLabel}</FormLabel>
        <Alert className={classes.alert} variant="filled" severity={reminder.isExpired ? 'error' : 'info'}>
          <AlertTitle>{notificationTimeSummary}</AlertTitle>
        </Alert>
        <RadioGroup
          value={reminder.triggerType}
          onChange={handleChangeReminderTriggerType}
          style={{ alignItems: 'flex-start' }}
        >
          <FormControlLabel
            value="absoluteTime"
            control={<Radio />}
            label={translations.absoluteNotificationTimeLabel}
            disabled={reminder.isReadOnly}
          />
          {reminder.isAbsoluteTimeReminder && (
            <Box className={classes.reminderTimeInputContainer}>
              <DatePicker
                onChange={handleChangeReminderTime}
                dateFormat={dateTimeFormat}
                selected={timeZonedReminderTime}
                minDate={timeZonedMinDate}
                isClearable={false}
                textFieldProps={{ inputProps: { disabled: reminder.isReadOnly } }}
                showTimeSelect
              />
            </Box>
          )}
          {isRelativeTimeReminderTimeOptionVisible ? (
            <FormControlLabel
              value="relativeTime"
              control={<Radio />}
              label={translations.relativeNotificationTimeLabel}
              disabled={reminder.isReadOnly}
            />
          ) : null}
          {reminder.isRelativeTimeReminder && (
            <Box className={classes.reminderTimeRowContainer}>
              <Box className={classes.reminderTimeRow}>
                <TextField
                  value={initialReminderTimeDetails.value}
                  onChange={(e) => handleChangeReminderTimeDetails({ value: parseInt(e.target.value) })}
                  size="small"
                  type="number"
                  variant="outlined"
                  className={classes.numberTextField}
                  inputProps={{ min: 1, max: 23 }}
                  InputProps={{ classes: { input: classes.numberInput } }}
                  disabled={reminder.isReadOnly}
                />
                <FormControl size="small" variant="outlined" disabled={reminder.isReadOnly}>
                  <Select
                    value={initialReminderTimeDetails.unit}
                    onChange={(e) =>
                      handleChangeReminderTimeDetails({ unit: e.target.value as 'hours' | 'days' | 'weeks' })
                    }
                  >
                    <MenuItem value="hours">{translations.hours}</MenuItem>
                    <MenuItem value="days">{translations.days}</MenuItem>
                    <MenuItem value="weeks">{translations.weeks}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" disabled={reminder.isReadOnly}>
                  <Select
                    value={initialReminderTimeDetails.isAfter ? 'after' : 'before'}
                    onChange={(e) =>
                      handleChangeReminderTimeDetails({ isAfter: e.target.value === 'after' ? true : false })
                    }
                  >
                    <MenuItem value="before">{translations.before}</MenuItem>
                    <MenuItem value="after">{translations.after}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" disabled={reminder.isReadOnly}>
                  <Select
                    value={initialReminderTimeDetails.reference}
                    onChange={(e) =>
                      handleChangeReminderTimeDetails({ reference: e.target.value as 'plannedStart' | 'plannedEnd' })
                    }
                  >
                    <MenuItem value="plannedStart" disabled={!refStartDate}>
                      {translations.plannedStartDate}
                    </MenuItem>
                    <MenuItem value="plannedEnd" disabled={!refEndDate}>
                      {translations.plannedEndDate}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
          {!reminder.isPersonal && !reminder.isCalendarEventReminder && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                value="statusChange"
                control={<Radio />}
                label={translations.notificationStatusLabel}
                disabled={reminder.isReadOnly}
              />

              <Box sx={{ ml: -1, display: 'flex', alignItems: 'center' }}>
                <FormControl size="small" variant="outlined" disabled={reminder.isReadOnly}>
                  <Select
                    value={reminder.triggerStatus || 'completed'}
                    onChange={handleChangeTriggerStatus}
                    className={classes.statusSelect}
                  >
                    <MenuItem value="completed">{translations.completed}</MenuItem>
                    <MenuItem value="inProgress">{translations.inProgress}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
        </RadioGroup>
      </FormControl>

      {reminder.isPersonal ? null : (
        <Box component="section">
          <FormControl>
            <FormLabel className={classes.h6FormLabel}>{translations.notificationRecipientsLabel}</FormLabel>
            <FormControlHelperText>{translations.notificationRecipientHelperText}</FormControlHelperText>
            <FormGroup className={classes.formGroup}>
              <Typography variant="subtitle2" component="legend" gutterBottom>
                {translations.roleRecipients}
              </Typography>
              {roleRecipientOptions.map((role) => (
                <FormControlLabel
                  label={translations[role]}
                  control={
                    <Checkbox
                      color="primary"
                      checked={recipients.roles.includes(role)}
                      onChange={() => toggleRoleRecipient(role)}
                      disabled={checkIsDisabled(reminder, role)}
                    />
                  }
                />
              ))}
            </FormGroup>
            <RecipientEmails
              emails={reminder.recipients.emails}
              onUpdateRecipientEmails={updateRecipientEmails}
              isInputDisabled={checkIsDisabled(reminder, 'email')}
              isAddEmailDisabled={reminder.isReadOnly}
            />
          </FormControl>
        </Box>
      )}
      <Box component="section">
        <FormControl style={{ width: '100%' }}>
          <FormLabel className={classes.h6FormLabel}>{translations.addNoteLabel}</FormLabel>
          <TextField
            defaultValue={reminder.note || ''}
            variant="outlined"
            onBlur={(e) => updateReminderNote(reminder.id, { note: Boolean(e.target.value) ? e.target.value : null })}
            disabled={reminder.isReadOnly}
            fullWidth
          />
        </FormControl>
      </Box>
    </Box>
  )
}

const RecipientEmails = ({
  emails,
  onUpdateRecipientEmails,
  isInputDisabled,
  isAddEmailDisabled,
}: RecipientEmailsProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [showNewEmailInput, setShowNewEmailInput] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const removeRecipientEmail = (email: string) => {
    const updatedEmails = emails.filter((e) => e !== email)
    onUpdateRecipientEmails(updatedEmails)
  }

  const saveRecipientEmail = (updatedEmail: string, index: number) => {
    const updatedEmails = emails.map((email, idx) => (idx === index ? updatedEmail : email))
    onUpdateRecipientEmails(updatedEmails)
  }

  const addRecipientEmail = (email: string) => {
    if (!email) {
      setShowNewEmailInput(false)
      return
    }

    const updatedEmails = uniq([...emails, email])
    onUpdateRecipientEmails(updatedEmails)
    const inputEl = inputRef.current?.querySelector('input')
    if (inputEl) inputEl.value = ''
  }

  return (
    <FormGroup className={classes.formGroup}>
      <Typography variant="subtitle2" component="legend" gutterBottom>
        {translations.emailRecipients}
      </Typography>
      {emails.map((email, index) => (
        <RecipientEmail
          key={index}
          email={email}
          onSaveRecipientEmail={(email) => saveRecipientEmail(email, index)}
          onRemoveRecipientEmail={removeRecipientEmail}
          isInputDisabled={isInputDisabled}
        />
      ))}
      {showNewEmailInput ? (
        <>
          <RecipientEmail
            ref={inputRef}
            email=""
            onSaveRecipientEmail={addRecipientEmail}
            onRemoveRecipientEmail={removeRecipientEmail}
            autoFocus
          />
          <Box>
            <Button
              style={{ marginRight: 8 }}
              variant="outlined"
              size="small"
              onClick={() => setShowNewEmailInput(false)}
            >
              {translations.add}
            </Button>
            <Button size="small" onClick={() => setShowNewEmailInput(false)}>
              {translations.cancel}
            </Button>
          </Box>
        </>
      ) : (
        <Button
          variant="outlined"
          className={classes.addEmailButton}
          size="small"
          onClick={() => setShowNewEmailInput(true)}
          disabled={isAddEmailDisabled}
        >
          {translations.addEmailRecipientLabel}
        </Button>
      )}
    </FormGroup>
  )
}

const RecipientEmail = React.forwardRef<HTMLInputElement, RecipientEmailProps>(
  ({ email, onSaveRecipientEmail, onRemoveRecipientEmail, isInputDisabled, autoFocus }, ref) => {
    const classes = useStyles()
    const { ZodEmail } = useZodSchemas()
    const [emailError, setEmailError] = React.useState('')
    const [isEditing, setIsEditing] = React.useState(false)

    const saveRecipientEmail = (email: string) => {
      if (email === '') return onSaveRecipientEmail(email)

      const parseResult = ZodEmail.safeParse(email)
      if (parseResult.success) {
        onSaveRecipientEmail(email)
        setEmailError('')
      } else {
        return setEmailError(parseResult.error.errors[0].message)
      }
    }

    const handleBlur = (email: string) => {
      saveRecipientEmail(email)
      setIsEditing(false)
    }

    return (
      <Box key={email} className={classes.emailInputRow}>
        <TextField
          ref={ref}
          size="small"
          variant="outlined"
          defaultValue={email}
          onFocus={() => setIsEditing(true)}
          onBlur={(e) => handleBlur(e.target.value)}
          InputProps={{
            onKeyDown: (e) => e.key === 'Enter' && handleBlur(e.currentTarget.value),
          }}
          error={Boolean(emailError)}
          helperText={emailError}
          autoFocus={autoFocus}
          disabled={isInputDisabled}
          fullWidth
        />
        {email && !isEditing && !isInputDisabled ? (
          <IconButton onClick={() => onRemoveRecipientEmail(email)} color="inherit">
            <Trash size={20} />
          </IconButton>
        ) : null}
      </Box>
    )
  }
)

const defaultTranslations = {
  notificationTimeLabel: 'When to notify?',
  notificationTimeSummary: 'Notification will be sent at',
  notificationExpiredSummary: 'Notification is expired.',
  absoluteNotificationTimeLabel: 'Set a specific date and time',
  relativeNotificationTimeLabel: 'Set a time relative to project dates',
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  before: 'before',
  after: 'after',
  plannedStartDate: 'planned start date',
  plannedEndDate: 'planned end date',
  notificationRecipientsLabel: 'Whom to notify?',
  notificationRecipientHelperText: 'Notification requires at least one recipient.',
  roleRecipients: 'Recipients by role',
  projectManager: 'Project Manager',
  projectParticipants: 'Project Participants',
  taskManager: 'Task responsible',
  taskParticipants: 'Task participants',
  managerOfParentTask: 'Responsible of parent task',
  managersOfDependentTasks: 'Responsible of dependent tasks',
  calendarEventParticipants: 'Calendar event participants',
  emailRecipients: 'Email Recipients',
  addEmailRecipientLabel: 'Add email recipient',
  add: 'Add',
  cancel: 'Cancel',
  addNoteLabel: 'Add a note',
  completed: 'Completed',
  inProgress: 'In progress',
  notificationStatusLabel: 'Send a notification when status changes to',
  notificationStatusSummary: 'Notification will be sent when status changes to {status}',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { t } = useI18n('translation')

  return {
    notificationTimeLabel: t('notificationTimeLabel', defaults.notificationTimeLabel),
    notificationTimeSummary: t('notificationTimeSummary', defaults.notificationTimeSummary),
    notificationStatusSummary: t('notificationStatusSummary', defaults.notificationStatusSummary),
    notificationExpiredSummary: t('notificationExpiredSummary', defaults.notificationExpiredSummary),
    absoluteNotificationTimeLabel: t('absoluteNotificationTimeLabel', defaults.absoluteNotificationTimeLabel),
    relativeNotificationTimeLabel: t('relativeNotificationTimeLabel', defaults.relativeNotificationTimeLabel),
    notificationStatusLabel: t('notificationStatusLabel', defaults.notificationStatusLabel),
    hours: t('hours', defaults.hours),
    days: t('days', defaults.days),
    weeks: t('weeks', defaults.weeks),
    before: t('before', defaults.before),
    after: t('after', defaults.after),
    plannedStartDate: t('plannedStartDate', defaults.plannedStartDate),
    plannedEndDate: t('plannedEndDate', defaults.plannedEndDate),
    notificationRecipientsLabel: t('notificationRecipientsLabel', defaults.notificationRecipientsLabel),
    roleRecipients: t('roleRecipients', defaults.roleRecipients),
    projectManager: t('projectManager', defaults.projectManager),
    projectParticipants: t('projectParticipants', defaults.projectParticipants),
    taskManager: t('taskManager', defaults.taskManager),
    taskParticipants: t('taskParticipants', defaults.taskParticipants),
    managerOfParentTask: t('managerOfParentTask', defaults.managerOfParentTask),
    managersOfDependentTasks: t('managersOfDependentTasks', defaults.managersOfDependentTasks),
    calendarEventParticipants: t('calendarEventParticipants', defaults.calendarEventParticipants),
    emailRecipients: t('emailRecipients', defaults.emailRecipients),
    addEmailRecipientLabel: t('addEmailRecipientLabel', defaults.addEmailRecipientLabel),
    addNoteLabel: t('addNoteLabel', defaults.addNoteLabel),
    add: t('add', defaults.add),
    cancel: t('cancel', defaults.cancel),
    notificationRecipientHelperText: t('notificationRecipientHelperText', defaults.notificationRecipientHelperText),
    completed: t('completed', defaults.completed),
    inProgress: t('inProgress', defaults.inProgress),
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 12,
    background: alpha(theme.palette.primary.main, 0.075),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& > section': {
      padding: theme.spacing(3, 0),
      '& > h6': {
        marginBottom: 12,
      },
    },
  },
  reminderTimeRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    '& > .MuiFormControl-root': {
      marginRight: 12,
    },
  },
  numberTextField: {
    maxWidth: 72,
  },
  numberInput: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'inner-spin-button',
      display: 'inline-flex',
      opacity: 1,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  formGroup: {
    margin: theme.spacing(1.5, 0),
  },
  h6FormLabel: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.15px',
  },
  emailInputRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '& > .MuiTextField-root': {
      minWidth: 300,
      maxWidth: 300,
      marginRight: 8,
    },
  },
  addEmailButton: {
    maxWidth: 300,
    marginTop: 20,
  },
  alert: {
    marginTop: 8,
    marginBottom: 24,
    paddingTop: 8,
    paddingBottom: 0,
    '&.MuiAlert-filledInfo': {
      color: theme.palette.common.black,
      background: alpha(theme.palette.info.main, 0.1),
    },
  },
  reminderTimeInputContainer: { marginLeft: 32, paddingTop: 8, marginBottom: 16, maxWidth: 220 },
  reminderTimeRowContainer: { marginLeft: 32, paddingTop: 8, marginBottom: 16 },
  statusSelect: {
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
}))

const getUpdatedReminderTimeDetails = (params: {
  prev: ReminderTimeDetails
  updates: Partial<ReminderTimeDetails>
  refStartDate: Date | null
  refEndDate: Date | null
}): ReminderTimeDetails => {
  const { prev, updates, refStartDate, refEndDate } = params
  return {
    value: updates.value !== undefined ? updates.value : prev.value,
    unit: updates.unit !== undefined ? updates.unit : prev.unit,
    reference: updates.reference !== undefined ? updates.reference : prev.reference,
    refDate: getRefDate(),
    isAfter: updates.isAfter !== undefined ? updates.isAfter : prev.isAfter,
  }

  function getRefDate() {
    if (updates.reference === 'plannedStart' && refStartDate) return refStartDate
    if (updates.reference === 'plannedEnd' && refEndDate) return refEndDate
    return prev.refDate
  }
}

const getInitialReminderTimeDetails = (params: {
  reminder: ReminderViewModel
  refStartDate: Date | null
  refEndDate: Date | null
}) => {
  const { reminder, refStartDate, refEndDate } = params
  const guessedReminderTime = guessReminderTime(refStartDate, refEndDate)
  const defaultReminderTimeDetails: ReminderTimeDetails = {
    value: 1,
    unit: 'days',
    reference: refStartDate ? 'plannedStart' : 'plannedEnd',
    refDate: refStartDate || refEndDate || new Date(),
    isAfter: false,
  }
  const guessedReminderTimeDetails = isDate(guessedReminderTime) ? defaultReminderTimeDetails : guessedReminderTime
  const initialReminderTimeDetails: ReminderTimeDetails = reminder.reminderTimeDetails || guessedReminderTimeDetails
  return initialReminderTimeDetails
}

const checkIsDisabled = ({ recipients, ...reminder }: ReminderViewModel, field: ReminderRecipientRole | 'email') => {
  const hasSingleRecipient = [...recipients.roles, ...recipients.emails].length === 1
  const isProjectManagerChecked = recipients.roles.includes('projectManager')
  const isProjectParticipantsChecked = recipients.roles.includes('projectParticipants')
  const isTaskManagerChecked = recipients.roles.includes('taskManager')
  const isTaskParticipantsChecked = recipients.roles.includes('taskParticipants')
  const isManagersOfAncestorTasksChecked = recipients.roles.includes('managerOfParentTask')
  const isManagersOfDependentTasksChecked = recipients.roles.includes('managersOfDependentTasks')
  const isCalendarEventParticipantsChecked = recipients.roles.includes('calendarEventParticipants')

  if (field === 'projectManager') return hasSingleRecipient && isProjectManagerChecked
  if (field === 'projectParticipants') return hasSingleRecipient && isProjectParticipantsChecked
  if (field === 'taskManager') return hasSingleRecipient && isTaskManagerChecked
  if (field === 'taskParticipants') return hasSingleRecipient && isTaskParticipantsChecked
  if (field === 'managerOfParentTask') return hasSingleRecipient && isManagersOfAncestorTasksChecked
  if (field === 'managersOfDependentTasks') return hasSingleRecipient && isManagersOfDependentTasksChecked
  if (field === 'calendarEventParticipants') return hasSingleRecipient && isCalendarEventParticipantsChecked
  if (field === 'email') return hasSingleRecipient && reminder.hasEmailRecipients

  return reminder.isReadOnly
}

export default ReminderForm

type ReminderFormProps = {
  reminder: ReminderViewModel
  refStartDate: Date | null
  refEndDate: Date | null
}

type RecipientEmailsProps = {
  isAddEmailDisabled: boolean
  isInputDisabled: boolean
  emails: string[]
  onUpdateRecipientEmails: (emails: string[]) => void
}

type RecipientEmailProps = {
  email: string
  onRemoveRecipientEmail: (email: string) => void
  onSaveRecipientEmail: (email: string) => void
  isInputDisabled?: boolean
  autoFocus?: boolean
}

type Translations = typeof defaultTranslations
