import { useI18n } from '../../hooks'
import * as reminderApi from '../api'
import { useSnackbar } from 'notistack'

export const useReminderMutations = () => {
  const translations = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const [createReminderMutation] = reminderApi.useCreateReminderMutation()
  const [updateReminderRecipientsMutation] = reminderApi.useUpdateReminderRecipientsMutation()
  const [updateReminderTriggerMutation] = reminderApi.useUpdateReminderTriggerMutation()
  const [updateNoteMutation] = reminderApi.useUpdateNoteMutation()
  const [deleteReminderMutation] = reminderApi.useDeleteReminderMutation()

  const handleError = (error: any) => {
    const errorMessage = 'error' in error ? error.error : 'message' in error ? error.message : 'Unknown error'
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  const createReminder = (newReminderData: reminderApi.NewReminderData) => {
    return createReminderMutation(newReminderData)
      .unwrap()
      .then((createdReminder) => {
        enqueueSnackbar(translations.notificationCreationSuccessful, { variant: 'success' })
        return createdReminder
      })
      .catch(() => handleError({ message: translations.notificationCreationFailed }))
  }

  const updateReminderRecipients = (
    reminderId: string,
    reminderUpdateData: reminderApi.ReminderRecipientsUpdateData
  ) => {
    return updateReminderRecipientsMutation({ reminderId, ...reminderUpdateData })
      .unwrap()
      .then((updatedReminder) => updatedReminder)
      .catch(handleError)
  }

  const updateReminderTrigger = (reminderId: string, reminderUpdateData: reminderApi.ReminderTriggerUpdateData) => {
    return updateReminderTriggerMutation({ reminderId, ...reminderUpdateData })
      .unwrap()
      .then((updatedReminder) => updatedReminder)
      .catch(handleError)
  }

  const updateReminderNote = (reminderId: string, reminderNoteUpdateData: reminderApi.ReminderNoteUpdateData) => {
    return updateNoteMutation({ reminderId, ...reminderNoteUpdateData })
      .unwrap()
      .then((updatedReminder) => updatedReminder)
      .catch(handleError)
  }

  const deleteReminder = (reminderId: string) => {
    return deleteReminderMutation(reminderId)
      .unwrap()
      .then(() => {
        enqueueSnackbar(translations.notificationDeletionSuccessful, { variant: 'success' })
      })
      .catch(() => handleError({ message: translations.notificationDeletionFailed }))
  }

  return {
    createReminder,
    updateReminderRecipients,
    updateReminderTrigger,
    updateReminderNote,
    deleteReminder,
  }
}

const defaultTranslations = {
  notificationCreationSuccessful: 'Notification created successfully',
  notificationCreationFailed: 'Failed to create notification',
  notificationDeletionSuccessful: 'Notification deleted successfully',
  notificationDeletionFailed: 'Failed to delete notification',
}

const useTranslations = (defaults = defaultTranslations) => {
  const { t } = useI18n('translation')

  return {
    notificationCreationSuccessful: t('notificationCreationSuccessful', defaults.notificationCreationSuccessful),
    notificationCreationFailed: t('notificationCreationFailed', defaults.notificationCreationFailed),
    notificationDeletionSuccessful: t('notificationDeletionSuccessful', defaults.notificationDeletionSuccessful),
    notificationDeletionFailed: t('notificationDeletionFailed', defaults.notificationDeletionFailed),
  }
}
