import { getMinDate, getMaxDate } from '../../utils'
import { RootNode } from '../types'
import { TaskViewModel } from '../../tasks/api/task'
import { TimeZoneType } from '../../constants/timezones'
import { makeTreeGridTask } from './tree-grid-task'
import { getHighestLevelTask, syncDataFromServerToGrid } from '../utils/tree-grid'
import { AddedRow } from '../hooks/use-added-rows'
import { TreeGridTranslations } from '../hooks/use-translations'

export const getRowById = (rows: any[], id: string) => {
  return rows.find((row) => row.id === id)
}
export const getMinStartDate = (
  root: Pick<RootNode, 'plannedStartDate'>,
  tasks: Pick<TaskViewModel, 'plannedStartDate' | 'actualStartDate'>[]
) => {
  const startDatesAccumulator: Date[] = root.plannedStartDate ? [new Date(root.plannedStartDate)] : []
  const allStartDates: Date[] = tasks.reduce((acc, task) => {
    if (task.plannedStartDate) {
      acc.push(new Date(task.plannedStartDate))
    }
    if (task.actualStartDate) {
      acc.push(new Date(task.actualStartDate))
    }
    return startDatesAccumulator
  }, startDatesAccumulator)
  return getMinDate(allStartDates)
}

export const getMaxEndDate = (
  root: Pick<RootNode, 'plannedEndDate'>,
  tasks: Pick<TaskViewModel, 'plannedEndDate' | 'actualEndDate' | 'isInProgress'>[]
) => {
  const endDatesAccumulator: Date[] = root.plannedEndDate ? [new Date(root.plannedEndDate)] : []
  const allEndDates: Date[] = tasks.reduce((acc, task) => {
    if (task.plannedEndDate) {
      acc.push(new Date(task.plannedEndDate))
    }
    if (task.actualEndDate) {
      acc.push(new Date(task.actualEndDate))
    }
    if (task.isInProgress) {
      acc.push(new Date())
    }
    return endDatesAccumulator
  }, endDatesAccumulator)
  return getMaxDate(allEndDates)
}

export const syncAddedAndUpdatedTasks = ({
  grid,
  addedTasks,
  updatedTasks,
  dateFormat,
  timeZone,
  translations,
  setAddedRows,
}: SyncAddedAndUpdatedTasksParams) => {
  if (!grid) return { addedRows: [], updatedRows: [] }
  const addedRows = addedTasks.map((task) => makeTreeGridTask({ task, dateFormat, timeZone, translations }))
  addedRows.forEach((addedRow) => {
    const parentRow = addedRow.parentTaskId ? grid.GetRowById(addedRow.parentTaskId) : undefined
    let nextRow = addedRow.parentTaskId ? parentRow?.firstChild : grid.GetFirst()
    while (nextRow) {
      const nextRowOrder = (nextRow as unknown as { order: number }).order
      const addedRowOrder = addedRow.order
      if (nextRowOrder === addedRowOrder) break
      nextRow = nextRow.nextSibling
    }
    grid.AddRow(parentRow, nextRow, 1, addedRow.id)
  })

  const updatedRows = updatedTasks.map((task) => makeTreeGridTask({ task, dateFormat, timeZone, translations }))
  syncDataFromServerToGrid(grid, [...addedRows, ...updatedRows])
  const highestLevelTask = getHighestLevelTask(addedTasks)
  setAddedRows(
    addedTasks.map((task) => ({
      ...task,
      focus: task.id === highestLevelTask.id,
      scrollTo: task.id === highestLevelTask.id,
    }))
  )

  return {
    addedRows,
    updatedRows,
  }
}

type SyncAddedAndUpdatedTasksParams = {
  grid: TGrid
  addedTasks: TaskViewModel[]
  updatedTasks: TaskViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  translations: TreeGridTranslations
  setAddedRows: (rows: AddedRow[]) => void
}
