import * as taskAPI from '../api'
import { DateTimeService } from '../../services/date-time-service'
import { useDateFormat } from '../../users/hooks/use-date-format'
import { useGetTaskByIdQuery } from '../api'
import { useTaskMutations } from './use-task-mutations'
import { isAfter } from 'date-fns'

export const useTask = (taskId: string, queryOptions?: any) => {
  const taskActions = useTaskMutations()
  const { dateFormat } = useDateFormat()
  const { data: task, ...requestStatus } = useGetTaskByIdQuery(taskId, queryOptions)
  const dateTimeService = new DateTimeService({ dateFormat, enableTimeComponent: task?.enableTimeComponent })

  const addTask = async (taskData: Omit<taskAPI.NewTaskData, 'projectId'>) => {
    if (!task) return
    const newTaskData: taskAPI.NewTaskData = {
      ...taskData,
      parentTaskId: taskData.parentTaskId || task.id,
      projectId: task.projectId,
    }
    return task.isOrgTask
      ? taskActions.createOrgTask(task.maintainerId, newTaskData)
      : taskActions.createUserTask(task.maintainerId, newTaskData)
  }

  const bulkAddTasks = async (taskData: Omit<taskAPI.NewBulkTaskData, 'projectId'>) => {
    if (!task) return
    const newTaskData: taskAPI.NewBulkTaskData = {
      ...taskData,
      parentTaskId: taskData.parentTaskId || task.id,
      projectId: task.projectId,
      orgId: task.isOrgTask ? task.maintainerId : undefined,
    }
    return taskActions.bulkCreateTasks(newTaskData)
  }

  const sortedStatusDescriptionHistory = task?.statusDescriptionUpdates
    .filter(({ description }) => description) // @ts-expect-error
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .map((desc) => ({
      ...desc,
      updatedAt: dateTimeService.format(desc.updatedAt),
    }))

  return {
    ...requestStatus,
    task,
    addTask,
    bulkAddTasks,
    formattedPlannedStart: task?.plannedStartDate ? dateTimeService.format(task.plannedStartDate) : null,
    formattedPlannedEnd: task?.plannedEndDate ? dateTimeService.format(task.plannedEndDate) : null,
    formattedActualStart: task?.actualStartDate ? dateTimeService.format(task.actualStartDate) : null,
    formattedActualEnd: task?.actualEndDate ? dateTimeService.format(task.actualEndDate) : null,
    plannedStartDateIsPastDue: isTaskPlannedStartDatePastDue(),
    plannedEndDateIsPastDue: isTaskPlannedEndDatePastDue(),
    currentStatusDescription: sortedStatusDescriptionHistory?.[0] || null,
    sortedStatusDescriptionHistory: sortedStatusDescriptionHistory || [],
  }

  function isTaskPlannedStartDatePastDue() {
    if (!task || !task.plannedStartDate) return false
    const compareToDate = task.enableTimeComponent
      ? task.plannedStartDate
      : dateTimeService.endOfDay(new Date(task.plannedStartDate))
    const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
    const isPastDue = task.isNotStarted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
    return isPastDue
  }

  function isTaskPlannedEndDatePastDue() {
    if (!task || !task.plannedEndDate) return false
    const compareToDate = task.enableTimeComponent
      ? task.plannedEndDate
      : dateTimeService.endOfDay(new Date(task.plannedEndDate))
    const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
    const isPastDue = !task.isCompleted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
    return isPastDue
  }
}
