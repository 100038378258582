import React from 'react'
import { TaskColumnName, TreeGridTask } from './types'
import { Option } from '../types'
import { TimeZoneType } from '../../constants/timezones'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { useRouter } from '../../hooks'
import { useTreeGridFilters } from '../hooks/use-treegrid-filters'
import { makeTreegridLayout } from './layout'
import { makeTreeGridTaskRows } from './rows'
import { DateTimeService } from '../../services/date-time-service'
import {
  createTreegrid,
  destroyTreegrid,
  getSummaryTextWithRowCount,
  syncDataFromServerToGrid,
  updateGridData,
} from '../utils/tree-grid'
import {
  getColorFromColorHtml,
  getShouldRemoveTaskRowBasedOnContext,
  getStateFromStateIcon,
  mapGroupByToColumns,
  mapTranslationsToColor,
  mapTranslationsToGroupBy,
  mapTranslationsToStatus,
} from '../utils'
import { useTranslations } from '../hooks/use-translations'
import { TasksViewOptions } from '../../tasks/components/TasksViewSelect'
import { useAppContext } from '../../hooks/use-app-context'
import { useContextOptions } from '../../context-options/hooks/use-context-options'
import { TaskViewModel } from '../../tasks/api/task'
import { useTaskMutations } from '../../tasks/hooks/use-task-mutations'
import { useUpdateTaskStatus } from '../../tasks/hooks/use-update-task-status'
import { getMaxDate, getMinDate } from '../../utils'
import { addDays, addMonths, subDays } from 'date-fns'
import { GroupBy } from '../../types/common'

const id = '__treegrid_task_list__'

export const useTaskList = (props: TaskListProps) => {
  const translations = useTranslations()
  const [navigateToTaskId, setNavigateToTaskId] = React.useState<string | null>(null)
  const { createPathWithContext } = useUrlWithContext()
  const { appContext } = useAppContext()
  const { userMembershipContextOptions } = useContextOptions()
  const { history, ...router } = useRouter()
  const { showFilters, toggleFilters } = useTreeGridFilters({ gridId: id })
  const { initTaskUpdate } = useTaskMutations()
  const { updateTaskStatus, updatedTasks } = useUpdateTaskStatus()
  const zoomRef = React.useRef<string | null>(null)
  const query = router.query as { view?: TasksViewOptions }
  const tasksView = query.view || 'currentAndFuture'

  translations.toolbarTaskListSummaryText = getSummaryTextWithRowCount(
    translations.toolbarTaskListSummaryText,
    props.tasks.length
  )
  React.useEffect(() => {
    if (navigateToTaskId) {
      const path = createPathWithContext(`/tasks/${navigateToTaskId}/basic`)
      history.push(path)
    }
  }, [createPathWithContext, history, navigateToTaskId])

  const showGantt = tasksView === 'currentAndFuture' || tasksView === 'all'
  const showGroupBy = appContext.mainContext?.type === 'org'
  const layout = makeTreegridLayout({
    id,
    showFilters,
    translations,
    showGantt,
    showGanttToolbarActions: showGantt,
    groupBy: GroupBy.NO_GROUPING,
    showGroupBy,
    ...props,
  })

  const allPlannedStartDates = props.tasks.map((task) => task.plannedStartDate).filter(Boolean)
  const allPlannedEndDates = props.tasks.map((task) => task.plannedEndDate).filter(Boolean)
  const allActualStartDates = props.tasks.map((task) => task.actualStartDate).filter(Boolean)
  const allActualEndDates = props.tasks.map((task) => task.actualEndDate).filter(Boolean)
  const allDates = allPlannedStartDates.concat(allPlannedEndDates).concat(allActualStartDates).concat(allActualEndDates)
  const minStartDate = getMinDate(allDates)
  const maxEndDate = getMaxDate(allDates)
  const dateTimeService = new DateTimeService({
    dateFormat: props.dateFormat,
    timeZone: props.timeZone,
  })

  const { dateFormat, timeZone, options } = props

  // @ts-ignore
  window.Grids['OnFilterToggle'] = function () {
    toggleFilters()
  }

  window.Grids.OnValueChanged = function (
    grid: TGrid,
    row: TRow & TreeGridTask,
    column: TaskColumnName,
    newValue,
    oldValue
  ) {
    // NOTE: Returning early from this function will cause unexpected behavior in the treegrid
    const isColumnEditAllowed = (row as any)[`${column}CanEdit`] === 1
    const hasValueChanged = newValue !== oldValue
    const shouldDispatchUpdateRequest = isColumnEditAllowed && hasValueChanged
    if (shouldDispatchUpdateRequest) {
      let value = adaptValue({ column, newValue, oldValue })

      // If the column is a date column, then we need to adjust the timezone
      if (isDateColumn(column) && value) {
        const dateTimeService = new DateTimeService({
          dateFormat: props.dateFormat,
          timeZone: props.timeZone,
          enableTimeComponent: row.enableTimeComponent,
        })
        const date = new Date(value)
        const adjustedDate = dateTimeService.addTimezoneOffset(date, 'UTC')
        value = adjustedDate.toISOString()
      }

      if (column === 'ganttBarColor') {
        row.GanttGanttClass = value === 'Default' ? 'Blue' : value // changes the color of the gantt bar immediately
        value = mapTranslationsToColor(translations)[value]
      }

      if (column === 'status' || column === 'actualEndDate' || column === 'actualStartDate') {
        if (column === 'status') value = mapTranslationsToStatus(translations)[value]
        updateTaskStatus(row.id, { [column]: value })
        return
      }

      initTaskUpdate(row.id, { field: column as any, value }).then((responseData) => {
        if (!responseData || !grid) return
        const tasks = 'tasks' in responseData ? responseData.tasks : [responseData]
        const tasksToUpdateInGrid: TaskViewModel[] = []
        const tasksToRemoveFromGrid: TaskViewModel[] = []
        tasks.forEach((task) => {
          const shouldRemoveRow = getShouldRemoveTaskRowBasedOnContext(task, appContext, userMembershipContextOptions)
          if (shouldRemoveRow) {
            tasksToRemoveFromGrid.push(task)
          } else {
            tasksToUpdateInGrid.push(task)
          }
        })
        const updatedTaskRows = makeTreeGridTaskRows({
          tasks: tasksToUpdateInGrid,
          dateFormat,
          timeZone,
          options,
          translations,
        })
        syncDataFromServerToGrid(grid, updatedTaskRows)

        tasksToRemoveFromGrid.forEach((task) => {
          const row = grid.GetRowById(task.id)
          if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
        })
      })
    }
    return newValue
  }

  // @ts-ignore
  window.Grids.OnClick = function (grid, row, col) {
    if (col === 'open') {
      const isTaskRow = row.Def.Name === 'R'
      if (isTaskRow) setNavigateToTaskId(row.id)
    }
    if (col === 'ZoomFit' && grid) {
      const zoomToStart = dateTimeService.removeTimezoneOffset(minStartDate || new Date(), 'UTC')
      const zoomToEnd = dateTimeService.removeTimezoneOffset(
        maxEndDate ? addDays(maxEndDate, 1) : addMonths(new Date(), 1),
        'UTC'
      )
      grid.ZoomTo(zoomToStart, zoomToEnd)
      return true
    }
  }

  // @ts-ignore
  window.Grids.OnSearchChange = function (grid: TGrid, value: string) {
    if (grid) {
      grid.SearchExpression = value
      grid.DoSearch('Filter')
      const rowCount = grid.GetShownRows().length
      translations.toolbarTaskListSummaryText = getSummaryTextWithRowCount(
        translations.toolbarTaskListSummaryText,
        rowCount
      )
      // @ts-expect-error
      grid.Toolbar.Summary = translations.toolbarTaskListSummaryText
      grid.RefreshRow(grid.Toolbar)
    }
  }

  // @ts-ignore
  window.Grids.onClickTitleLink = function (link) {
    const linkWithContext = createPathWithContext(link)
    history.push(linkWithContext)
  }

  window.Grids.OnGetSortValue = function (grid: TGrid, row: TRow & TreeGridTask, column: TaskColumnName, value: any) {
    if (column === 'plannedEndDate' || column === 'plannedStartDate') {
      const shouldSortToBottom = !value
      if (shouldSortToBottom) return Number.MAX_SAFE_INTEGER
    }
    return value
  }

  window.Grids.OnRenderFinish = function (grid) {
    if (grid) {
      const yesterday = subDays(new Date(), 1)
      const oneMonthAhead = addMonths(new Date(), 1)
      const zoomToStart = dateTimeService.removeTimezoneOffset(yesterday, 'UTC')
      const zoomToEnd = dateTimeService.removeTimezoneOffset(
        maxEndDate && maxEndDate > oneMonthAhead ? addDays(maxEndDate, 1) : oneMonthAhead,
        'UTC'
      )
      grid.ZoomTo(zoomToStart, zoomToEnd)
    }
  }

  window.Grids.OnZoom = function (_, zoom) {
    zoomRef.current = zoom
  }

  // @ts-ignore
  window.Grids.OnSelectGroupByChange = function (grid: TGrid, groupBy: string) {
    if (grid) {
      const groubByValue = mapTranslationsToGroupBy(translations)[groupBy] as GroupBy
      const Group = mapGroupByToColumns(groubByValue)
      grid.DoGrouping(Group)
    }
  }

  React.useEffect(() => {
    const grid = window.Grids[id]
    if (grid) {
      const tasksToUpdateInGrid: TaskViewModel[] = []
      updatedTasks.forEach((task) => {
        const shouldRemoveRow = getShouldRemoveRow(task, tasksView)
        if (shouldRemoveRow) {
          const row = grid.GetRowById(task.id)
          if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
        } else {
          tasksToUpdateInGrid.push(task)
        }
      })
      if (tasksToUpdateInGrid.length) {
        const taskRows = makeTreeGridTaskRows({
          tasks: tasksToUpdateInGrid,
          dateFormat,
          timeZone,
          options,
          translations,
        })
        syncDataFromServerToGrid(grid, taskRows)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedTasks])

  return { layout, tasksView }
}

const TaskList = (props: TaskListProps) => {
  const translations = useTranslations()
  const { layout, tasksView } = useTaskList(props)
  const { tasks, dateFormat, timeZone, options } = props

  React.useEffect(() => {
    const data = makeTreeGridTaskRows({ tasks, dateFormat, timeZone, options, translations })
    createTreegrid({ id, layout, data })
    return () => destroyTreegrid(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultVisibleColumns, props.defaultColumnOrder])

  React.useEffect(() => {
    const data = makeTreeGridTaskRows({ tasks, dateFormat, timeZone, options, translations })
    updateGridData({ id, data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksView])

  return <div id={id} style={{ height: 'calc(100vh - 128px)' }} data-test="task-list-container"></div>
}

export default TaskList

function adaptValue({ column, newValue, oldValue }: { column: string; newValue: string; oldValue: string }) {
  let adaptedValue: any = newValue
  if (isDateColumn(column)) {
    if (newValue) adaptedValue = new Date(newValue).toISOString()
    else adaptedValue = ''
  }
  if (isSelectionColumn(column)) {
    const oldArray: string[] = oldValue.split(';').filter(Boolean)
    const newArray: string[] = newValue.split(';').filter(Boolean)
    const add = newArray.filter((id) => !oldArray.includes(id))
    const remove = oldArray.filter((id) => !newArray.includes(id))
    adaptedValue = { add, remove }
  }
  if (column === 'state') {
    adaptedValue = getStateFromStateIcon(newValue)
  }
  if (column === 'ganttBarColor') {
    adaptedValue = getColorFromColorHtml(newValue)
  }
  return adaptedValue
}

function isDateColumn(columnName: string) {
  return (
    columnName === 'actualEndDate' ||
    columnName === 'actualStartDate' ||
    columnName === 'plannedEndDate' ||
    columnName === 'plannedStartDate'
  )
}

function isSelectionColumn(columnName: string) {
  return (
    columnName === 'participants' ||
    columnName === 'managers' ||
    columnName === 'suppliers' ||
    columnName === 'workspaces'
  )
}

function getShouldRemoveRow(task: TaskViewModel, tasksView: TasksViewOptions) {
  if (tasksView === 'all') return false
  if (task.archived) return tasksView !== 'archived'
  if (task.isRejected) return tasksView !== 'rejected'
  if (task.isCompleted) return tasksView !== 'completed'
  if (task.isNotStarted || task.isInProgress) return tasksView !== 'currentAndFuture'
  return false
}

type TaskListProps = {
  defaultColumnOrder: TaskColumnName[]
  defaultVisibleColumns: TaskColumnName[]
  tasks: TaskViewModel[]
  options: Option[]
  gridInfo: string
  dateFormat: string
  dateSeparator: string
  firstDayOfWeek: DayOfWeek
  timeZone: TimeZoneType
  weekendDays: DayOfWeek[]
}
