import * as membershipApi from '../../services/membership-api'
import doublet from '../../../utils/doublet'
import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import clsx from 'clsx'
import Checkmark from '../../../components/Checkmark'
import InputLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AssociationsAndPermissionsManagerAccordion from '../AssociationsAndPermissionsManagerAccordion'
import AbilitiesTable from '../../../components/AbilitiesTable'
import AssociationsManager from '../AssociationsManager'
import MemberMenu from '../MemberMenu'
import Stack from '../../../components/Stack'
import BillingInformationDialog from '../../../components/BillingInformation/BillingInformationDialog'
import { MembershipRoleEnum } from '../../../types/memberships'
import { useI18n } from '../../../hooks'
import { StringMap } from '../../../types/common'
import { ArrowRight, Plus } from 'react-feather'
import { Theme, alpha, makeStyles, useMediaQuery } from '@material-ui/core'
import { useNewMemberAbilities } from './use-new-member-abilities'
import { useOrgOptions } from '../../../options/hooks/use-org-options'
import { useFormik } from 'formik'
import { invariant } from '../../../utils/invariant'
import { isLimitedMember as getIsLimitedMember, isNormalMember } from '../../utils'
import { useMembershipActions } from '../../hooks/use-membership-actions'
import { makeAllowedAbilities } from '../../../roles/entities/allowed-abilities'
import { makeRestrictedAbilities } from '../../../roles/entities/restricted-abilities'
import { truncate } from 'lodash'
import { IOptionNormalized } from '../../../options/interfaces/options-normalized'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useUrlWithContext } from '../../../hooks/use-url-with-context'
import { Alert } from '@material-ui/lab'
import { useLanguage } from '../../../i18n/use-language'
import { paths } from '../../../paths'
import { useCheckBillingSettingsQuery } from '../../../organisations/api'
import { useAuthUserMembership } from '../../hooks/use-auth-user-membership'
import InvitationEmailLanguageSwitch from '../InvitationEmailLanguageSwitch'

const initialValues: Omit<FormValues, 'language'> = {
  firstname: '',
  lastname: '',
  userEmail: '',
  sendInvitation: false,
  abilitiesToAdd: [],
  abilitiesToRemove: [],
  associations: [],
  abilitiesInResources: [],
}

export const useMembershipForm = ({ orgId, translations }: UseMembershipFormProps) => {
  const language = useLanguage()
  const history = useHistory()
  const { addMembership } = useMembershipActions()
  const { customerOptions, membershipOptions, supplierOptions } = useOrgOptions(orgId)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { createPathWithContext } = useUrlWithContext()
  const { canCreateMemberships } = useAuthUserMembership(orgId)
  const membersPagePath = createPathWithContext(paths.orgMembers())
  const goToMembersPage = () => history.push(membersPagePath)
  const [open, setOpen] = React.useState(false)
  const { data: checkBillingSettings, refetch: recheck } = useCheckBillingSettingsQuery(orgId, {
    skip: !canCreateMemberships,
    refetchOnMountOrArgChange: true,
  })

  const formik = useFormik({
    initialValues: { ...initialValues, language } as FormValues,
    onSubmit: async (values) => {
      const newMemberData = getNewMemberData(values)
      const [error] = await doublet(addMembership, orgId, newMemberData)
      if (error) {
        const message = error.message || translations.addMemberFailedMessage
        return enqueueSnackbar(message, { variant: 'error' })
      } else {
        formik.resetForm()
        await recheck()
        return enqueueSnackbar(translations.addMemberSuccessMessage, {
          variant: 'success',
          autoHideDuration: 5000,
          action: (id) => {
            return (
              <Button
                color="default"
                onClick={() => {
                  closeSnackbar(id)
                  goToMembersPage()
                }}
                endIcon={<ArrowRight size={12} />}
                size="small"
                data-test="view-members-button"
              >
                {translations.viewMembersButtonLabel}
              </Button>
            )
          },
        })
      }
    },
    validateOnChange: false,
  })

  const { values, errors, setFieldValue, handleChange, handleSubmit, isSubmitting, validateField } = formik
  const { firstname, sendInvitation, userEmail, copyPermissionsFrom } = values
  const isFirstnameError = Boolean(errors.firstname)
  const isUserEmailError = Boolean(errors.userEmail)
  const isSendInvitationVisible = Boolean(userEmail) && !isUserEmailError
  const isStandardMember = isNormalMember({ role: copyPermissionsFrom })
  const isLimitedMember = getIsLimitedMember({ role: copyPermissionsFrom })
  const isPermissionManagerVisible = Boolean(sendInvitation && !isUserEmailError && firstname)
  const isCopiedMember = Boolean(copyPermissionsFrom && !isStandardMember && !isLimitedMember)
  const copiedMember = getMemberById(membershipOptions, values.copyPermissionsFrom)
  const copyFromMemberLabel = isCopiedMember
    ? truncate(translations.copyingMemberLabel.replace('{{name}}', copiedMember?.name || ''), { length: 20 })
    : translations.copyFromMemberLabel

  const helperText = isCopiedMember
    ? translations.copiedMemberHelperText
        .replace('{{copy_to_name}}', values.firstname)
        .replace('{{copy_from_name}}', copiedMember?.name || '')
    : isStandardMember
    ? translations.normalMembershipHelperText
    : isLimitedMember
    ? translations.limitedMembershipHelperText
    : ''

  // Callbacks
  const setCopyPermissionsFrom = (nextValue?: string) => setFieldValue('copyPermissionsFrom', nextValue)
  const setAssociations = (nextValue: string[]) => setFieldValue('associations', nextValue)
  const setAbilitiesInResources = (nextValue: ResourceAbility[]) => setFieldValue('abilitiesInResources', nextValue)

  const handleChangeLanguage = (language: string) => setFieldValue('language', language)
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    // If the user changes the email, we need to validate the email
    validateField('userEmail')
    setFieldValue('userEmail', e.target.value)
  }
  const handleChangeSendInvitation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    setFieldValue('sendInvitation', checked)
    // If the user wants to send an invitation, by default we should set the copyPermissionsFrom to orgMember
    if (checked) setCopyPermissionsFrom(MembershipRoleEnum.orgMember)
    else setCopyPermissionsFrom()
  }

  const updateAbilities: UpdateAbilitiesFn = ({ abilitiesToAdd = [], abilitiesToRemove = [] }) => {
    // Only change abilitiesToAdd if the abilitiesToAdd array contains some values that are not in the old value
    const shouldChangeAbilitiesToAdd = abilitiesToAdd.some((a) => !values.abilitiesToAdd.includes(a))
    // Only change abilitiesToRemove if the abilitiesToRemove array contains some values that are not in the old value
    const shouldChangeAbilitiesToRemove = abilitiesToRemove.some((a) => !values.abilitiesToRemove.includes(a))

    if (shouldChangeAbilitiesToAdd) {
      // If the abilities to add have changed, we need to add new abilities to the abilities to add array
      // And also if the abilities to remove array contains some of the abilities in abilitiesToAdd,
      // we need to remove them from the abilities to remove array
      const nextAbilitiesToAdd = makeAllowedAbilities([...values.abilitiesToAdd, ...abilitiesToAdd]).getAbilities()
      const nextAbilitiesToRemove = values.abilitiesToRemove.filter((a) => !nextAbilitiesToAdd.includes(a))
      setFieldValue('abilitiesToAdd', nextAbilitiesToAdd)
      setFieldValue('abilitiesToRemove', nextAbilitiesToRemove)
    }

    if (shouldChangeAbilitiesToRemove) {
      // If the abilities to remove have changed, we need to add new abilities to the abilities to remove array
      // And also if the abilities to add array contains some of the abilities in abilitiesToRemove,
      // we need to remove them from the abilities to add array
      const nextAbilitiesToRemove = makeRestrictedAbilities([
        ...values.abilitiesToRemove,
        ...abilitiesToRemove,
      ]).getAbilities()
      const nextAbilitiesToAdd = values.abilitiesToAdd.filter((a) => !nextAbilitiesToRemove.includes(a))
      setFieldValue('abilitiesToAdd', nextAbilitiesToAdd)
      setFieldValue('abilitiesToRemove', nextAbilitiesToRemove)
    }
  }

  // Everytime isStandardMember or isLimitedMember changes, we need to reset the abilitiesToAdd and abilitiesToRemove
  // And also reset the abilitiesInResources and associations
  React.useEffect(() => {
    setFieldValue('abilitiesToAdd', [])
    setFieldValue('abilitiesToRemove', [])
    setFieldValue('associations', [])
    setFieldValue('abilitiesInResources', [])
  }, [isStandardMember, isLimitedMember, setFieldValue])

  return {
    errors,
    values,
    handleChange,
    handleChangeEmail,
    handleChangeSendInvitation,
    handleChangeLanguage,
    handleSubmit,
    setCopyPermissionsFrom,
    setAbilitiesInResources,
    setAssociations,
    updateAbilities,
    isCopiedMember,
    isFirstnameError,
    isLimitedMember,
    isPermissionManagerVisible,
    isStandardMember,
    isSendInvitationVisible,
    isSubmitting,
    isUserEmailError,
    customerOptions,
    membershipOptions,
    supplierOptions,
    copyFromMemberLabel,
    helperText,
    recheck,
    checkBillingSettings,
    setOpen,
    open,
  }
}

const MembershipForm = ({ orgId }: { orgId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const isSmAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const {
    errors,
    values,
    handleChange,
    handleChangeEmail,
    handleChangeSendInvitation,
    handleChangeLanguage,
    handleSubmit,
    setAbilitiesInResources,
    setAssociations,
    setCopyPermissionsFrom,
    updateAbilities,
    isLimitedMember,
    isPermissionManagerVisible,
    isStandardMember,
    isCopiedMember,
    isFirstnameError,
    isSendInvitationVisible,
    isSubmitting,
    isUserEmailError,
    customerOptions,
    membershipOptions,
    supplierOptions,
    copyFromMemberLabel,
    helperText,
    recheck,
    checkBillingSettings,
    setOpen,
    open,
  } = useMembershipForm({ orgId, translations })
  const { groupedAbilities } = useNewMemberAbilities({
    orgId,
    isLimitedMember: isLimitedMember,
    isStandardMember: isStandardMember,
    abilitiesToAdd: values.abilitiesToAdd,
    abilitiesToRemove: values.abilitiesToRemove,
  })
  const isFillBillingInfoAlertVisible = isSendInvitationVisible && checkBillingSettings?.shouldFillBillingSettings
  const checkmarkIcon = <Checkmark variant="contained" size={20} />

  return (
    <>
      <Typography variant="h4" className={classes.pageHeader}>
        {translations.pageHeader}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <div>
            <InputLabel error={isFirstnameError} htmlFor="firstname">
              {translations.firstnameLabel}
            </InputLabel>
            <TextField
              id="firstname"
              name="firstname"
              error={isFirstnameError}
              helperText={errors.firstname}
              value={values.firstname}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              inputProps={{ 'data-test': 'member-firstname-input' }}
            />
          </div>
          <div>
            <InputLabel htmlFor="lastname">{translations.lastnameLabel}</InputLabel>
            <TextField
              id="lastname"
              name="lastname"
              value={values.lastname}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              inputProps={{ 'data-test': 'member-lastname-input' }}
            />
          </div>
          <div>
            <InputLabel error={isUserEmailError} htmlFor="userEmail">
              {translations.emailLabel}
            </InputLabel>
            <TextField
              id="userEmail"
              name="userEmail"
              error={isUserEmailError}
              helperText={errors.userEmail}
              value={values.userEmail}
              onChange={handleChangeEmail}
              variant="outlined"
              fullWidth
              inputProps={{ 'data-test': 'member-email-input' }}
            />
          </div>
          {isFillBillingInfoAlertVisible && (
            <>
              <Alert severity="warning" data-test="fill-billing-settings-message">
                <span>{translations.fillBillingInfoText}</span>
                <span
                  onClick={() => setOpen(true)}
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    marginLeft: 8,
                    textDecoration: 'underline',
                  }}
                  data-test="fill-billing-settings-link"
                >
                  {translations.clickMeLabel}
                </span>
              </Alert>
              <BillingInformationDialog open={open} closeDialog={() => setOpen(false)} />
            </>
          )}
          {isSendInvitationVisible && (
            <FormControlLabel
              label={translations.sendInvitationLabel}
              control={
                <Checkbox
                  checked={values.sendInvitation}
                  onChange={async (e) => {
                    handleChangeSendInvitation(e)
                    await recheck()
                  }}
                  name="sendInvitation"
                  color="primary"
                  size="small"
                  disabled={checkBillingSettings?.shouldFillBillingSettings}
                  data-test="send-invitation-checkbox"
                />
              }
            />
          )}
          <InvitationEmailLanguageSwitch
            language={values.language}
            onChangeLanguage={handleChangeLanguage}
            shouldShowLanguageSwitch={values.sendInvitation}
            translations={translations}
          />
          {isPermissionManagerVisible && (
            <>
              <InputLabel>{translations.choosePermissionsLabel.replace('{{firstname}}', values.firstname)}</InputLabel>
              <>
                <Stack direction={isSmAndUp ? 'row' : 'column'} spacing={1}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={clsx(classes.button, isStandardMember && 'selected')}
                    onClick={() => setCopyPermissionsFrom(MembershipRoleEnum.orgMember)}
                    startIcon={isStandardMember ? checkmarkIcon : null}
                    fullWidth
                    data-test="standard-member-button"
                  >
                    {translations.standardMemberLabel}
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={clsx(classes.button, isLimitedMember && 'selected')}
                    onClick={() => setCopyPermissionsFrom(MembershipRoleEnum.orgLimitedMember)}
                    startIcon={isLimitedMember ? checkmarkIcon : null}
                    fullWidth
                    data-test="limited-member-button"
                  >
                    {translations.limitedMemberLabel}
                  </Button>
                  <Button
                    className={clsx(classes.button, isCopiedMember && 'selected')}
                    color="primary"
                    variant="outlined"
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget)
                      setCopyPermissionsFrom()
                    }}
                    startIcon={isCopiedMember ? checkmarkIcon : null}
                    fullWidth
                    data-test="copied-member-button"
                  >
                    {copyFromMemberLabel}
                  </Button>
                </Stack>
                <Typography variant="caption" color="textSecondary" style={{ margin: '12px 0 12px 0' }} component="p">
                  {helperText}
                </Typography>
                <MemberMenu
                  anchor={anchorEl}
                  menuTitle={translations.chooseMemberToCopyLabel}
                  searchInputPlaceholder={translations.searchMembersPlaceholder}
                  options={getMembersWithAccess(membershipOptions)}
                  selected={values.copyPermissionsFrom}
                  onClickAway={() => {
                    // should fallback to orgMember if no member is selected
                    if (!values.copyPermissionsFrom && anchorEl) {
                      setCopyPermissionsFrom(MembershipRoleEnum.orgMember)
                    }
                    setAnchorEl(null)
                  }}
                  onClickMenuItem={({ id }) => {
                    setCopyPermissionsFrom(id)
                    setAnchorEl(null)
                  }}
                />
              </>
              {/* The association customization section is visible only when the new member's type is limited member */}
              {isLimitedMember && (
                <AssociationsAndPermissionsManagerAccordion title={translations.customizeAssociationsLabel}>
                  <AssociationsManager
                    abilities={values.abilitiesToAdd}
                    abilitiesInResources={values.abilitiesInResources}
                    associations={values.associations}
                    setAbilitiesInResources={setAbilitiesInResources}
                    setAssociations={setAssociations}
                    updateAbilities={updateAbilities}
                    customerOptions={customerOptions}
                    supplierOptions={supplierOptions}
                    canUpdatePermissions={true}
                  />
                </AssociationsAndPermissionsManagerAccordion>
              )}
              {/* The permissions customization section is visible only when the new member's type is limited member  or standard member*/}
              {(isLimitedMember || isStandardMember) && (
                <AssociationsAndPermissionsManagerAccordion title={translations.customizePermissionsLabel}>
                  <AbilitiesTable groupedAbilities={groupedAbilities} onUpdateAbilities={updateAbilities} />
                </AssociationsAndPermissionsManagerAccordion>
              )}
            </>
          )}
          <Stack direction="row" packed>
            <Stack spacing={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                endIcon={isSubmitting ? null : <Plus size={16} />}
                disabled={isSubmitting}
                data-test="add-member-button"
              >
                {isSubmitting ? translations.addingMemberButtonLabel : translations.addMemberButtonLabel}
              </Button>
              <Typography variant="caption" color="textSecondary">
                {values.sendInvitation
                  ? translations.invitationInfo.replace('{{email}}', values.userEmail || '')
                  : translations.noInvitationInfo}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: { marginBottom: theme.spacing(4) },
  button: {
    fontWeight: theme.typography.fontWeightMedium as any,
    letterSpacing: theme.typography.body1.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderColor: theme.palette.divider,
    '&.selected': {
      outline: `1px solid ${theme.palette.primary.main}`,
      background: alpha(theme.palette.primary.main, 0.075),
      borderColor: theme.palette.primary.main,
      '& .MuiButton-startIcon': {
        paddingRight: theme.spacing(),
      },
    },
  },
}))

const useTranslations = (defaults = defaultTranslations): MembershipFormTranslations => {
  const { translations } = useI18n('translation')
  const { translations: t } = useI18n('membership')
  const newMemberPageTranslations = (t.newMemberPage || {}) as StringMap
  const settingsPageTranslations = (t.membershipSettingsView || {}) as StringMap
  // Do not remove this line. This is required to load the permission translations used by AbilitiesTable component,
  // If we do not load the permission translations here, the permission translations will be fetched once the AbilitiesTable component is rendered,
  // And fetching translations in the middle will cause flickering of the UI
  useI18n('permission') // <-- Do not remove this line

  const { note = defaults.note } = translations
  const {
    standardMemberLabel = defaults.standardMemberLabel,
    limitedMemberLabel = defaults.limitedMemberLabel,
    copyFromMemberLabel = defaults.copyFromMemberLabel,
    copyingMemberLabel = defaults.copyingMemberLabel,
    copiedMemberHelperText = defaults.copiedMemberHelperText,
    customizeAssociationsLabel = defaults.customizeAssociationsLabel,
    customizePermissionsLabel = defaults.customizePermissionsLabel,
    chooseMemberToCopyLabel = defaults.chooseMemberToCopyLabel,
    searchMembersPlaceholder = defaults.searchMembersPlaceholder,
    choosePermissionsLabel = defaults.choosePermissionsLabel,
    invitationInfo = defaults.invitationInfo,
    noInvitationInfo = defaults.noInvitationInfo,
    invitationLanguageDescription = defaults.invitationLanguageDescription,
  } = t

  const {
    pageHeader = defaults.pageHeader,
    normalMembershipHelperText = defaults.normalMembershipHelperText,
    limitedMembershipHelperText = defaults.limitedMembershipHelperText,
    addMemberButtonLabel = defaults.addMemberButtonLabel,
    addingMemberButtonLabel = defaults.addingMemberButtonLabel,
    addMemberFailedMessage = defaults.addMemberFailedMessage,
    firstnameRequiredText = defaults.firstnameRequiredText,
    validEmailRequiredText = defaults.validEmailRequiredText,
    duplicateEmailError = defaults.duplicateEmailError,
    addMemberSuccessMessage = defaults.addMemberSuccessMessage,
    fillBillingInfoText = defaults.fillBillingInfoText,
    clickMeLabel = defaults.clickMeLabel,
    sendInvitationLabel = defaults.sendInvitationLabel,
    resetButtonLabel = defaults.resetButtonLabel,
    viewMembersButtonLabel = defaults.viewMembersButtonLabel,
  } = newMemberPageTranslations

  const {
    firstnameLabel = defaults.firstnameLabel,
    lastnameLabel = defaults.lastnameLabel,
    emailLabel = defaults.emailLabel,
    membershipTypeLabel = defaults.membershipTypeLabel,
    normalMembershipLabel = defaults.normalMembershipLabel,
    limitedMembershipLabel = defaults.limitedMembershipLabel,
  } = settingsPageTranslations

  return {
    pageHeader,
    firstnameLabel,
    lastnameLabel,
    emailLabel,
    firstnameRequiredText,
    validEmailRequiredText,
    fillBillingInfoText,
    clickMeLabel,
    sendInvitationLabel,
    membershipTypeLabel,
    normalMembershipLabel,
    normalMembershipHelperText,
    limitedMembershipLabel,
    limitedMembershipHelperText,
    addMemberButtonLabel,
    addingMemberButtonLabel,
    addMemberFailedMessage,
    addMemberSuccessMessage,
    duplicateEmailError,
    resetButtonLabel,
    standardMemberLabel,
    limitedMemberLabel,
    copyFromMemberLabel,
    copyingMemberLabel,
    copiedMemberHelperText,
    customizeAssociationsLabel,
    customizePermissionsLabel,
    chooseMemberToCopyLabel,
    searchMembersPlaceholder,
    choosePermissionsLabel,
    invitationInfo,
    noInvitationInfo,
    viewMembersButtonLabel,
    invitationLanguageDescription,
    note,
  }
}

const getNewNoAccessMemberData = (values: FormValues): membershipApi.NewNoAccessMemberData => {
  return {
    firstname: values.firstname,
    lastname: values.lastname,
    sendInvitation: false,
    userEmail: values.userEmail,
  }
}

const getNewStandardMemberData = (values: FormValues): membershipApi.NewStandardMemberData => {
  invariant(values.userEmail, 'userEmail is required to create a new standard member')
  return {
    firstname: values.firstname,
    lastname: values.lastname,
    userEmail: values.userEmail,
    abilitiesToAdd: values.abilitiesToAdd,
    abilitiesToRemove: values.abilitiesToRemove,
    sendInvitation: true,
    copyPermissionsFrom: MembershipRoleEnum.orgMember,
    language: values.language,
  }
}

const getNewLimitedMemberData = (values: FormValues): membershipApi.NewLimitedMemberData => {
  invariant(values.userEmail, 'userEmail is required to create a new limited member')
  return {
    firstname: values.firstname,
    lastname: values.lastname,
    sendInvitation: true,
    userEmail: values.userEmail,
    abilitiesToAdd: values.abilitiesToAdd,
    abilitiesToRemove: values.abilitiesToRemove,
    associations: values.associations,
    abilitiesInResources: values.abilitiesInResources,
    copyPermissionsFrom: MembershipRoleEnum.orgLimitedMember,
    language: values.language,
  }
}

const getNewCopiedMemberData = (values: FormValues): membershipApi.NewCopiedMemberData => {
  invariant(values.userEmail, 'userEmail is required to create a new copied member')
  invariant(values.copyPermissionsFrom, 'copyPermissionsFrom is required to create a new copied member')
  return {
    firstname: values.firstname,
    lastname: values.lastname,
    sendInvitation: true,
    userEmail: values.userEmail,
    copyPermissionsFrom: values.copyPermissionsFrom,
    language: values.language,
  }
}

const getNewMemberData = (values: FormValues): membershipApi.NewMemberData => {
  if (isNormalMember({ role: values.copyPermissionsFrom })) return getNewStandardMemberData(values)
  if (getIsLimitedMember({ role: values.copyPermissionsFrom })) return getNewLimitedMemberData(values)
  if (values.copyPermissionsFrom) return getNewCopiedMemberData(values)
  return getNewNoAccessMemberData(values)
}

const getMemberById = (options: IOptionNormalized[], id?: string) => {
  return options.find((member) => member.id === id)
}

const getMembersWithAccess = (options: IOptionNormalized[]) => {
  const filteredOptions = options.filter((option) => option.type !== 'noAccessMember')
  return filteredOptions.sort((a, b) => a.name.localeCompare(b.name))
}

const defaultTranslations = {
  pageHeader: "Let's add a new member",
  firstnameLabel: 'Firstname',
  lastnameLabel: 'Lastname',
  emailLabel: 'Email',
  firstnameRequiredText: 'Firstname is required',
  validEmailRequiredText: 'Email must be a valid email',
  membershipTypeLabel: 'Membership type',
  normalMembershipLabel: 'Normal',
  limitedMembershipLabel: 'Limited',
  fillBillingInfoText: 'Please fill in the billing information before sending the invitation!!!',
  clickMeLabel: 'Click me',
  sendInvitationLabel: 'Invite user and allow access to this organisation',
  normalMembershipHelperText:
    "A normal member has access to all projects, tasks, to-dos, resources, and other information within the organisation. However, specific actions can be restricted by adjusting the member's permissions.",
  limitedMembershipHelperText:
    'A limited member can access their own projects, tasks, to-dos, and other relevant information within the organisation. Additionally, they will be able to view resources specified in the input field of this form. Permissions for a limited member operate in the same way as those for a regular member.',
  addMemberButtonLabel: 'Add member',
  addingMemberButtonLabel: 'Adding member...',
  addMemberFailedMessage: 'Something went wrong! Please try again later',
  addMemberSuccessMessage: 'Member added. New member can be viewed in the members page.',
  duplicateEmailError: 'There is an existing member with this email. Please try with another email',
  choosePermissionsLabel: 'Choose what access to give to {{firstname}}',
  standardMemberLabel: 'Standard member',
  limitedMemberLabel: 'Limited member',
  copyFromMemberLabel: 'Copied member',
  copyingMemberLabel: 'Copying {{name}}',
  copiedMemberHelperText: '{{copy_to_name}} will have exact same permissions as {{copy_from_name}}',
  customizeAssociationsLabel: 'Customize associations',
  customizePermissionsLabel: 'Customize permissions',
  chooseMemberToCopyLabel: 'Choose a member to copy from',
  searchMembersPlaceholder: 'Search for members',
  resetButtonLabel: 'Reset',
  invitationInfo:
    'We will send an email to {{email}} with a link to join the organisation. The user will be able to access the organisation after accepting the invitation.',
  noInvitationInfo: 'User will not be invited to the organisation and will not have access to the organisation',
  viewMembersButtonLabel: 'View members',
  invitationLanguageDescription: 'The invitation email will be sent in the selected language',
  note: 'Note',
}

export type MembershipFormTranslations = typeof defaultTranslations
type UseMembershipFormProps = { orgId: string; translations: MembershipFormTranslations }
type ResourceAbility = { resourceId: string; abilities: string[] }
type UpdateAbilitiesFn = (p: { abilitiesToAdd?: string[]; abilitiesToRemove?: string[] }) => void

type FormValues = {
  firstname: string
  lastname?: string
  userEmail?: string
  sendInvitation: boolean
  abilitiesToAdd: string[]
  abilitiesToRemove: string[]
  associations: string[]
  abilitiesInResources: ResourceAbility[]
  copyPermissionsFrom?: string
  language: string
}

export default MembershipForm
