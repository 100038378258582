import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import OrgGeneralSettings from '../../../organisations/components/OrgGeneralSettings'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppContext } from '../../../hooks/use-app-context'
import { useI18n, useRouter } from '../../../hooks'
import { Grid, Box, Typography, CircularProgress } from '@material-ui/core'
import { useAppDispatch } from '../../../store'
import { FetchOrganisation } from '../../../organisations/store/actions'
import { FetchOptions } from '../../../options/store/actions'
import { FetchOrgMemberships } from '../../../memberships/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAsync } from '../../../hooks/use-async'
import OrgNotificationSettings from '../../../organisations/components/OrgNotificationSettings'

const useLoader = (orgId: string) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: `load_org_home_route` })
    const results = await Promise.all([
      dispatch(FetchOrganisation(orgId)),
      dispatch(FetchOptions(orgId)),
      dispatch(FetchOrgMemberships(orgId)),
    ])
    results.forEach(unwrapResult)
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const OrgSettingsRoute = () => {
  const classes = useStyles()
  const router = useRouter()
  const query = router.query as { tab?: string }
  const currentTab = query?.tab === 'notifications' ? 1 : 0
  const { appContext } = useAppContext()
  const { mainContext } = appContext
  const translations = useTranslations()
  const orgId = mainContext?.id as string
  const { isSuccess } = useLoader(orgId)

  if (!mainContext || mainContext.type !== 'org') return null

  if (isSuccess()) {
    return (
      <div className={classes.container}>
        <Grid item style={{ marginBottom: 32 }}>
          <Typography variant="h5" data-test="page-header" style={{ fontWeight: 500 }}>
            <Box color="text.secondary" component="span">
              {mainContext.name}
            </Box>
            {' - '}
            {translations.settings}
          </Typography>
        </Grid>
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => {
            const currentSearch = new URLSearchParams(router.location.search)
            currentSearch.set('tab', newValue === 1 ? 'notifications' : 'general')
            router.push({ search: currentSearch.toString() })
          }}
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab className={classes.tab} disableRipple label={translations.general} />
          <Tab className={classes.tab} disableRipple label={translations.notifications} />
        </Tabs>
        <TabPanel currentTab={currentTab} index={0} element={<OrgGeneralSettings orgId={orgId} />} />
        <TabPanel currentTab={currentTab} index={1} element={<OrgNotificationSettings orgId={orgId} />} />
      </div>
    )
  }

  return <CircularProgress />
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 800,
    margin: 'auto',
    paddingTop: 8,
    paddingBottom: 32,
    borderRadius: 8,
  },
  tabs: { borderBottom: `1px solid ${theme.palette.divider}` },
  tab: { minWidth: 120 },
  tabPanel: { padding: theme.spacing(3, 0) },
}))

function TabPanel({ element, currentTab, index }: TabPanelProps) {
  const classes = useStyles()
  const hidden = currentTab !== index
  return (
    <div role="tabpanel" hidden={hidden} aria-hidden={hidden} className={classes.tabPanel}>
      {currentTab === index && element}
    </div>
  )
}

const defaultTranslations = {
  settings: 'Settings',
  general: 'General',
  notifications: 'Notifications',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n('translation')

  return {
    settings: translate('settings'),
    general: translate('general'),
    notifications: translate('notifications'),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

type Translations = typeof defaultTranslations

type TabPanelProps = {
  element: React.ReactNode
  index: any
  currentTab: any
}

export default OrgSettingsRoute
