export const makeOrganisationApiEndpoints = (orgId = ':orgId') => {
  const endpoints = {
    organisations: () => '/orgs',
    organisationItem: (id = orgId) => {
      return `${endpoints.organisations()}/${id || orgId}`
    },
    orgRoles: (id = orgId) => `${endpoints.organisationItem(id)}/roles`,
    orgRoleItem: (id = ':roleId') => `/roles/${id}`,
    orgRoleAbilities: (id = ':roleId') => `/roles/${id}/abilities`,
    orgRoleAbilitiesApplyToAll: (id = ':roleId') => {
      return `/roles/${id}/abilities/apply-to-all`
    },
    orgBillingSummary: (id = orgId) => `${endpoints.organisationItem(id)}/billing/summary`,
    orgBillingSettingsCheck: (id = orgId) => `${endpoints.organisationItem(id)}/billing/check`,
    orgNotificationSettings: (id = orgId) => `${endpoints.organisationItem(id)}/notification-settings`,
  }
  return endpoints
}
