import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { COLOR_DARK_100, COLOR_WHITE } from '../../constants'
import { Check } from 'react-feather'
import { entries } from 'lodash'
import { useI18n } from '../../hooks'

const redundantAbilityMap = {
  UpdateAnyCustomer: 'CreateCustomers',
  UpdateAnySupplier: 'CreateSuppliers',
  UpdateAnyWorkspace: 'CreateWorkspaces',
  UpdateAnyMembershipPermissions: 'CreateMemberships',
  UpdateProjectPermissions: 'UpdateAnyMembershipPermissions',
} as const

export default function AbilitiesTable({ groupedAbilities, onUpdateAbilities }: AbilitiesTableProps) {
  const translations = useTranslations(defaultTranslations)
  return (
    <>
      {groupedAbilities.map(({ resource, abilities }) => {
        return (
          <AbilityRowGroup
            key={resource}
            resource={resource}
            abilities={abilities}
            onUpdateAbilities={onUpdateAbilities}
            translations={translations}
          />
        )
      })}
    </>
  )
}

const AbilityRowGroup = ({
  resource,
  abilities,
  onUpdateAbilities,
  translations = defaultTranslations,
}: AbilityRowGroupProps) => {
  const groupHeader = translations[getHeaderKey(resource)] || resource
  const abilityRows = makeAbilityRows(abilities)
  const onEnableAbility = (ability: string) => {
    onUpdateAbilities({ abilitiesToAdd: [ability], abilitiesToRemove: [] })
  }
  const onDisableAbility = (ability: string) => {
    onUpdateAbilities({ abilitiesToRemove: [ability], abilitiesToAdd: [] })
  }

  return (
    <>
      <Typography variant="h6" style={{ marginLeft: 16 }}>
        {groupHeader}
      </Typography>
      <List>
        {abilityRows.map(({ id, description, yesNoState }) => {
          const onEnable = () => onEnableAbility(id)
          const onDisable = () => onDisableAbility(id)

          return (
            <AbilityRow
              key={id}
              description={description}
              yesNoState={yesNoState}
              onEnable={onEnable}
              onDisable={onDisable}
            />
          )
        })}
      </List>
    </>
  )

  // converts ability map to ability rows
  // for example { CanRead: true } => { id: 'CanRead', description: 'Can read', isEnabled: true }
  function makeAbilityRows(abilities: { [id: string]: boolean } | { [id: string]: boolean }[]) {
    const adaptedAbilities = adaptAbilities(abilities)
    return entries(adaptedAbilities)
      .filter(([abilityName]) => !redundantAbilityMap[abilityName as keyof typeof redundantAbilityMap])
      .map(([id, yesNoState]) => {
        const description = translations[getDescriptionKey(id)] || id
        return {
          id,
          description,
          yesNoState,
        }
      })
  }
}

const AbilityRow = ({
  description,
  onEnable,
  onDisable,
  yesNoState,
}: {
  description: string
  yesNoState: 0 | 1 | 2
  onEnable: () => void
  onDisable: () => void
}) => {
  const translations = useTranslations(defaultTranslations)
  const classes = useStyles()
  const isYesEnabled = yesNoState === 1 || yesNoState === 2
  const isNoEnabled = yesNoState === 0 || yesNoState === 2
  const yesBtnColor = yesNoState === 2 ? 'secondary' : 'primary'
  const noBtnColor = yesNoState === 2 ? 'secondary' : 'primary'
  const yesBtnVariant = isYesEnabled ? 'contained' : 'outlined'
  const noBtnVariant = isNoEnabled ? 'contained' : 'outlined'
  const yesBtnIcon = isYesEnabled ? <Check size={16} /> : null
  const noBtnIcon = isNoEnabled ? <Check size={16} /> : null

  return (
    <ListItem divider style={{ paddingRight: 148 }}>
      <ListItemText primary={description} />
      <ListItemSecondaryAction>
        <ButtonGroup variant="outlined" size="small">
          <Button
            color={yesBtnColor}
            variant={yesBtnVariant}
            startIcon={yesBtnIcon}
            className={classes.button}
            onClick={onEnable}
          >
            {translations.yesBtnLabel}
          </Button>
          <Button
            color={noBtnColor}
            variant={noBtnVariant}
            startIcon={noBtnIcon}
            className={classes.button}
            onClick={onDisable}
          >
            {translations.noBtnLabel}
          </Button>
        </ButtonGroup>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '64px',
    '&.MuiButton-outlinedPrimary': {
      color: COLOR_DARK_100,
      borderColor: COLOR_DARK_100,
    },
    '&.MuiButton-containedPrimary': {
      backgroundColor: COLOR_DARK_100,
    },
    '&.MuiButton-containedSecondary': {
      backgroundColor: '#696969',
      '&:not(:last-child)': {
        borderRight: `2px solid ${COLOR_WHITE}`,
      },
    },
  },
}))

const defaultTranslations = {
  ReadAnyCustomerDescription: 'Can view name and contact details of any customer in this organisation',
  ReadAnySupplierDescription: 'Can view name and contact details of any supplier in this organisation',
  ReadAnyWorkspaceDescription: 'Can view name and member details of any workspace in this organisation',
  ReadAnyTaskDescription: 'Can view details of any task in this organisation',
  ReadAnyProjectDescription: 'Can view details of any project in this organisation',
  ReadAnyCalendarEventDescription: 'Can view details of any calendar event in this organisation',
  ReadAnyTodoDescription: 'Can view details of any todo in this organisation',
  ReadProjectDescription: 'Can view details of the project',
  ReadProjectTaskDescription: 'Can view details of any task connected to the project',
  ReadTaskDescription: 'Can view details of the task',
  orgMemberRoleApplyToAllBtnLabel: 'Apply these permissions to all members',
  projectManagerRoleApplyToAllBtnLabel: 'Apply these permissions to all project managers',
  projectParticipantRoleApplyToAllBtnLabel: 'Apply these permissions to all project participants',
  taskManagerRoleApplyToAllBtnLabel: 'Apply these permissions to all task managers',
  taskParticipantRoleApplyToAllBtnLabel: 'Apply these permissions to all task participants',
  customerPermissionsHeader: 'Customer permissions',
  supplierPermissionsHeader: 'Supplier permissions',
  workspacePermissionsHeader: 'Workspace permissions',
  membershipPermissionsHeader: 'Member permissions',
  invitationPermissionsHeader: 'Invitation permissions',
  projectPermissionsHeader: 'Project permissions',
  taskPermissionsHeader: 'Task permissions',
  calendarEventPermissionsHeader: 'Calendar event permissions',
  todoPermissionsHeader: 'To-do permissions',
  organisationPermissionsHeader: 'Organisation permissions',
  CreateCustomersDescription: 'Can create and update customers in this organisation',
  CreateSuppliersDescription: 'Can create and update suppliers in this organisation',
  CreateWorkspacesDescription: 'Can create and update workspaces in this organisation',
  CreateMembershipsDescription: 'Can add members to this organisation and manage their permissions',
  ReadAnyNormalMembershipDetailsDescription:
    'Can view name and contact details of any standard member of this organisation',
  ReadAnyLimitedMembershipDetailsDescription:
    'Can view name and contact details of any limited member of this organisation',
  UpdateAnyMembershipDetailsDescription: 'Can update firstname, lastname and email of any member of this organisation',
  UpdateAnyMembershipPermissionsDescription: 'Can update permissions of any member of this organisation',
  UpdateAnyMembershipStatusDescription: 'Can activate or deactivate members of this organisation',
  CreateInvitationsDescription: 'Can invite new members to this organisation',
  ReadAnyInvitationDescription: 'Can read invitations sent to new members of this organisation',
  DeleteAnyInvitationDescription: 'Can delete invitations sent to new members of this organisation',
  CreateProjectsDescription: 'Can create projects in this organisation',
  UpdateAnyProjectDetailsDescription: 'Can update title, number and description of any project in this organisation',
  UpdateAnyProjectPlanDescription: 'Can update schedule of any project in this organisation',
  UpdateAnyProjectStatusDescription: 'Can update status of any project in this organisation',
  UpdateAnyProjectManagersDescription: 'Can update managers of any project in this organisation',
  UpdateAnyProjectParticipantsDescription: 'Can update participants of any project in this organisation',
  UpdateAnyProjectCustomersDescription: 'Can update customers of any project in this organisation',
  UpdateAnyProjectSuppliersDescription: 'Can update suppliers of any project in this organisation',
  UpdateAnyProjectWorkspacesDescription: 'Can update workspaces of any project in this organisation',
  DeleteAnyProjectDescription: 'Can delete and archive any project in this organisation',
  CreateTasksDescription: 'Can create tasks in all projects of this organisation',
  UpdateAnyTaskDetailsDescription: 'Can update title, number and description of any task in this organisation',
  UpdateAnyTaskPlanDescription: 'Can update schedule of any task in this organisation',
  UpdateAnyTaskStatusDescription: 'Can update status of any task in this organisation',
  UpdateAnyTaskManagersDescription: 'Can update managers of any task in this organisation',
  UpdateAnyTaskParticipantsDescription: 'Can update participants of any task in this organisation',
  UpdateAnyTaskSuppliersDescription: 'Can update suppliers of any task in this organisation',
  UpdateAnyTaskWorkspacesDescription: 'Can update workspaces of any task in this organisation',
  DeleteAnyTaskDescription: 'Can delete and archive any task in this organisation',
  CreateCalendarEventsDescription: 'Can create calendar events in this organisation',
  UpdateAnyCalendarEventDescription: 'Can update information about any calendar event in this organisation',
  CreateTodosDescription: 'Can create todos in this organisation',
  UpdateAnyTodoDescription: 'Can update information about any todo in this organisation',
  UpdateOrganisationDetailsDescription: 'Can update general and notification settings of this organisation',
  UpdateProjectDetailsDescription: 'Can update title, number and description of the project',
  UpdateProjectPlanDescription: 'Can update schedule of the project',
  UpdateProjectStatusDescription: 'Can update status of the project',
  UpdateProjectManagersDescription: 'Can update managers of the project',
  UpdateProjectParticipantsDescription: 'Can update participants of the project',
  UpdateProjectCustomersDescription: 'Can update customers of the project',
  UpdateProjectSuppliersDescription: 'Can update suppliers of the project',
  UpdateProjectWorkspacesDescription: 'Can update workspaces of the project',
  UpdateProjectPermissionsDescription: 'Can update permissions of the project',
  DeleteProjectDescription: 'Can delete the project',
  CreateProjectTasksDescription: 'Can create tasks in the project',
  UpdateProjectTaskDetailsDescription: 'Can update title, number and description of any tasks connected to the project',
  UpdateProjectTaskStatusDescription: 'Can update status of any tasks connected to the project',
  UpdateProjectTaskManagersDescription: 'Can update managers of any tasks connected to the project',
  UpdateProjectTaskParticipantsDescription: 'Can update participants of any tasks connected to the project',
  UpdateProjectTaskSuppliersDescription: 'Can update suppliers of any tasks connected to the project',
  UpdateProjectTaskWorkspacesDescription: 'Can update workspaces of any tasks connected to the project',
  DeleteProjectTaskDescription: 'Can delete any tasks connected to the project',
  CreateProjectCalendarEventsDescription: 'Can create calendar events in the project',
  CreateProjectTodosDescription: 'Can create to-dos in the project',
  CreateSubTasksDescription: 'Can create sub-tasks in the task',
  UpdateTaskDetailsDescription: 'Can update title, number and description of the task',
  UpdateTaskStatusDescription: 'Can update status of the task',
  UpdateTaskManagersDescription: 'Can update managers of the task',
  UpdateTaskParticipantsDescription: 'Can update participants of the task',
  UpdateTaskSuppliersDescription: 'Can update suppliers of the task',
  UpdateTaskWorkspacesDescription: 'Can update workspaces of the task',
  DeleteTaskDescription: 'Can delete the task',
  CreateTaskCalendarEventsDescription: 'Can create calendar events in the task',
  CreateTaskTodosDescription: 'Can create to-dos in the task',
  UpdateAnyRoleDescription:
    'Can update default permissions of members, project managers or participants, task managers or participants',
  ManageProjectNotificationsDescription: 'Can manage notifications of the project',
  yesBtnLabel: 'Yes',
  noBtnLabel: 'No',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: premissionsTranslations } = useI18n('permission')

  const { yesBtnLabel = defaultTranslations.yesBtnLabel, noBtnLabel = defaultTranslations.noBtnLabel } = t
  const {
    orgMemberRoleApplyToAllBtnLabel = defaults.orgMemberRoleApplyToAllBtnLabel,
    projectManagerRoleApplyToAllBtnLabel = defaults.projectManagerRoleApplyToAllBtnLabel,
    projectParticipantRoleApplyToAllBtnLabel = defaults.projectParticipantRoleApplyToAllBtnLabel,
    taskManagerRoleApplyToAllBtnLabel = defaults.taskManagerRoleApplyToAllBtnLabel,
    taskParticipantRoleApplyToAllBtnLabel = defaults.taskParticipantRoleApplyToAllBtnLabel,
    customerPermissionsHeader = defaults.customerPermissionsHeader,
    supplierPermissionsHeader = defaults.supplierPermissionsHeader,
    workspacePermissionsHeader = defaults.workspacePermissionsHeader,
    membershipPermissionsHeader = defaults.membershipPermissionsHeader,
    invitationPermissionsHeader = defaults.invitationPermissionsHeader,
    projectPermissionsHeader = defaults.projectPermissionsHeader,
    taskPermissionsHeader = defaults.taskPermissionsHeader,
    calendarEventPermissionsHeader = defaults.calendarEventPermissionsHeader,
    todoPermissionsHeader = defaults.todoPermissionsHeader,
    organisationPermissionsHeader = defaults.organisationPermissionsHeader,
    CreateCustomersDescription = defaults.CreateCustomersDescription,
    ReadAnyCustomerDescription = defaults.ReadAnyCustomerDescription,
    CreateSuppliersDescription = defaults.CreateSuppliersDescription,
    ReadAnySupplierDescription = defaults.ReadAnySupplierDescription,
    CreateWorkspacesDescription = defaults.CreateWorkspacesDescription,
    ReadAnyWorkspaceDescription = defaults.ReadAnyWorkspaceDescription,
    CreateMembershipsDescription = defaults.CreateMembershipsDescription,
    ReadAnyNormalMembershipDetailsDescription = defaults.ReadAnyNormalMembershipDetailsDescription,
    ReadAnyLimitedMembershipDetailsDescription = defaults.ReadAnyLimitedMembershipDetailsDescription,
    UpdateAnyMembershipDetailsDescription = defaults.UpdateAnyMembershipDetailsDescription,
    UpdateAnyMembershipPermissionsDescription = defaults.UpdateAnyMembershipPermissionsDescription,
    UpdateAnyMembershipStatusDescription = defaults.UpdateAnyMembershipStatusDescription,
    CreateInvitationsDescription = defaults.CreateInvitationsDescription,
    ReadAnyInvitationDescription = defaults.ReadAnyInvitationDescription,
    DeleteAnyInvitationDescription = defaults.DeleteAnyInvitationDescription,
    CreateProjectsDescription = defaults.CreateProjectsDescription,
    ReadAnyProjectDescription = defaults.ReadAnyProjectDescription,
    UpdateAnyProjectDetailsDescription = defaults.UpdateAnyProjectDetailsDescription,
    UpdateAnyProjectPlanDescription = defaults.UpdateAnyProjectPlanDescription,
    UpdateAnyProjectStatusDescription = defaults.UpdateAnyProjectStatusDescription,
    UpdateAnyProjectManagersDescription = defaults.UpdateAnyProjectManagersDescription,
    UpdateAnyProjectParticipantsDescription = defaults.UpdateAnyProjectParticipantsDescription,
    UpdateAnyProjectCustomersDescription = defaults.UpdateAnyProjectCustomersDescription,
    UpdateAnyProjectSuppliersDescription = defaults.UpdateAnyProjectSuppliersDescription,
    UpdateAnyProjectWorkspacesDescription = defaults.UpdateAnyProjectWorkspacesDescription,
    DeleteAnyProjectDescription = defaults.DeleteAnyProjectDescription,
    CreateTasksDescription = defaults.CreateTasksDescription,
    ReadAnyTaskDescription = defaults.ReadAnyTaskDescription,
    UpdateAnyTaskDetailsDescription = defaults.UpdateAnyTaskDetailsDescription,
    UpdateAnyTaskPlanDescription = defaults.UpdateAnyTaskPlanDescription,
    UpdateAnyTaskStatusDescription = defaults.UpdateAnyTaskStatusDescription,
    UpdateAnyTaskManagersDescription = defaults.UpdateAnyTaskManagersDescription,
    UpdateAnyTaskParticipantsDescription = defaults.UpdateAnyTaskParticipantsDescription,
    UpdateAnyTaskSuppliersDescription = defaults.UpdateAnyTaskSuppliersDescription,
    UpdateAnyTaskWorkspacesDescription = defaults.UpdateAnyTaskWorkspacesDescription,
    DeleteAnyTaskDescription = defaults.DeleteAnyTaskDescription,
    CreateCalendarEventsDescription = defaults.CreateCalendarEventsDescription,
    ReadAnyCalendarEventDescription = defaults.ReadAnyCalendarEventDescription,
    UpdateAnyCalendarEventDescription = defaults.UpdateAnyCalendarEventDescription,
    CreateTodosDescription = defaults.CreateTodosDescription,
    ReadAnyTodoDescription = defaults.ReadAnyTodoDescription,
    UpdateAnyTodoDescription = defaults.UpdateAnyTodoDescription,
    UpdateOrganisationDetailsDescription = defaults.UpdateOrganisationDetailsDescription,
    ReadProjectDescription = defaults.ReadProjectDescription,
    UpdateProjectDetailsDescription = defaults.UpdateProjectDetailsDescription,
    UpdateProjectPlanDescription = defaults.UpdateProjectPlanDescription,
    UpdateProjectStatusDescription = defaults.UpdateProjectStatusDescription,
    UpdateProjectManagersDescription = defaults.UpdateProjectManagersDescription,
    UpdateProjectParticipantsDescription = defaults.UpdateProjectParticipantsDescription,
    UpdateProjectCustomersDescription = defaults.UpdateProjectCustomersDescription,
    UpdateProjectSuppliersDescription = defaults.UpdateProjectSuppliersDescription,
    UpdateProjectWorkspacesDescription = defaults.UpdateProjectWorkspacesDescription,
    UpdateProjectPermissionsDescription = defaults.UpdateProjectPermissionsDescription,
    DeleteProjectDescription = defaults.DeleteProjectDescription,
    CreateProjectTasksDescription = defaults.CreateProjectTasksDescription,
    ReadProjectTaskDescription = defaults.ReadProjectTaskDescription,
    UpdateProjectTaskDetailsDescription = defaults.UpdateProjectTaskDetailsDescription,
    UpdateProjectTaskStatusDescription = defaults.UpdateProjectTaskStatusDescription,
    UpdateProjectTaskManagersDescription = defaults.UpdateProjectTaskManagersDescription,
    UpdateProjectTaskParticipantsDescription = defaults.UpdateProjectTaskParticipantsDescription,
    UpdateProjectTaskSuppliersDescription = defaults.UpdateProjectTaskSuppliersDescription,
    UpdateProjectTaskWorkspacesDescription = defaults.UpdateProjectTaskWorkspacesDescription,
    DeleteProjectTaskDescription = defaults.DeleteProjectTaskDescription,
    CreateProjectCalendarEventsDescription = defaults.CreateProjectCalendarEventsDescription,
    CreateProjectTodosDescription = defaults.CreateProjectTodosDescription,
    CreateSubTasksDescription = defaults.CreateSubTasksDescription,
    ReadTaskDescription = defaults.ReadTaskDescription,
    UpdateTaskDetailsDescription = defaults.UpdateTaskDetailsDescription,
    UpdateTaskStatusDescription = defaults.UpdateTaskStatusDescription,
    UpdateTaskManagersDescription = defaults.UpdateTaskManagersDescription,
    UpdateTaskParticipantsDescription = defaults.UpdateTaskParticipantsDescription,
    UpdateTaskSuppliersDescription = defaults.UpdateTaskSuppliersDescription,
    UpdateTaskWorkspacesDescription = defaults.UpdateTaskWorkspacesDescription,
    DeleteTaskDescription = defaults.DeleteTaskDescription,
    CreateTaskCalendarEventsDescription = defaults.CreateTaskCalendarEventsDescription,
    CreateTaskTodosDescription = defaults.CreateTaskTodosDescription,
    UpdateAnyRoleDescription = defaults.UpdateAnyRoleDescription,
    ManageProjectNotificationsDescription = defaults.ManageProjectNotificationsDescription,
  } = premissionsTranslations

  return {
    orgMemberRoleApplyToAllBtnLabel,
    projectManagerRoleApplyToAllBtnLabel,
    projectParticipantRoleApplyToAllBtnLabel,
    taskManagerRoleApplyToAllBtnLabel,
    taskParticipantRoleApplyToAllBtnLabel,
    customerPermissionsHeader,
    supplierPermissionsHeader,
    workspacePermissionsHeader,
    membershipPermissionsHeader,
    invitationPermissionsHeader,
    projectPermissionsHeader,
    taskPermissionsHeader,
    calendarEventPermissionsHeader,
    todoPermissionsHeader,
    organisationPermissionsHeader,
    CreateCustomersDescription,
    ReadAnyCustomerDescription,
    CreateSuppliersDescription,
    ReadAnySupplierDescription,
    CreateWorkspacesDescription,
    ReadAnyWorkspaceDescription,
    CreateMembershipsDescription,
    ReadAnyNormalMembershipDetailsDescription,
    ReadAnyLimitedMembershipDetailsDescription,
    UpdateAnyMembershipDetailsDescription,
    UpdateAnyMembershipPermissionsDescription,
    UpdateAnyMembershipStatusDescription,
    CreateInvitationsDescription,
    ReadAnyInvitationDescription,
    DeleteAnyInvitationDescription,
    CreateProjectsDescription,
    ReadAnyProjectDescription,
    UpdateAnyProjectDetailsDescription,
    UpdateAnyProjectPlanDescription,
    UpdateAnyProjectStatusDescription,
    UpdateAnyProjectManagersDescription,
    UpdateAnyProjectParticipantsDescription,
    UpdateAnyProjectCustomersDescription,
    UpdateAnyProjectSuppliersDescription,
    UpdateAnyProjectWorkspacesDescription,
    DeleteAnyProjectDescription,
    CreateTasksDescription,
    ReadAnyTaskDescription,
    UpdateAnyTaskDetailsDescription,
    UpdateAnyTaskPlanDescription,
    UpdateAnyTaskStatusDescription,
    UpdateAnyTaskManagersDescription,
    UpdateAnyTaskParticipantsDescription,
    UpdateAnyTaskSuppliersDescription,
    UpdateAnyTaskWorkspacesDescription,
    DeleteAnyTaskDescription,
    CreateCalendarEventsDescription,
    ReadAnyCalendarEventDescription,
    UpdateAnyCalendarEventDescription,
    CreateTodosDescription,
    ReadAnyTodoDescription,
    UpdateAnyTodoDescription,
    UpdateOrganisationDetailsDescription,
    ReadProjectDescription,
    UpdateProjectDetailsDescription,
    UpdateProjectPlanDescription,
    UpdateProjectStatusDescription,
    UpdateProjectManagersDescription,
    UpdateProjectParticipantsDescription,
    UpdateProjectCustomersDescription,
    UpdateProjectSuppliersDescription,
    UpdateProjectWorkspacesDescription,
    DeleteProjectDescription,
    CreateProjectTasksDescription,
    ReadProjectTaskDescription,
    UpdateProjectTaskDetailsDescription,
    UpdateProjectTaskStatusDescription,
    UpdateProjectTaskManagersDescription,
    UpdateProjectTaskParticipantsDescription,
    UpdateProjectTaskSuppliersDescription,
    UpdateProjectTaskWorkspacesDescription,
    DeleteProjectTaskDescription,
    CreateProjectCalendarEventsDescription,
    CreateProjectTodosDescription,
    CreateSubTasksDescription,
    ReadTaskDescription,
    UpdateTaskDetailsDescription,
    UpdateTaskStatusDescription,
    UpdateTaskManagersDescription,
    UpdateTaskParticipantsDescription,
    UpdateTaskSuppliersDescription,
    UpdateTaskWorkspacesDescription,
    DeleteTaskDescription,
    CreateTaskCalendarEventsDescription,
    CreateTaskTodosDescription,
    UpdateProjectPermissionsDescription,
    UpdateAnyRoleDescription,
    ManageProjectNotificationsDescription,
    yesBtnLabel,
    noBtnLabel,
  }
}

type RoleAbilitiesUpdateData = {
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}

type AbilityRowGroupProps = {
  resource: string
  abilities: { [id: string]: boolean } | { [id: string]: boolean }[]
  onUpdateAbilities: (update: RoleAbilitiesUpdateData) => void
  translations?: Translations
}
const getHeaderKey = (resource: string) => {
  return `${resource}PermissionsHeader` as keyof typeof defaultTranslations
}
const getDescriptionKey = (id: string) => {
  return `${id}Description` as keyof typeof defaultTranslations
}

type AbilitiesTableProps = {
  groupedAbilities: GroupedAbilities[]
  onUpdateAbilities: (updateData: UpdateData) => void
}

type GroupedAbilities = {
  resource: string
  abilities: { [ability: string]: boolean } | { [ability: string]: boolean }[]
}

type UpdateData = {
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}

type Translations = typeof defaultTranslations

// Adapt abilities so boolean values are converted to 0, 1, 2
// 0 = false, 1 = true, 2 = mixed
function adaptAbilities(abilities: { [id: string]: boolean } | { [id: string]: boolean }[]) {
  const adapted: { [id: string]: 0 | 1 | 2 } = {}
  if (Array.isArray(abilities)) {
    abilities.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (adapted[key] === undefined) {
          adapted[key] = value ? 1 : 0
        } else if (adapted[key] === 1 && !value) {
          adapted[key] = 2
        } else if (adapted[key] === 0 && value) {
          adapted[key] = 2
        }
      })
    })
  } else {
    for (const key in abilities) {
      adapted[key] = abilities[key] ? 1 : 0
    }
  }
  return adapted
}
