import * as userApi from '../api'
import { useSnackbar } from 'notistack'
import { NotificationChannel, NotificationType } from '../../notifications/notifications'

export const useUserMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [updateNotificationSettingsMutation] = userApi.useUpdateNotificationSettingsMutation()
  const [updateUserInfoMutation] = userApi.useUpdateUserInfoMutation()

  const handleError = (error: any) => {
    const errorMessage = 'error' in error ? error.error : 'message' in error ? error.message : 'Unknown error'
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  const enableNotification = (userId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [{ type, channel }], disable: [] }
    return updateNotificationSettings(userId, updateData)
  }

  const disableNotification = (userId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [], disable: [{ type, channel }] }
    return updateNotificationSettings(userId, updateData)
  }

  const updateNotificationSettings = async (userId: string, data: userApi.UserNotificationSettingsUpdateData) => {
    return await updateNotificationSettingsMutation({ userId, ...data })
      .unwrap()
      .catch(handleError)
  }

  const updateUserInfo = async (userId: string, data: userApi.UserInfoUpdateData) => {
    return await updateUserInfoMutation({ userId, ...data })
      .unwrap()
      .catch(handleError)
  }

  return {
    enableNotification,
    disableNotification,
    updateUserInfo,
    updateNotificationSettings,
  }
}
