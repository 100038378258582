import * as calendarEventApi from '../../calendar-events/api'
import * as taskApi from '../../tasks/api'
import * as todoApi from '../../todos/api'
import { isAfter } from 'date-fns'
import { useCalendarEventMutations } from '../../calendar-events/hooks/use-calendar-event-mutations'
import { DateTimeService } from '../../services/date-time-service'
import { useTaskMutations } from '../../tasks/hooks/use-task-mutations'
import { useTodoMutations } from '../../todos/hooks/use-todo-mutations'
import { useDateFormat } from '../../users/hooks/use-date-format'
import { useGetProjectByIdQuery } from '../api'
import { useProjectMutations } from './use-project-mutations'

export const useProject = (projectId: string, queryOptions?: any) => {
  const { data: project, ...requestStatus } = useGetProjectByIdQuery(projectId, queryOptions)
  const calendarEventActions = useCalendarEventMutations()
  const projectActions = useProjectMutations()
  const taskActions = useTaskMutations()
  const todoActions = useTodoMutations()
  const { dateFormat } = useDateFormat()
  const dateTimeService = new DateTimeService({ dateFormat })
  const longDateFormat = `${dateTimeService.getDateFormat()} ${dateTimeService.getTimeFormat()}`

  const addTask = async (taskData: Omit<taskApi.NewTaskData, 'projectId' | 'orgId'>) => {
    const newTaskData = { ...taskData, projectId }
    if (project?.isOrgProject) {
      return taskActions.createOrgTask(project.maintainerId, newTaskData)
    }
    return taskActions.createUserTask(project?.maintainerId || '', newTaskData)
  }

  const bulkAddTasks = async (taskData: Omit<taskApi.NewBulkTaskData, 'projectId' | 'orgId'>) => {
    const orgId = project?.isOrgProject ? project.maintainerId : undefined
    return taskActions.bulkCreateTasks({ ...taskData, projectId, orgId })
  }

  const addTodo = (todo: todoApi.NewTodoData) => {
    return todoActions.createTodo({
      ...todo,
      description: todo.description || null,
      dueDate: todo.dueDate || null,
      organisation: project?.isOrgProject ? project.maintainerId : null,
      project: projectId,
    })
  }

  const addCalendarEvent = (event: calendarEventApi.NewCalendarEventData) => {
    return calendarEventActions.createCalendarEvent({
      title: event.title,
      startDate: event.startDate,
      endDate: event.endDate,
      description: event.description || null,
      organisation: project?.isOrgProject ? project.maintainerId : null,
      project: projectId,
    })
  }

  const onInheritPlan = (startOrEnd: 'start' | 'end') => {
    if (startOrEnd === 'start') {
      return projectActions.updatePlan(projectId, {
        lockPlannedStartDate: !Boolean(project?.lockPlannedStartDate),
      })
    } else {
      return projectActions.updatePlan(projectId, {
        lockPlannedEndDate: !Boolean(project?.lockPlannedEndDate),
      })
    }
  }

  const sortedStatusDescriptionHistory = project?.statusDescriptionUpdates
    .filter(({ description }) => description) // @ts-ignore
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .map((desc) => ({
      ...desc,
      updatedAt: dateTimeService.format(desc.updatedAt, longDateFormat),
    }))

  return {
    ...requestStatus,
    project,
    formattedPlannedStart: project?.plannedStartDate ? dateTimeService.format(project.plannedStartDate) : null,
    formattedPlannedEnd: project?.plannedEndDate ? dateTimeService.format(project.plannedEndDate) : null,
    formattedActualStart: project?.actualStartDate ? dateTimeService.format(project.actualStartDate) : null,
    formattedActualEnd: project?.actualEndDate ? dateTimeService.format(project.actualEndDate) : null,
    plannedStartDateIsPastDue: isProjectPlannedStartDatePastDue(),
    plannedEndDateIsPastDue: isProjectPlannedEndDatePastDue(),
    currentStatusDescription: sortedStatusDescriptionHistory?.[0] || null,
    sortedStatusDescriptionHistory,
    onInheritPlan,
    addTask,
    bulkAddTasks,
    addTodo,
    addCalendarEvent,
  }

  function isProjectPlannedStartDatePastDue() {
    if (!project || !project.plannedStartDate) return false
    const compareToDate = project.enableTimeComponent
      ? project.plannedStartDate
      : dateTimeService.endOfDay(new Date(project.plannedStartDate))
    const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
    const isPastDue = project.isNotStarted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
    return isPastDue
  }

  function isProjectPlannedEndDatePastDue() {
    if (!project || !project.plannedEndDate) return false
    const compareToDate = project.enableTimeComponent
      ? project.plannedEndDate
      : dateTimeService.endOfDay(new Date(project.plannedEndDate))
    const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
    const isPastDue = !project.isCompleted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
    return isPastDue
  }
}
