import MembershipsTable from '../components/MembershipsTable'
import AddMembershipDialog, { AddMembershipDialogTranslations } from '../components/AddMembershipDialog'

import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { StringMap } from '../../types/common'
import { useI18n } from '../../hooks'
import { useWorkspace } from '../hooks/use-workspace'
import { useOrgMemberships } from '../../memberships/hooks/use-org-memberships'
import { keyBy } from 'lodash'
import { IMembershipNormalized } from '../../memberships/interfaces/membership-normalized'
import { useState } from 'react'
import { useAppContext } from '../../hooks/use-app-context'
import { useOrgOptions } from '../../options/hooks/use-org-options'

const WorkspaceMembersView = ({ workspaceId }: { workspaceId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { appContext } = useAppContext()
  const { mainContext, subContext } = appContext
  const { workspace, onRemoveManager, onRemoveMember, onAddMember, onAddManager } = useWorkspace(workspaceId)
  const { activeMemberships } = useOrgMemberships(workspace.orgId)
  const { membershipOptions } = useOrgOptions(workspace.orgId)
  const [openAddManagerDialog, setOpenAddManagerDialog] = useState(false)
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false)

  if (!mainContext || mainContext.type === 'user') return null

  const membershipMap = keyBy(activeMemberships, 'id')
  const workspaceManager = workspace.managers
    .map(({ id }) => id)
    .map((managerId: string) => membershipMap[managerId])
    .filter(Boolean)
  const workspaceMembers = workspace.members
    .map(({ id }) => id)
    .map((managerId: string) => membershipMap[managerId])
    .filter(Boolean)

  const managerOptions = membershipOptions.map(({ id, name }) => ({
    membershipId: id,
    fullname: name,
    userInitials: name[0],
    isSelected: workspace.managers.map(({ id }) => id).includes(id),
  }))
  const memberOptions = membershipOptions.map(({ id, name }) => ({
    membershipId: id,
    fullname: name,
    userInitials: name[0],
    isSelected: workspace.members.map(({ id }) => id).includes(id),
  }))

  return (
    <div className={classes.pageContainer}>
      <Grid container className={classes.header} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" component="header" data-test="page-header">
            {translations.workspace}
            <Box component="span" color="text.secondary">
              {` ${subContext?.name} - `}
            </Box>
            {translations.managerHeaderText}
          </Typography>
        </Grid>
        <Grid item>
          {workspace.canUpdate && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenAddManagerDialog(true)}
              data-test="add-manager-button"
            >
              {translations.addManagerButtonLabel}
            </Button>
          )}
          <AddMembershipDialog
            open={openAddManagerDialog}
            memberships={managerOptions}
            handleClose={() => setOpenAddManagerDialog(false)}
            handleAddMembership={onAddManager}
            translations={translations.addManagerDialogTranslations}
          />
        </Grid>
      </Grid>

      <MembershipsTable
        memberships={workspaceManager}
        emptyTableMessage={translations.noData}
        handleClickDelete={(membership: IMembershipNormalized) => onRemoveManager(membership.id)}
        canRemoveRow={workspace.canUpdate}
      />

      <Grid container className={classes.header} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" component="header">
            {translations.workspace}
            <Box component="span" color="text.secondary">
              {` ${subContext?.name} - `}
            </Box>
            {translations.membersHeaderText}
          </Typography>
        </Grid>
        <Grid item>
          {workspace.canUpdate && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenAddMemberDialog(true)}
              data-test="add-member-button"
            >
              {translations.addMemberButtonLabel}
            </Button>
          )}
          <AddMembershipDialog
            open={openAddMemberDialog}
            memberships={memberOptions}
            handleClose={() => setOpenAddMemberDialog(false)}
            handleAddMembership={onAddMember}
            translations={translations.addMemberDialogTranslations}
          />
        </Grid>
      </Grid>

      <MembershipsTable
        memberships={workspaceMembers}
        emptyTableMessage={translations.noData}
        handleClickDelete={(membership: IMembershipNormalized) => onRemoveMember(membership.id)}
        canRemoveRow={workspace.canUpdate}
      />
    </div>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: treegridT } = useI18n('treegrid')
  const { translations: t } = useI18n('workspace')
  const treegridTransations = (treegridT || {}) as StringMap
  const translations = (t?.membersPage || {}) as StringMap
  const addManagerDialogTranslations = (t?.addManagerDialogTranslations ||
    addManagerDialogDefaultTranslations) as AddMembershipDialogTranslations
  const addMemberDialogTranslations = (t?.addMemberDialogTranslations ||
    addMemberDialogDefaultTranslations) as AddMembershipDialogTranslations

  const {
    workspace = defaults.workspace,
    membersHeaderText = defaults.membersHeaderText,
    managerHeaderText = defaults.managerHeaderText,
    addManagerButtonLabel = defaults.addManagerButtonLabel,
    addMemberButtonLabel = defaults.addMemberButtonLabel,
  } = translations

  const { noData = defaults.noData } = treegridTransations

  return {
    workspace,
    membersHeaderText,
    managerHeaderText,
    addManagerButtonLabel,
    addMemberButtonLabel,
    addManagerDialogTranslations,
    addMemberDialogTranslations,
    noData,
  }
}

const addManagerDialogDefaultTranslations: AddMembershipDialogTranslations = {
  dialogMessage: 'Add as a manager',
  addMembershipMessage_ideal: 'Add manager',
  addMembershipMessage_pending: 'Adding manager',
  addMembershipMessage_rejected: 'Unable to add manager',
  addMembershipMessage_fulfilled: 'Added as a manager',
  membershipNotFoundMessage: 'No match found',
  inputFieldPlaceholder: 'Add a new manager',
}
const addMemberDialogDefaultTranslations: AddMembershipDialogTranslations = {
  dialogMessage: 'Add as a member',
  addMembershipMessage_ideal: 'Add member',
  addMembershipMessage_pending: 'Adding member',
  addMembershipMessage_rejected: 'Unable to add member',
  addMembershipMessage_fulfilled: 'Added as a member',
  membershipNotFoundMessage: 'No match found',
  inputFieldPlaceholder: 'Add a new member',
}
const defaultTranslations = {
  workspace: 'Workspace',
  membersHeaderText: 'Member',
  managerHeaderText: 'Manager',
  addManagerButtonLabel: 'Add',
  addMemberButtonLabel: 'Add',
  noData: 'No data found',
  addManagerDialogTranslations: addManagerDialogDefaultTranslations,
  addMemberDialogTranslations: addMemberDialogDefaultTranslations,
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 'calc(100% - 49px)',
    width: 1440,
    margin: theme.spacing(-1, 'auto', 0, 'auto'),
  },
  header: {
    padding: theme.spacing(2),
  },
}))

type Translations = typeof defaultTranslations
export default WorkspaceMembersView
