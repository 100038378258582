import { TimeZoneType } from '../../constants/timezones'
import { NotificationSettingsData } from '../../notifications/notifications'
import { DateFormatOptionType, DateFormatSeparatorOptionType } from '../constants'
import { SuspensionStatus } from '../interfaces/org-response'
import { CheckBillingSettingsResponse } from './organisation-response'

export type OrganisationViewModel = {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  email: string | null
  ownerId: string
  ownerEmail: string
  ownerFullname: string
  weekendDays: DayOfWeek[]
  firstDayOfWeek: DayOfWeek
  timeZone: TimeZoneType
  dateFormatSeparator: DateFormatSeparatorOptionType
  dateFormat: DateFormatOptionType
  suspensionStatus: SuspensionStatus
  canReadDetails: boolean
  canReadPermissions: boolean
  canUpdateDetails: boolean
  canUpdatePermissions: boolean
  canDelete: boolean
  notificationSettings: NotificationSettingsData
}

export const makeCheckBillingSettingsViewModel = (
  checkBillingSettings: CheckBillingSettingsResponse
): CheckBillingSettingsViewModel => {
  const { billingSettingsIsFilled, canInviteUserWithoutBillingInfo } = checkBillingSettings
  const shouldFillBillingSettings = !billingSettingsIsFilled && !canInviteUserWithoutBillingInfo
  return {
    ...checkBillingSettings,
    shouldFillBillingSettings,
  }
}
export type CheckBillingSettingsViewModel = CheckBillingSettingsResponse & {
  shouldFillBillingSettings: boolean
}
