import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import TaskDetails from '../../tasks/components/TaskDetails'
import TaskCardTitle from '../../tasks/components/TaskCardTitle'
import TaskNavBreadCrumbs from '../../tasks/components/TaskNavBreadCrumbs'
import TodoCardTitle from '../../todos/components/TodoCardTitle'
import TodoDetails from '../../todos/components/TodoDetails'
import { Draggable } from 'react-beautiful-dnd'
import { IconButton, makeStyles, Theme } from '@material-ui/core'
import { ArrowRight, X } from 'react-feather'
import { COLOR_BLACK } from '../../constants'
import { Link } from 'react-router-dom'
import { StringMap } from '../../types/common'
import { useI18n, useRouter } from '../../hooks'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { BoardPopulated, CardPopulated } from '../api/board'

const BoardCard = ({ board, card }: BoardCardProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { createPathWithContext } = useUrlWithContext()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)
  const isTaskCard = card.type === 'task'
  const isTodoCard = card.type === 'todo'
  const isDraggable = isTaskCard ? card.task.canUpdateStatus : card.todo.canUpdate

  const projectTitle = getProjectTitleFromCard(card)
  const projectLinkWithoutContext = getProjectLinkFromCard(card)
  const projectLink =
    projectLinkWithoutContext && createPathWithContext(projectLinkWithoutContext, { withSubContext: true })
  const showProjectLinkOnCard = projectLink && !board.isProjectBoard && !board.isTaskBoard
  const showProjectAndTaskLinkDivider = showProjectLinkOnCard && isTaskCard
  const taskKanbanLink = createPathWithContext(`/tasks/${card.id}/kanban`, { withSubContext: true })
  const cardTitle = isTaskCard ? card.task.title || card.task.shownTaskNumber : card.todo.title

  return (
    <>
      <Draggable draggableId={card.id} index={card.position} isDragDisabled={!isDraggable}>
        {(provided) => (
          <Card
            className={classes.root}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            onClick={openDialog}
          >
            <CardContent>
              <div className={classes.links}>
                {showProjectLinkOnCard && (
                  <MuiLink className={classes.link} color="primary" component={Link} to={projectLink} gutterBottom>
                    {projectTitle}
                  </MuiLink>
                )}
                {showProjectAndTaskLinkDivider && <span className={classes.linkDivider}>|</span>}
                {isTaskCard && (
                  <MuiLink className={classes.link} color="primary" component={Link} to={taskKanbanLink} gutterBottom>
                    {translations.openKanbanBtnLabel}
                    <span>
                      <ArrowRight size={10} style={{ marginLeft: 6, marginTop: 4 }} />
                    </span>
                  </MuiLink>
                )}
              </div>
              <Typography variant="body2" component="h2" gutterBottom>
                {cardTitle}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Draggable>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: 1040,
            padding: '16px 0px',
          },
        }}
        disablePortal
      >
        <div className={classes.cardHeader}>
          <div style={{ flex: 1 }}>
            {isTaskCard && <TaskNavBreadCrumbs task={card.task} />}
            {isTodoCard && <TodoParentNav card={card} />}
          </div>
          <IconButton className={classes.closeIcon} onClick={closeDialog}>
            <X size={18} color={COLOR_BLACK} />
          </IconButton>
        </div>
        <div className={classes.cardTitle}>
          {isTaskCard && <TaskCardTitle task={card.task} />}
          {isTodoCard && <TodoCardTitle todo={card.todo} />}
        </div>
        <DialogContent style={{ paddingBottom: '16px ' }}>
          {isTaskCard && <TaskDetails taskId={card.task.id} boardId={board.id} />}
          {isTodoCard && <TodoDetails todo={card.todo} boardId={board.id} />}
        </DialogContent>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(),
  },
  link: {
    fontSize: 10,
    display: 'inline-flex',
    alignItems: 'center',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 0, 2.5),
  },
  cardTitle: {
    paddingLeft: theme.spacing(1.5),
  },
  closeIcon: {
    marginRight: 16,
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
  },
  links: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  linkDivider: {
    color: theme.palette.info.main,
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.boardCard || {}) as StringMap
  const { openKanbanBtnLabel = defaults.openKanbanBtnLabel, maximizeBtnTip = defaults.maximizeBtnTip } = translations

  return {
    openKanbanBtnLabel,
    maximizeBtnTip,
  }
}

const defaultTranslations = {
  openKanbanBtnLabel: 'Open task kanban',
  maximizeBtnTip: 'Open task in full screen',
}

type Translations = typeof defaultTranslations

type BoardCardProps = { board: BoardPopulated; card: CardPopulated }
export default BoardCard

function TodoParentNav({ card }: { card: CardPopulated }) {
  const classes = useStyles()
  const router = useRouter()
  const { createPathWithContext } = useUrlWithContext()
  const projectLink = getProjectLinkFromCard(card)
  const taskLink = getTaskLinkFromCard(card)
  const projectTitle = getProjectTitleFromCard(card)
  const taskTitle = getTaskTitleFromCard(card)

  return (
    <Breadcrumbs>
      {projectLink && (
        <Chip
          className={classes.chip}
          size="small"
          label={projectTitle}
          onClick={() => {
            const projectRoute = createPathWithContext(projectLink, { withSubContext: true })
            router.push(projectRoute)
          }}
        />
      )}
      {taskLink && (
        <Chip
          className={classes.chip}
          size="small"
          label={taskTitle}
          onClick={() => {
            const taskRoute = createPathWithContext(taskLink, { withSubContext: true })
            router.push(taskRoute)
          }}
        />
      )}
    </Breadcrumbs>
  )
}

function getProjectTitleFromCard(card: CardPopulated) {
  return card.type === 'task' ? card.task.projectLink.label : card.todo.project?.title
}

function getProjectLinkFromCard(card: CardPopulated) {
  if (card.type === 'task') return card.task.projectLink.href + '/basic'
  const projectId = card.todo.project?.id
  if (projectId) return `/projects/${projectId}/basic`
}

function getTaskTitleFromCard(card: CardPopulated) {
  return card.type === 'task' ? card.task.title : card.todo.task?.title
}

function getTaskLinkFromCard(card: CardPopulated) {
  if (card.type === 'task') return `/tasks/${card.task.id}/basic`
  const taskId = card.todo.task?.id
  if (taskId) return `/tasks/${taskId}/basic`
}
