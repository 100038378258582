import { addDays, format, previousDay } from 'date-fns'
import { forEach } from 'lodash'
import { Option, RootNode } from '../types'
import { makeColorEnum, makeEnumString, makeStateEnum, makeStatusEnum, serializeDepedency } from '../utils'
import { DateTimeService } from '../../services/date-time-service'
import { getMembershipOptions, getSupplierOptions, getWorkspaceOptions } from '../../options/utils'
import { treegridDateFormat, treegridDateTimeFormat } from '../utils'
import { TreeGridTranslations } from '../hooks/use-translations'

export const makeColumns = ({
  root,
  showActualBar,
  showBaselineBar,
  defaultZoom,
  zoomList,
  options,
  weekendDays,
  translations,
  showGantt,
  visibleColumns = getDefaultVisibleColumnNames({ showGantt, root }),
}: MakeColumnsProps) => {
  const membershipOptions = getMembershipOptions(options)
  const membershipNames = membershipOptions.map(({ name }) => name)
  const membershipIds = membershipOptions.map(({ id }) => id)
  const membershipEnum = makeEnumString(membershipNames)
  const membershipEnumKeys = makeEnumString(membershipIds)

  const supplierOptions = getSupplierOptions(options)
  const supplierNames = supplierOptions.map(({ name }) => name)
  const supplierIds = supplierOptions.map(({ id }) => id)
  const supplierEnum = makeEnumString(supplierNames)
  const supplierEnumKeys = makeEnumString(supplierIds)

  const workspaceOptions = getWorkspaceOptions(options)
  const workspaceNames = workspaceOptions.map(({ name }) => name)
  const workspaceIds = workspaceOptions.map(({ id }) => id)
  const workspaceEnum = makeEnumString(workspaceNames)
  const workspaceEnumKeys = makeEnumString(workspaceIds)

  const statusEnum = makeStatusEnum(translations)
  const stateEnum = makeStateEnum(translations)

  const ganttBarColorEnum = makeColorEnum(translations)

  const dateTimeService = new DateTimeService({
    dateFormat: root.dateFormat,
    enableTimeComponent: root.enableTimeComponent,
  })
  const dateTimeFormat = dateTimeService.getFormat()
  const today = new Date()

  const ganttBaseDate = !root.enableTimeComponent
    ? root.ganttBaseDate
      ? dateTimeService.startOfDay(new Date(root.ganttBaseDate)).getTime()
      : undefined
    : root.ganttBaseDate
  const timeZonedGanttBaseDate = ganttBaseDate
    ? dateTimeService.removeTimezoneOffset(new Date(ganttBaseDate), 'UTC').getTime()
    : undefined

  const ganttFinishDate = !root.enableTimeComponent
    ? root.ganttFinishDate
      ? dateTimeService.endOfDay(new Date(root.ganttFinishDate)).getTime()
      : undefined
    : root.ganttFinishDate
  const timeZonedGanttFinishDate = ganttFinishDate
    ? dateTimeService.removeTimezoneOffset(new Date(ganttFinishDate), 'UTC').getTime()
    : undefined

  const rowNumberColumn = {
    Name: '1',
    CanSort: 0,
    CanHide: 0,
    header: translations.rowNumberColumnHeader,
    Class: 'rowNumber',
  }

  const openColumn = {
    Name: 'open',
    Type: 'Icon',
    Visible: visibleColumns.includes('open'),
    Width: 32,
    header: translations.openColumnHeader,
    CanExport: 0,
    CanPrint: 0,
    CanSort: 0,
    CanHide: 0,
    Class: 'openButton',
  }

  const titleColumn = {
    Name: 'title',
    MinWidth: 160,
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.titleColumnHeader,
  }

  const taskNumberColumn = {
    Name: 'taskNumber',
    MinWidth: 80,
    MaxWidth: 80,
    CaseSensitive: 0,
    header: translations.taskNumberColumnHeader,
  }

  const descriptionColumn = {
    Name: 'description',
    Visible: visibleColumns.includes('description'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    AcceptEnters: 1,
    RelWidth: 1,
    header: translations.descriptionColumnHeader,
    CanSort: 0,
  }

  const statusColumn = {
    Name: 'status',
    Type: 'Enum',
    Enum: statusEnum,
    MinWidth: 120,
    Visible: visibleColumns.includes('status'),
    header: translations.statusColumnHeader,
  }

  const stateColumn = {
    Name: 'state',
    Type: 'Enum',
    Enum: stateEnum,
    CanSort: 0,
    CanExport: 0,
    MinWidth: 64,
    MaxWidth: 64,
    Visible: visibleColumns.includes('state'),
    header: translations.stateColumnHeader,
  }

  const statusDescriptionColumn = {
    Name: 'statusDescription',
    Visible: visibleColumns.includes('statusDescription'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    AcceptEnters: 1,
    header: translations.statusDescriptionColumnHeader,
    CanSort: 0,
  }

  const managersColumn = {
    Name: 'managers',
    Type: 'Enum',
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: root.belongsToUser ? 0 : 1, // user should never be able to see managers column in his personal task list
    Visible: !root.belongsToUser && visibleColumns.includes('managers'),
    header: translations.responsibleColumnHeader,
  }

  const participantsColumn = {
    Name: 'participants',
    Type: 'Enum',
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: root.belongsToUser ? 0 : 1, // user should never be able to see participants column in his personal task list
    Visible: !root.belongsToUser && visibleColumns.includes('participants'),
    header: translations.participantsColumnHeader,
  }

  const durationColumn = {
    Name: 'duration',
    Type: 'Int',
    Visible: visibleColumns.includes('duration'),
    Align: 'Center',
    header: translations.durationColumnHeader,
  }

  const daysLeftColumn = {
    Name: 'daysLeft',
    Type: 'Int',
    Visible: visibleColumns.includes('daysLeft'),
    header: translations.daysLeftColumnHeader,
    Align: 'Center',
  }

  const plannedStartDateColumn = {
    Name: 'plannedStartDate',
    Type: 'Date',
    Format: dateTimeFormat,
    Width: 154,
    Visible: visibleColumns.includes('plannedStartDate'),
    header: translations.plannedStartDateColumnHeader,
  }

  const plannedEndDateColumn = {
    Name: 'plannedEndDate',
    Type: 'Date',
    Format: dateTimeFormat,
    Width: 154,
    Visible: visibleColumns.includes('plannedEndDate'),
    header: translations.plannedEndDateColumnHeader,
  }

  const actualStartDateColumn = {
    Name: 'actualStartDate',
    Type: 'Date',
    Format: dateTimeFormat,
    Width: 154,
    Visible: visibleColumns.includes('actualStartDate'),
    header: translations.actualStartDateColumnHeader,
  }

  const actualEndDateColumn = {
    Name: 'actualEndDate',
    Type: 'Date',
    Format: dateTimeFormat,
    Visible: visibleColumns.includes('actualEndDate'),
    Width: 154,
    header: translations.actualEndDateColumnHeader,
  }

  const actualBarStartDateColumn = {
    Name: 'actualBarStartDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenActualBarStartDate',
  }

  const actualBarEndDateColumn = {
    Name: 'actualBarEndDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenActualBarEndDate',
  }

  const basePlanStartDateColumn = {
    Name: 'basePlanStartDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    header: translations.basePlanStartDateColumnHeader,
  }

  const basePlanEndDateColumn = {
    Name: 'basePlanEndDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    header: translations.basePlanEndDateColumnHeader,
  }

  const ganttAncestorsColumn = {
    Name: 'ganttAncestors',
    type: 'Html',
    CanHide: 0,
    Visible: 0,
    CanPrint: 0,
    CanExport: 0,
  }

  // @ts-ignore
  window.FormatGanttDependencies = function (Grid: TGrid, Row: TRow) {
    if (!Grid || !Row) return
    // @ts-ignore
    const dependencies = JSON.parse(Row.dependencies)
    const formatted = dependencies
      .map((dependency: any) => {
        const dependencyRow = Grid.GetRowById(dependency.taskId)
        const rowIndex = Grid.GetRowIndex(dependencyRow)
        return { ...dependency, taskId: rowIndex }
      })
      .map(serializeDepedency)
      .join(',')
    return formatted
  }

  const dependenciesColumn = {
    Name: 'dependencies',
    CanEdit: 0,
    CanSort: 0,
    Visible: 0,
    CanHide: 0,
  }

  const ganttDependenciesColumn = {
    Name: 'ganttAncestors',
    type: 'Html',
    Visible: 0,
    CanPrint: 0,
    CanExport: 0,
    CanEdit: 0,
    CanHide: 1,
    header: translations.ganttDependenciesColumnHeader,
    Formula: 'FormatGanttDependencies(Grid, Row)',
  }

  const suppliersColumn = {
    Name: 'suppliers',
    Type: 'Enum',
    Enum: supplierEnum,
    EnumKeys: supplierEnumKeys,
    Value: 0,
    Range: true,
    MinWidth: 120,
    // the suppliers column should be included in the grid only if the grid belongs to an organisation, and the organisation has suppliers
    // with CanHide and Visible both set to 0, user will neither see the column nor be able to show it from column picker
    CanHide: !root.belongsToUser && supplierOptions.length ? 1 : 0,
    Visible: supplierOptions.length && visibleColumns.includes('suppliers'),
    header: translations.suppliersColumnHeader,
  }

  const workspacesColumn = {
    Name: 'workspaces',
    Type: 'Enum',
    Enum: workspaceEnum,
    EnumKeys: workspaceEnumKeys,
    Value: 0,
    Range: true,
    MinWidth: 120,
    // the workspaces column should be included in the grid only if the grid belongs to an organisation, and the organisation has workspaces
    // with CanHide and Visible both set to 0, user will neither see the column nor be able to show it from column picker
    CanHide: !root.belongsToUser && workspaceOptions.length ? 1 : 0,
    Visible: workspaceOptions.length && visibleColumns.includes('workspaces'),
    header: translations.workspacesColumnHeader,
  }

  const orderColumn = {
    Name: 'order',
    Type: 'Int',
    Visible: 0,
    CanHide: 0,
    CanPrint: 0,
    CanExport: 0,
    CanSort: 0,
  }

  const canCreateTasksColumn = {
    Name: 'canCreateTasks',
    Type: 'Bool',
    Visible: 0,
    CanHide: 0,
    CanPrint: 0,
    CanExport: 0,
    CanSort: 0,
  }

  const ganttBarColorColumn = {
    Name: 'ganttBarColor',
    Type: 'Enum',
    CanFilter: 1,
    Enum: ganttBarColorEnum,
    MinWidth: 120,
    CanHide: 1,
    Visible: visibleColumns.includes('ganttBarColor'),
    header: translations.color,
  }

  const ganttGanttHtmlRightColumn = {
    Name: 'ganttGanttHtmlRight',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenGanttGanttHtmlRight',
  }

  // Color weekend days differently in the gantt chart
  // If its organisation gantt chart, get the weekend days from the organisation
  // else use the weekend days from the user
  let GanttBackground = `d#${format(today, root.dateFormat)}#1;`
  if (weekendDays && weekendDays.length) {
    forEach(weekendDays, (day) => {
      const ganttExcludeDay = previousDay(today, day)
      const ganttExcludeStart = format(ganttExcludeDay, treegridDateFormat)
      const ganttExcludeEnd = format(addDays(ganttExcludeDay, 1), treegridDateFormat)
      GanttBackground += `w#${ganttExcludeStart}~${ganttExcludeEnd}#1;` //
    })
  }

  const ganttColumn = {
    Name: 'Gantt',
    Type: 'Gantt',
    GanttCount: 1, // Plan bar and actual bar
    /**
     * @link http://www.treegrid.com/Doc/GanttObjects.htm#CGanttEdit
     */
    GanttEdit: 'Main,Dependency,DependencyTypes,DependencyLags',
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt background configuration ------------ */
    /** ---------------------------------------------------------------- */
    GanttLines: `0#${format(today, treegridDateTimeFormat)}#Navy`, // line indicating today
    GanttBackground, // background lines repeating every day and every weekend
    GanttBase: timeZonedGanttBaseDate,
    GanttFinish: timeZonedGanttFinishDate,
    GanttBaseCanEdit: 0,
    GanttFinishCanEdit: 0,
    GanttShowBounds: 2, // shows gantt base and gantt finish as a vertical line if they are defined
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt dependency configuration ------------ */
    /** ---------------------------------------------------------------- */
    GanttDependencyColor: 3,
    GanttAncestors: 'ganttAncestors',
    GanttIncorrectDependencies: 0, // treegrid should not mark any dependencies as incorrect
    GanttCorrectDependencies: 0, // treegrid should not auto correct dependencies
    GanttCheckDependencies: 0, // treegrid should not check dependencies
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt zoom configuration ------------------ */
    /** ---------------------------------------------------------------- */
    GanttZoom: defaultZoom,
    GanttZoomList: zoomList,
    GanttSmoothZoom: 1, // controls whether to adjust the GanttWidth of each column with zoom
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt page configuration ------------------ */
    /** ---------------------------------------------------------------- */
    GanttPaging: 1,
    GanttPageWidth: '120%',
    /** ---------------------------------------------------------------- */
    /** -------------------- Plan Bar configuration -------------------- */
    /** ---------------------------------------------------------------- */
    GanttStart: 'plannedStartDate',
    GanttEnd: 'plannedEndDate',
    GanttHeight: 16,
    GanttClass: 'Blue',
  }

  if (showGantt) {
    /** if actual bar is shown add actual bar to gantt column */
    if (showActualBar) {
      const GanttNumber = ganttColumn.GanttCount
      Object.assign(ganttColumn, {
        /** Reset gantt count */
        GanttCount: GanttNumber + 1,
        /** ------------------------------------------------------------------ */
        /** -------------------- Actual Bar configuration -------------------- */
        /** ------------------------------------------------------------------ */
        [`GanttStart${GanttNumber}`]: 'actualBarStartDate',
        [`GanttEnd${GanttNumber}`]: 'actualBarEndDate',
        [`GanttTop${GanttNumber}`]: 0,
        [`GanttHeight${GanttNumber}`]: 3,
        [`GanttClass${GanttNumber}`]: 'Gray',
      })
    }
    if (showBaselineBar) {
      const GanttNumber = ganttColumn.GanttCount
      Object.assign(ganttColumn, {
        /** Reset gantt count */
        GanttCount: GanttNumber + 1,
        /** ------------------------------------------------------------------ */
        /** -------------------- Baseplan Bar configuration ------------------ */
        /** ------------------------------------------------------------------ */
        [`GanttStart${GanttNumber}`]: 'basePlanStartDate',
        [`GanttEnd${GanttNumber}`]: 'basePlanEndDate',
        [`GanttTop${GanttNumber}`]: 20,
        [`GanttHeight${GanttNumber}`]: 3,
        [`GanttClass${GanttNumber}`]: 'Red',
        /** ------------------------------------------------------------------ */
      })
    }
  }

  const makeLeftCols = () => {
    //  Ordered left columns is a list of columns with the possibility to be visible (some columns are never shown in the grid)
    //  and ordered in the default order they should be displayed in the grid.
    const leftCols = [
      rowNumberColumn,
      openColumn,
      { Name: 'Panel' },
      taskNumberColumn,
      titleColumn,
      statusColumn,
      stateColumn,
      statusDescriptionColumn,
      managersColumn,
      participantsColumn,
      durationColumn,
      plannedStartDateColumn,
      plannedEndDateColumn,
      daysLeftColumn,
      descriptionColumn,
      actualStartDateColumn,
      actualEndDateColumn,
      actualBarEndDateColumn,
      actualBarStartDateColumn,
      basePlanStartDateColumn,
      basePlanEndDateColumn,
      suppliersColumn,
      workspacesColumn,
      ganttAncestorsColumn,
      ganttDependenciesColumn,
      ganttGanttHtmlRightColumn,
      ganttBarColorColumn,
    ]

    if (!showGantt || (showGantt && root.enableTimeComponent)) {
      leftCols.splice(leftCols.indexOf(durationColumn), 1)
      leftCols.splice(leftCols.indexOf(daysLeftColumn), 1)
    }

    return leftCols
  }

  const makeRightCols = () => {
    const rightColumns = [] as any[]
    if (showGantt) {
      rightColumns.push(ganttColumn)
    }
    return rightColumns
  }

  return {
    leftCols: makeLeftCols(),
    midCols: [orderColumn, canCreateTasksColumn, dependenciesColumn],
    rightCols: makeRightCols(),
  }
}

const getDefaultVisibleColumnNames = ({
  showGantt,
  root,
}: {
  showGantt?: boolean
  root?: RootNode
} = {}) => {
  const colNames = [
    'title',
    'status',
    'description',
    'state',
    'statusDescription',
    'duration',
    'plannedStartDate',
    'plannedEndDate',
    'managers',
    'rowNumber',
    'open',
  ]

  if (showGantt) {
    // hide description column if gantt is visible
    colNames.splice(colNames.indexOf('description'), 1)
    // hide state column if gantt is visible
    colNames.splice(colNames.indexOf('state'), 1)
    //  hide statusDescription column if gantt is visible
    colNames.splice(colNames.indexOf('statusDescription'), 1)
    // show ganttBarColor column if gantt is visible
    colNames.push('ganttBarColor')
  }

  if (!showGantt || root?.enableTimeComponent) {
    colNames.splice(colNames.indexOf('duration'), 1)
  }

  return colNames
}

type MakeColumnsProps = {
  root: RootNode
  showActualBar: boolean
  showBaselineBar: boolean
  showGantt: boolean
  translations: TreeGridTranslations
  defaultZoom: string
  visibleColumns?: string[]
  zoomList: any[]
  options: Option[]
  weekendDays: DayOfWeek[]
}
