import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import EventParticipantsWidget from './EventParticipantsWidget'
import EventSuppliersWidget from './EventSuppliersWidget'
import EventWorkspacesWidget from './EventWorkspacesWidget'
import EventCustomersWidget from './EventCustomersWidget'
import EventProjectWidget from './EventProjectWidget'
import ColorSelectionWidget from '../../components/ColorSelectionWidget'
import DescriptionEditor from '../../components/DescriptionEditor'
import DateWidget from '../../components/DateWidget'
import React from 'react'
import EventTaskWidget from './EventTaskWidget'
import { makeStyles, Theme } from '@material-ui/core'
import { useDateFormat } from '../../users/hooks/use-date-format'
import { DateTimeService } from '../../services/date-time-service'
import { useOrgOptions } from '../../options/hooks/use-org-options'
import { useCalendarEventMutations } from '../hooks/use-calendar-event-mutations'
import { useGetProjectsQuery } from '../../projects/api'
import { adaptProjectToResource, adaptTaskToResource } from '../../components/ResourceManager'
import { useGetTasksQuery } from '../../tasks/api'
import { useI18n } from '../../hooks/use-i18n'
import { StringMap } from '../../types/common'
import { CalendarEventViewModel } from '../api/calendar-event'
import CreationMetaDataWidget from '../../components/CreationMetaDataWidget'
import EventRecurringWidget from './EventRecurringWidget'
import CalendarEventReminders from './CalendarEventReminders'

const CalendarEventDetails = ({
  calendarEvent,
  onDelete,
}: {
  calendarEvent: CalendarEventViewModel
  onDelete: () => void
}) => {
  const actions = useCalendarEventMutations()
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { dateFormat } = useDateFormat()
  const dateTimeService = new DateTimeService({
    dateFormat,
    enableTimeComponent: true,
  })
  const format = dateTimeService.format.bind(dateTimeService)
  const { id, description, startDate, endDate, canUpdate } = calendarEvent

  const { customerOptions, workspaceOptions, supplierOptions } = useOrgOptions(calendarEvent?.organisation?.id || '')
  const hasCustomerOptions = Boolean(customerOptions?.length)
  const hasWorkspaceOptions = Boolean(workspaceOptions?.length)
  const hasSupplierOptions = Boolean(supplierOptions?.length)

  const projectQuery = React.useMemo(() => {
    if (!calendarEvent.organisation) return
    return { organisation: calendarEvent.organisation.id }
  }, [calendarEvent.organisation])
  const { data: projects = [] } = useGetProjectsQuery(projectQuery)
  const projectOptions = projects.map((project) => adaptProjectToResource(project))

  const taskQuery = React.useMemo(() => {
    if (!calendarEvent.project) return
    return { project: calendarEvent.project.id }
  }, [calendarEvent.project])
  const { data: tasks = [] } = useGetTasksQuery(taskQuery)
  const taskOptions = tasks.map((task) => adaptTaskToResource(task))

  return (
    <Grid container spacing={4} wrap="nowrap">
      <Grid item className={classes.main}>
        <DescriptionEditor
          description={description || ''}
          onSaveDescription={(description) => actions.updateInfo(id, { description })}
          TextAreaAutosizeProps={{
            placeholder: calendarEvent.canUpdate
              ? translations.descriptionPlaceholder
              : translations.readOnlyDescriptionPlaceholder,
            disabled: !canUpdate,
          }}
        />

        {calendarEvent.isOrgEvent && (
          <>
            <Grid container spacing={3}>
              {hasCustomerOptions && (
                <Grid item style={{ flex: 1 }}>
                  <EventCustomersWidget calendarEvent={calendarEvent} />
                </Grid>
              )}
              <Grid item style={{ flex: 1 }}>
                <EventParticipantsWidget calendarEvent={calendarEvent} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {hasWorkspaceOptions && (
                <Grid item style={{ flex: 1 }}>
                  <EventWorkspacesWidget calendarEvent={calendarEvent} />
                </Grid>
              )}
              {hasSupplierOptions && (
                <Grid item style={{ flex: 1 }}>
                  <EventSuppliersWidget calendarEvent={calendarEvent} />
                </Grid>
              )}
            </Grid>
          </>
        )}

        <section style={{ marginTop: 24 }}>
          <EventRecurringWidget
            isRecurringEvent={calendarEvent.isRecurringEvent}
            startDate={startDate}
            endDate={endDate}
            recurranceType={calendarEvent.recurranceType}
            recurranceEndDate={calendarEvent.recurranceEndDate}
            onChange={({ recurranceType, recurranceEndDate, startDate, endDate }) => {
              let data: {
                recurranceType: string | null
                recurranceEndDate: string | null
                startDate?: string
                endDate?: string
              } = { recurranceType, recurranceEndDate }
              if (startDate) data.startDate = startDate || undefined
              if (endDate) data.endDate = endDate || undefined
              actions.updateInfo(id, data)
            }}
            disabled={!canUpdate || Boolean(calendarEvent.parentEventId)}
          />
        </section>

        <section>
          <CalendarEventReminders calendarEvent={calendarEvent} />
        </section>

        <section style={{ marginTop: 24 }}>
          <ColorSelectionWidget
            selectedColor={calendarEvent.color}
            onColorChange={(color) => actions.updateInfo(id, { color })}
            disabled={!canUpdate}
          />
        </section>
      </Grid>
      <Grid item className={classes.sidebar}>
        <section className={classes.sidebarWidget}>
          <DateWidget
            formattedDate={format(startDate)}
            isoDate={startDate}
            onDateChange={(date) => actions.updateInfo(id, { startDate: date?.toISOString() })}
            label={translations.startDateLabel}
            isClearable={false}
            isReadOnly={!canUpdate}
            showTimeSelect
          />
        </section>
        <Divider />

        <section className={classes.sidebarWidget}>
          <DateWidget
            formattedDate={format(endDate)}
            isoDate={endDate}
            onDateChange={(date) => actions.updateInfo(id, { endDate: date?.toISOString() })}
            label={translations.endDateLabel}
            isClearable={false}
            isReadOnly={!canUpdate}
            showTimeSelect
          />
        </section>
        <Divider />

        <section className={classes.sidebarWidget}>
          <EventProjectWidget calendarEvent={calendarEvent} projectOptions={projectOptions} />
        </section>
        <Divider />

        {calendarEvent.project && (
          <section className={classes.sidebarWidget}>
            <EventTaskWidget calendarEvent={calendarEvent} taskOptions={taskOptions} />
          </section>
        )}
        <div className={classes.actionsContainer}>
          {calendarEvent.canDelete && (
            <Button
              onClick={() => {
                actions.deleteEvent(calendarEvent.id)
                onDelete()
              }}
              variant="outlined"
              style={{ marginBottom: 8 }}
              fullWidth
            >
              {translations.deleteButtonLabel}
            </Button>
          )}
        </div>

        <section className={classes.sidebarWidget}>
          <CreationMetaDataWidget creatorName={calendarEvent.creatorFullname} creationDate={calendarEvent.createdAt} />
        </section>
      </Grid>
    </Grid>
  )
}

const useTranslations = (defaults = defaultTranslations): typeof defaultTranslations => {
  const { translations: t } = useI18n('calendarEvent')
  const translations = (t?.calendarEventDetails || {}) as StringMap

  const {
    startDateLabel = defaults.startDateLabel,
    endDateLabel = defaults.endDateLabel,
    deleteButtonLabel = defaults.deleteButtonLabel,
    descriptionPlaceholder = defaults.descriptionPlaceholder,
    readOnlyDescriptionPlaceholder = defaults.readOnlyDescriptionPlaceholder,
  } = translations

  return { startDateLabel, endDateLabel, deleteButtonLabel, descriptionPlaceholder, readOnlyDescriptionPlaceholder }
}

const defaultTranslations = {
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  deleteButtonLabel: 'Delete event',
  descriptionPlaceholder: 'Add a description',
  readOnlyDescriptionPlaceholder: 'This event has no description',
}

const useStyles = makeStyles((theme: Theme) => ({
  main: { flexGrow: 1 },
  sidebar: { minWidth: 300 },
  sidebarWidget: {
    padding: theme.spacing(1.5, 0),
    '&:first-child': {
      marginTop: -theme.spacing(2.5),
    },
  },
  actionsContainer: { marginTop: 24 },
}))

export default CalendarEventDetails
