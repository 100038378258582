import React from 'react'
import { Typography, Grid, Button } from '@material-ui/core'
import { COLOR_BLACK, COLOR_LIGHT, treeGridColors, treeGridColorsMap } from '../constants'
import { Check } from 'react-feather'
import { TreeGridColors } from '../treegrid/types'
import { useI18n } from '../hooks'

type ColorSelectionWidgetProps = {
  selectedColor: TreeGridColors
  onColorChange: (color: TreeGridColors) => void
  disabled?: boolean
}

const ColorSelectionWidget: React.FC<ColorSelectionWidgetProps> = ({
  selectedColor,
  onColorChange,
  disabled = false,
}) => {
  const translations = useTranslations()
  const colorOptions = treeGridColors.filter((color) => color !== 'Default')

  return (
    <div>
      <Typography component="h4" variant="body1">
        <strong>{translations.color}</strong>
      </Typography>
      <Grid container spacing={1} style={{ marginTop: '8px' }}>
        {colorOptions.map(($color) => {
          const label = `${$color[0]}${$color[1]}`
          const labelToShow = selectedColor === $color ? <Check /> : label
          const color = treeGridColorsMap[$color]
          const textColor = $color === 'White' || $color === 'Yellow' ? COLOR_BLACK : COLOR_LIGHT
          return (
            <Grid item key={color}>
              <Button
                variant="outlined"
                style={{ backgroundColor: color, color: selectedColor === $color ? textColor : color, padding: '0' }}
                onClick={() => {
                  if ($color === selectedColor) return
                  onColorChange($color)
                }}
                size="small"
                disabled={disabled}
              >
                {labelToShow}
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations) => {
  const { translations: t } = useI18n('translation')
  const { color = defaults.color } = t
  return { color }
}

const defaultTranslations = {
  color: 'Color',
}

type Translations = typeof defaultTranslations

export default ColorSelectionWidget
