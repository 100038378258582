import * as React from 'react'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import ErrorList from '../../../components/ErrorList'
import ProjectSettings from '../../../projects/components/ProjectSettings'
import ProjectNotificationSettings from '../../../projects/components/ProjectNotificationSettings'
import ProjectReminders from '../../../projects/components/ProjectReminders'

import { RouteComponentProps } from 'react-router-dom'
import { useProject } from '../../../projects/hooks/use-project'
import { useGetProjectPermissionsQuery } from '../../../projects/api'
import { CircularProgress, Theme, makeStyles } from '@material-ui/core'
import { useGetOrgMembershipsQuery } from '../../../memberships/api'
import { paths } from '../../../paths'
import { useI18n, useRouter } from '../../../hooks'
import { ArrowLeft } from 'react-feather'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectSettingsRoute = ({ match, history }: RouteComponentProps<RouteParams>) => {
  const classes = useStyles()
  const translations = useTranslations()
  const router = useRouter()
  const query = router.query as { tab?: string }
  const currentTab = query?.tab === 'notifications' ? 1 : query.tab === 'reminders' ? 2 : 0
  const { projectId } = match.params
  const { project, isLoading: isLoadingProject, isError: isErrorProject } = useProject(projectId, queryOptions)
  const {
    data: projectPermissions = [],
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
  } = useGetProjectPermissionsQuery(projectId, { ...queryOptions, skip: !project || project.isUserProject })
  const {
    data: memberships = [],
    isLoading: isLoadingOrgMemberships,
    isError: isErrorOrgMemberships,
  } = useGetOrgMembershipsQuery(project?.maintainerId || '', {
    ...queryOptions,
    skip: !project || project.isUserProject,
  })

  if (isLoadingProject || isLoadingPermissions || isLoadingOrgMemberships) return <CircularProgress />
  if (isErrorProject) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  if (isErrorOrgMemberships) return <ErrorList errors={[translations.fetchErrorMemberships]} />
  if (isErrorPermissions) return <ErrorList errors={[translations.fetchErrorPermissions]} />
  const projectBasic = `${paths.projectBasic(projectId)}${history.location.search}`

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: 32 }}>
        <Button
          onClick={() => history.push(projectBasic)}
          size="small"
          startIcon={<ArrowLeft size={16} />}
          style={{ marginBottom: 12 }}
        >
          {`${translations.backButtonLabel}: ${project.title}`}
        </Button>
        <Typography variant="h6">{translations.pageTitle}</Typography>
      </div>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => {
          const currentSearch = new URLSearchParams(router.location.search)
          currentSearch.set('tab', newValue === 1 ? 'notifications' : newValue === 2 ? 'reminders' : 'general')
          router.push({ search: currentSearch.toString() })
        }}
        indicatorColor="primary"
        className={classes.tabs}
      >
        <Tab className={classes.tab} disableRipple label={translations.general} />
        <Tab className={classes.tab} disableRipple label={translations.systemNotifications} />
        <Tab className={classes.tab} disableRipple label={translations.customNotifications} />
      </Tabs>
      <TabPanel
        currentTab={currentTab}
        index={0}
        element={
          <ProjectSettings project={project} projectPermissions={projectPermissions} memberships={memberships} />
        }
      />
      <TabPanel currentTab={currentTab} index={1} element={<ProjectNotificationSettings projectId={project.id} />} />
      <TabPanel currentTab={currentTab} index={2} element={<ProjectReminders projectId={project.id} />} />
    </div>
  )
}

function TabPanel({ element, currentTab, index }: TabPanelProps) {
  const classes = useStyles()
  const hidden = currentTab !== index
  return (
    <div role="tabpanel" hidden={hidden} aria-hidden={hidden} className={classes.tabPanel}>
      {currentTab === index && element}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 800,
    margin: 'auto',
    paddingTop: 8,
    paddingBottom: 32,
    borderRadius: 8,
  },
  tabs: { borderBottom: `1px solid ${theme.palette.divider}` },
  tab: { minWidth: 120 },
  tabPanel: { padding: theme.spacing(3, 0) },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations = {} } = useI18n('translation')
  const { translations: projectTranslations = {} } = useI18n('project')
  const projectSettingsPageTranslations = projectTranslations.projectSettingsPage || {}

  return {
    settings: translate('settings'),
    general: translate('general'),
    systemNotifications: translate('systemNotifications'),
    customNotifications: translate('customNotifications'),
    pageTitle: translate('pageTitle', 'project'),
    backButtonLabel: translate('backButtonLabel', 'project'),
    projectNotFoundError: translate('projectNotFoundError'),
    fetchErrorProject: translate('fetchErrorProject'),
    fetchErrorPermissions: translate('fetchErrorPermissions'),
    fetchErrorMemberships: translate('fetchErrorMemberships'),
  }

  function translate(key: keyof Translations, namespace: 'translation' | 'project' = 'translation'): string {
    if (namespace === 'translation') return translations[key] || defaults[key]
    else return projectSettingsPageTranslations[key] || defaults[key]
  }
}
const defaultTranslations = {
  settings: 'Settings',
  general: 'General',
  systemNotifications: 'System notifications',
  customNotifications: 'Custom notifications',
  pageTitle: 'Project settings',
  backButtonLabel: 'Back to project',
  projectNotFoundError: 'Project not found',
  fetchErrorProject: 'Failed to fetch project',
  fetchErrorPermissions: 'Failed to fetch permissions',
  fetchErrorMemberships: 'Failed to fetch memberships',
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
type TabPanelProps = { element: React.ReactNode; index: any; currentTab: any }
export default ProjectSettingsRoute
