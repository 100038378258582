import { makeStyles, Theme, Typography } from '@material-ui/core'
import { useI18n } from '../hooks'
import _ from 'lodash'
import { useUserTimeZone } from '../hooks/use-user-time-zone'
import { useDateFormat } from '../users/hooks/use-date-format'
import { DateTimeService } from '../services/date-time-service'

const CreationMetaDataWidget = ({ creatorName, creationDate }: CreateMetaDataWidgetProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const timeZone = useUserTimeZone()
  const { dateFormat } = useDateFormat()
  const dateTimeService = new DateTimeService({
    timeZone,
    dateFormat,
    enableTimeComponent: true,
  })
  const timeZonedDate = dateTimeService.removeTimezoneOffset(new Date(creationDate).toISOString())

  return (
    <div className={classes.emptyMessageDisplay}>
      <Typography component="p" variant="caption" color="textSecondary">
        <strong>{translations.createdBy}:</strong> {_.capitalize(creatorName)}
      </Typography>
      <Typography component="p" variant="caption" color="textSecondary">
        <strong>{translations.createdAt}:</strong> {dateTimeService.format(timeZonedDate)}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  emptyMessageDisplay: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5),
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.125),
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const creationMetaDataWidgetTranslations = t.creationMetaDataWidget || {}

  const { createdBy = defaults.createdBy, createdAt = defaults.createdAt } = creationMetaDataWidgetTranslations

  return {
    createdBy,
    createdAt,
  }
}

const defaultTranslations = {
  createdBy: 'Created by',
  createdAt: 'Created at',
}

type Translations = typeof defaultTranslations
type CreateMetaDataWidgetProps = {
  creatorName: string
  creationDate: string
}

export default CreationMetaDataWidget
