import { api } from '../../api'
import { NotificationChannel, NotificationType } from '../../notifications/notifications'
import { makeOrganisationApiEndpoints } from './endpoints'
import { CheckBillingSettingsViewModel, OrganisationViewModel, makeCheckBillingSettingsViewModel } from './organisation'
import { CheckBillingSettingsResponse } from './organisation-response'

const apiEndpoints = makeOrganisationApiEndpoints()

export const organisationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganisationById: builder.query<OrganisationViewModel, string>({
      query: (id) => apiEndpoints.organisationItem(id),
      providesTags: ['Organisation'],
    }),
    checkBillingSettings: builder.query<CheckBillingSettingsViewModel, string>({
      query: (id) => apiEndpoints.orgBillingSettingsCheck(id),
      transformResponse: (checkBillingSettings: CheckBillingSettingsResponse) => {
        return makeCheckBillingSettingsViewModel(checkBillingSettings)
      },
    }),
    updateOrgNotificationSettings: builder.mutation<
      OrganisationViewModel,
      { orgId: string } & OrgNotificationSettingsUpdateData
    >({
      query: ({ orgId, ...updateData }) => ({
        url: apiEndpoints.orgNotificationSettings(orgId),
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['Organisation'],
    }),
  }),
})

type NotificationUpdateAction = { type: NotificationType; channel: NotificationChannel }
export const { useGetOrganisationByIdQuery, useCheckBillingSettingsQuery, useUpdateOrgNotificationSettingsMutation } =
  organisationApi
export type OrgNotificationSettingsUpdateData = {
  enable: NotificationUpdateAction[]
  disable: NotificationUpdateAction[]
}
