import ReminderForm from './ReminderForm'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Tooltip from '@material-ui/core/Tooltip'
import { useReminderMutations } from '../hooks/use-reminder-mutations'
import { Check, Edit, Maximize2, MessageSquare, Trash, X } from 'react-feather'
import { ReminderViewModel } from '../api/reminder'
import { Chip, makeStyles, Theme } from '@material-ui/core'
import { COLOR_LIGHT } from '../../constants'
import { useAuthUserLocale } from '../../users/hooks/use-auth-user-locale'
import { DateTimeService } from '../../services/date-time-service'
import { useI18n } from '../../hooks'
import { useAuthUser } from '../../users/hooks/use-auth-user'

const Reminder = ({ reminder, refStartDate, refEndDate, isExpanded, onCollapse, onExpand }: ReminderProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { deleteReminder } = useReminderMutations()
  const { timeZone, dateFormat } = useAuthUserLocale()
  const { user } = useAuthUser()
  const dateTimeService = new DateTimeService({ timeZone, dateFormat, enableTimeComponent: true })
  const timeZonedReminderTime = reminder.reminderTime && dateTimeService.removeTimezoneOffset(reminder.reminderTime)
  const reminderTime = timeZonedReminderTime && dateTimeService.format(timeZonedReminderTime)
  const formattedReminderTime = reminderTime ? ` @ ${reminderTime}` : ''
  const reminderLabelTranslationKey = getNotificationLabelTranslationKey(reminder)
  const reminderPrimaryText = translations[reminderLabelTranslationKey].replace(
    '{status}',
    `${translations[reminder.triggerStatus ?? 'completed']}`
  )
  const sentAtTime = reminder.sentAt && dateTimeService.format(reminder.sentAt)
  const formattedSentAtTime = sentAtTime ? ` @ ${sentAtTime}` : ''
  const roleRecipients: string[] = reminder.recipients.roles.map((role) => translations[role].toLowerCase())
  const recipientsSummary = reminder.isPersonal
    ? user?.email
    : reminder.sentAt
    ? formatListToString(reminder.sentTo)
    : formatListToString([...roleRecipients, ...reminder.recipients.emails])
  const sentAtSummary =
    formattedSentAtTime && `${translations.notificationWasSentTo} ${recipientsSummary} ${formattedSentAtTime}`
  const reminderSummary = `${translations.notificationWillBeSentTo} ${recipientsSummary} ${formattedReminderTime}`

  if (isExpanded) {
    return (
      <Box className={classes.formContainer}>
        <IconButton
          className={classes.closeIcon}
          onClick={onCollapse}
          edge="end"
          aria-label="close editor"
          color="inherit"
        >
          <X />
        </IconButton>
        <ReminderForm reminder={reminder} refStartDate={refStartDate} refEndDate={refEndDate} />
      </Box>
    )
  }

  const secondary = (
    <div style={{ maxWidth: '80%', marginTop: 8 }}>
      {reminder.note ? (
        <div style={{ display: 'flex', marginBottom: 8, marginTop: 16 }}>
          <MessageSquare color="black" size={20} style={{ marginTop: 4, marginRight: 12 }} />
          <Typography variant="body1" color="textPrimary" gutterBottom>
            {reminder.note}
          </Typography>
        </div>
      ) : null}
      <Typography variant="body2">{reminder.sentAt ? sentAtSummary : reminderSummary}</Typography>
    </div>
  )

  return (
    <ListItem key={reminder.id} className={classes.reminderListItem} divider>
      <ListItemText primary={reminderPrimaryText} secondary={secondary} />
      <ListItemSecondaryAction>
        {reminder.isExpired && !reminder.sentAt ? (
          <Chip size="small" label={translations.expired} className={classes.expiredChip} />
        ) : null}
        {reminder.sentAt ? <Chip size="small" label={translations.sent} icon={<Check />} color="primary" /> : null}
        <Tooltip title={reminder.sentAt ? '' : translations.editNotification}>
          <IconButton onClick={onExpand} edge="end" aria-label={translations.editNotification} color="inherit">
            {reminder.sentAt ? <Maximize2 size={20} /> : <Edit size={20} />}
          </IconButton>
        </Tooltip>
        <Tooltip title={translations.deleteNotification}>
          <IconButton
            onClick={() => deleteReminder(reminder.id)}
            aria-label={translations.deleteNotification}
            color="inherit"
            edge="end"
          >
            <Trash size={20} />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const defaultTranslations = {
  expired: 'Expired',
  sent: 'Sent',
  notificationWasSentTo: 'Notification was sent to',
  notificationWillBeSentTo: 'Notification will be sent to',
  deleteNotification: 'Delete notification',
  editNotification: 'Edit notification',
  relativeToPlannedEndNotificationLabel: 'Notification relative to planned end',
  relativeToPlannedStartNotificationLabel: 'Notification relative to planned start',
  absoluteNotificationLabel: 'Notification at specific time',
  statusNotificationLabel: 'Notification when status changes to {status}',
  completed: 'completed',
  inProgress: 'in progress',
  projectManager: 'Project manager',
  projectParticipants: 'Project participants',
  taskManager: 'Task responsible',
  taskParticipants: 'Task participants',
  managerOfParentTask: 'Responsible of parent task',
  managersOfDependentTasks: 'Responsible of dependent tasks',
  calendarEventParticipants: 'Calendar event participants',
}
const useTranslations = (defaults = defaultTranslations): Translations => {
  const { t } = useI18n('translation')

  return {
    expired: t('expired', defaults.expired),
    sent: t('sent', defaults.sent),
    notificationWasSentTo: t('notificationWasSentTo', defaults.notificationWasSentTo),
    notificationWillBeSentTo: t('notificationWillBeSentTo', defaults.notificationWillBeSentTo),
    deleteNotification: t('deleteNotification', defaults.deleteNotification),
    editNotification: t('editNotification', defaults.editNotification),
    relativeToPlannedEndNotificationLabel: t(
      'relativeToPlannedEndNotificationLabel',
      defaults.relativeToPlannedEndNotificationLabel
    ),
    relativeToPlannedStartNotificationLabel: t(
      'relativeToPlannedStartNotificationLabel',
      defaults.relativeToPlannedStartNotificationLabel
    ),
    absoluteNotificationLabel: t('absoluteNotificationLabel', defaults.absoluteNotificationLabel),
    statusNotificationLabel: t('statusNotificationLabel', defaults.statusNotificationLabel),
    completed: t('completed', defaults.completed),
    inProgress: t('inProgress', defaults.inProgress),
    projectManager: t('projectManager', defaults.projectManager),
    projectParticipants: t('projectParticipants', defaults.projectParticipants),
    taskManager: t('taskManager', defaults.taskManager),
    taskParticipants: t('taskParticipants', defaults.taskParticipants),
    managerOfParentTask: t('managerOfParentTask', defaults.managerOfParentTask),
    managersOfDependentTasks: t('managersOfDependentTasks', defaults.managersOfDependentTasks),
    calendarEventParticipants: t('calendarEventParticipants', defaults.calendarEventParticipants),
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    position: 'relative',
  },
  closeIcon: {
    display: 'block',
    marginLeft: 'auto',
    position: 'absolute',
    top: 16,
    right: 40,
    zIndex: 1,
  },
  reminderListItem: {
    background: COLOR_LIGHT,
    marginBottom: 4,
    borderRadius: 4,
    '& .MuiListItemText-primary': {
      fontWeight: 500,
    },
  },
  expiredChip: {
    background: theme.palette.error.main,
    color: 'white',
    marginRight: 8,
  },
}))

const getNotificationLabelTranslationKey = (reminder: ReminderViewModel) => {
  if (reminder.isReminderTimeRelativeToPlannedEnd) {
    return 'relativeToPlannedEndNotificationLabel'
  } else if (reminder.isReminderTimeRelativeToPlannedStart) {
    return 'relativeToPlannedStartNotificationLabel'
  } else if (reminder.isAbsoluteTimeReminder) {
    return 'absoluteNotificationLabel'
  }
  return 'statusNotificationLabel'
}

const formatListToString = (list: string[]): string => {
  const listLength = list.length
  if (listLength === 0) return ''
  if (listLength === 1) return list[0]
  const allButLast = list.slice(0, -1).join(', ')
  const last = list[listLength - 1]
  return `${allButLast} & ${last}`
}

export default Reminder

type ReminderProps = {
  reminder: ReminderViewModel
  refStartDate: Date | null
  refEndDate: Date | null
  isExpanded: boolean
  onCollapse: () => void
  onExpand: () => void
}

type Translations = typeof defaultTranslations
