import React from 'react'
import ProjectList from '../treegrid/project-list'
import ProjectsViewHeader from '../projects/components/ProjectsViewHeader'
import { CircularProgress, Theme, makeStyles } from '@material-ui/core'
import { useAppContext } from '../hooks/use-app-context'
import { useFirstDayOfWeek } from '../hooks/use-first-day-of-week'
import { useRouter } from '../hooks/use-router'
import { useUserTimeZone } from '../hooks/use-user-time-zone'
import { useAllOptions } from '../options/hooks/use-all-options'
import { getOptionsForMainContext } from '../options/utils'
import { ProjectsViewOptions } from '../projects/components/ProjectsViewSelect'
import { ProjectColumnName } from '../treegrid/project-list/types'
import { useDateFormat } from '../users/hooks/use-date-format'
import { ProjectsQueryParams, useGetProjectsQuery } from '../projects/api'
import { getProjectQueryFromContext } from '../context-options/utils'
import { AppMainContextType, AppSubContextType } from '../types/app-context'
import { Status, StringMap } from '../types/common'
import { useI18n } from '../hooks/use-i18n'
import ErrorList from '../components/ErrorList'
import { useWeekendDays } from '../hooks/use-weekend-days'

const defaultColumnOrder: ProjectColumnName[] = [
  'open',
  'customers',
  'projectNumber',
  'title',
  'description',
  'managers',
  'participants',
  'workspaces',
  'suppliers',
  'plannedStartDate',
  'plannedEndDate',
  'actualStartDate',
  'actualEndDate',
  'actualBarStartDate',
  'actualBarEndDate',
  'status',
  'state',
  'statusDescription',
  'rejectedDate',
  'ganttBarColor',
  'ganttGanttHtmlRight',
  'ganttRun',
]

const defaultVisibleColumnsByView: Record<ProjectsViewOptions, ProjectColumnName[]> = {
  currentAndFuture: [
    'open',
    'customers',
    'projectNumber',
    'title',
    'managers',
    'plannedStartDate',
    'plannedEndDate',
    'status',
    'state',
    'statusDescription',
  ],
  all: [
    'open',
    'customers',
    'projectNumber',
    'title',
    'managers',
    'plannedStartDate',
    'plannedEndDate',
    'status',
    'state',
    'statusDescription',
  ],
  completed: [
    'open',
    'customers',
    'projectNumber',
    'title',
    'managers',
    'actualStartDate',
    'actualEndDate',
    'state',
    'statusDescription',
  ],
  archived: [
    'open',
    'customers',
    'projectNumber',
    'title',
    'managers',
    'plannedStartDate',
    'plannedEndDate',
    'status',
    'state',
    'statusDescription',
  ],
  rejected: ['open', 'customers', 'projectNumber', 'title', 'managers', 'rejectedDate', 'state', 'statusDescription'],
}

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectsRoute = () => {
  const translations = useTranslations()
  const { dateFormat, dateSeparator } = useDateFormat()
  const userTimeZone = useUserTimeZone()
  const firstDayOfWeek = useFirstDayOfWeek()
  const weekendDays = useWeekendDays()
  const router = useRouter()
  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext
  const { view = 'currentAndFuture' } = (router.query || {}) as { view?: ProjectsViewOptions }
  const query = makeProjectsQuery(context, view)
  const { data: projects = [], isLoading, isError, status } = useGetProjectsQuery(query, queryOptions)
  const showArchivedTitle = view === 'archived'
  const classes = useStyles()
  const { options } = useAllOptions()
  const [key, setKey] = React.useState(JSON.stringify(query))

  React.useEffect(() => {
    if (status === 'fulfilled') {
      // Need to recreate the treegrid when the query is changed and the data is completed refetching (status = fulfilled)
      setKey(JSON.stringify(query))
    }
  }, [status, query])

  if (isLoading) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={[translations.fetchErrorProjects]} />

  return (
    <div className={classes.viewContainer}>
      <ProjectsViewHeader titlePrefix={context.name} showArchivedTitle={showArchivedTitle} />
      <ProjectList
        key={key}
        projects={projects}
        dateFormat={dateFormat}
        timeZone={userTimeZone}
        options={getOptionsForMainContext(options, mainContext)}
        gridInfo={`${context.name} - Projects`}
        dateSeparator={dateSeparator}
        firstDayOfWeek={firstDayOfWeek}
        defaultColumnOrder={defaultColumnOrder}
        defaultVisibleColumns={defaultVisibleColumnsByView[view]}
        weekendDays={weekendDays}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  viewContainer: {
    marginTop: theme.spacing(0.75),
    '& > :first-child': {
      marginBottom: theme.spacing(1.125),
    },
  },
}))

const makeProjectsQuery = (
  context: AppMainContextType | AppSubContextType | null,
  view: ProjectsViewOptions
): ProjectsQueryParams => {
  if (view === 'completed') {
    return {
      archived: 'false',
      status: [Status.COMPLETED],
      ...getProjectQueryFromContext(context),
    }
  }

  if (view === 'archived') {
    return {
      archived: 'true',
      ...getProjectQueryFromContext(context),
    }
  }

  if (view === 'rejected') {
    return {
      archived: 'false',
      status: [Status.REJECTED],
      ...getProjectQueryFromContext(context),
    }
  }

  if (view === 'currentAndFuture') {
    return {
      archived: 'false',
      status: [Status.NOT_STARTED, Status.IN_PROGRESS],
      ...getProjectQueryFromContext(context),
    }
  }

  return getProjectQueryFromContext(context)
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const translations = t || ({} as StringMap)

  const { contextNotFoundError = defaults.contextNotFoundError, fetchErrorProjects = defaults.fetchErrorProjects } =
    translations

  return {
    contextNotFoundError,
    fetchErrorProjects,
  }
}

const defaultTranslations = {
  contextNotFoundError: 'Context not found',
  fetchErrorProjects: 'Failed to fetch projects',
}
type Translations = typeof defaultTranslations

export default ProjectsRoute
