import { TreeGridColors } from '../treegrid/types'

// Style related constants
export const SANS_FONT_FAMILY = "'Manrope', 'Helvetica', 'Arial', sans-serif"
export const COLOR_BLACK = '#000000'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_PRIMARY = '#1675b3'
export const COLOR_SECONDARY = '#43a047'
export const COLOR_INFO = '#2A93FA'
export const COLOR_ERROR = '#ff3333'
export const COLOR_WARNING = '#D9A21B'
export const COLOR_GRAY = '#8C7388'
export const COLOR_DIVIDER = '#E8E7ED'
export const COLOR_LIGHT = '#F6F5FF'
export const COLOR_LOW_CONTRAST_NEUTRAL = '#B8B6C7'
export const COLOR_DARK_100 = '#454B75'

// Other consnts

export const REFRESH_TOKEN = 'refresh_token'
export const ACCESS_TOKEN = 'access_token'
export const ACCESS_TOKEN_EXPIRES = 'access_token_expires'
export const SIDE_PANEL_WIDTH = 300
export const TOOLBAR_HEIGHT_SM_UP = 64
export const TOOLBAR_HEIGHT_SM_DOWN = 56
export const APP_MAX_WIDTH = 1440
export const DEFAULT_ACCESS_TOKEN_EXPIRY = 1000

// Regex

export const ISO_DATE_REGEX = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/
export const MONGODB_ID_REGEX = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i

// Day of week
export const SUNDAY: DayOfWeek = 0
export const MONDAY: DayOfWeek = 1
export const TUESDAY: DayOfWeek = 2
export const WEDNESDAY: DayOfWeek = 3
export const THURSDAY: DayOfWeek = 4
export const FRIDAY: DayOfWeek = 5
export const SATURDAY: DayOfWeek = 6

export const treeGridColors: TreeGridColors[] = [
  'Default',
  'Aqua',
  'Black',
  'Blue',
  'Fuchsia',
  'Gray',
  'Green',
  'Lime',
  'Maroon',
  'Navy',
  'Olive',
  'Orange',
  'Purple',
  'Red',
  'Silver',
  'Teal',
  'White',
  'Yellow',
]

export const treeGridColorsMap: Record<TreeGridColors, string> = {
  Default: '#0288D1',
  Aqua: '#23D2D0',
  Black: '#000000',
  Blue: '#2196F3',
  Fuchsia: '#94067E',
  Gray: '#515151',
  Green: '#51AB09',
  Lime: '#44CC44',
  Maroon: '#951212',
  Navy: '#045CA1',
  Olive: '#9AA659',
  Orange: '#F39B10',
  Purple: '#CF12B2',
  Red: '#CF1212',
  Silver: '#808080',
  Teal: '#11B480',
  White: '#CCCCCC',
  Yellow: '#E8D10F',
}
