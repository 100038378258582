import {
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { StringMap } from 'i18next'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Trash, Sliders, MoreVertical, Bell } from 'react-feather'
import BoardColumnChooser from '../../boards/components/BoardColumnChooser'
import { COLOR_BLACK, COLOR_LIGHT } from '../../constants'
import { useConfirmDialog } from '../../components/ConfirmDialog'
import InlineEditableText from '../../components/InlineEditableText'
import { useI18n, useRouter } from '../../hooks'
import TaskNavBreadCrumbs from './TaskNavBreadCrumbs'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { paths } from '../../paths'
import { Menu as MenuIcon } from 'react-feather'
import { TaskViewModel } from '../api/task'
import { useTaskMutations } from '../hooks/use-task-mutations'
import { useGetTasksQuery } from '../api'

const TaskNavBar = ({ task, isFullWidth = false, translations = defaultTaskNavbarTranslations }: TaskNavBarProps) => {
  const classes = useStyles({ isFullWidth })
  const router = useRouter()
  const { createPathWithContext } = useUrlWithContext()
  const { updateInfo, deleteTask } = useTaskMutations()
  const [isDeleted, setIsDeleted] = React.useState(false)
  const { status } = useGetTasksQuery({ project: task.projectId })

  const { enqueueSnackbar } = useSnackbar()
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [navAnchorEl, setNavAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const closeMenu = () => setMenuAnchorEl(null)
  const closeNav = () => setNavAnchorEl(null)
  const tabValue = getActiveTabIndex(router.pathname)

  React.useEffect(() => {
    if (status === 'fulfilled' && isDeleted) {
      enqueueSnackbar(translations.deleteTaskSuccessText, { autoHideDuration: 3000 })
      const tasksRoute = createPathWithContext(`/projects/${task.projectId}/tasks`)
      return router.push(tasksRoute)
    }
  }, [
    createPathWithContext,
    enqueueSnackbar,
    isDeleted,
    router,
    status,
    task.projectId,
    translations.deleteTaskSuccessText,
  ])

  const handleDeleteTask = async () => {
    closeMenu()
    await deleteTask(task.id)
      .then((deleteResult) => {
        if (!deleteResult) throw new Error('Failed to delete task')
        setIsDeleted(true)
      })
      .catch((error) => {
        enqueueSnackbar(translations.deleteTaskFailureText, {
          variant: 'error',
        })
      })
  }

  const { confirm: confirmDeleteTask } = useConfirmDialog({
    onCancel: () => closeMenu(),
    onConfirm: handleDeleteTask,
    text: translations.deleteTaskConfirmText,
    title: translations.deleteTaskConfirmTitle,
    primaryActionButtonLabel: translations.deleteTaskBtnLabel,
  })

  const goToPermissions = () => {
    closeMenu()
    router.push(permissionsRoute)
  }

  const basicRoute = createPathWithContext(paths.taskBasic(task.id))
  const tasksRoute = createPathWithContext(paths.taskSubtasks(task.id))
  const kanbanRoute = createPathWithContext(paths.taskKanban(task.id))
  const calendarRoute = createPathWithContext(paths.taskCalendar(task.id))
  const permissionsRoute = createPathWithContext(paths.taskPermissions(task.id))
  const todosRoute = `${createPathWithContext(paths.taskTodos(task.id))}&view=all`
  const notificationsRoute = createPathWithContext(paths.taskNotifications(task.id))

  const menuItems = [
    {
      id: 'manageNotifications',
      visible: task.canRead,
      primaryText: translations.notificationsLabel,
      onClick: () => router.push(notificationsRoute),
      icon: <Bell size={18} color={COLOR_BLACK} />,
    },
    {
      id: 'managePermissionsButton',
      visible: task.canUpdatePermissions,
      primaryText: translations.managePermissionsBtnLabel,
      onClick: goToPermissions,
      icon: <Sliders size={18} color={COLOR_BLACK} />,
    },
    {
      id: 'deleteTaskButton',
      visible: task.canDelete,
      primaryText: translations.deleteTaskBtnLabel,
      secondaryText: translations.deleteTaskSecondaryText,
      onClick: confirmDeleteTask,
      icon: <Trash size={18} color={COLOR_BLACK} />,
    },
  ].filter((item) => item.visible)

  const isSmallScreen = useMediaQuery(() => '(max-width:1300px)')

  return (
    <>
      <div className={classes.stickyFlexContainer}>
        <Grid container wrap="nowrap" alignItems="center" className={classes.navbarContainer}>
          <Grid container item alignItems="center" className={classes.infoContainer}>
            <Grid item>
              <TaskNavBreadCrumbs task={task} />
            </Grid>
            <Grid item>
              <span className={classes.divider} />
            </Grid>
            <Grid item>
              <InlineEditableText
                isEditable={task.canUpdateDetails}
                value={task.customTaskNumber || task.taskNumber}
                onSave={(customTaskNumber) => updateInfo(task.id, { customTaskNumber })}
                TypographyProps={{ variant: 'subtitle1' }}
              />
            </Grid>
            <Grid item>
              <span className={classes.divider} />
            </Grid>
            <Grid item>
              <InlineEditableText
                isEditable={task.canUpdateDetails}
                value={task.title}
                onSave={(title) => updateInfo(task.id, { title })}
                TypographyProps={{ variant: 'subtitle1' }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              item
              container
              alignItems="flex-start"
              justifyContent="flex-end"
              className={classes.tabsContainer}
              wrap="nowrap"
            >
              {isSmallScreen ? (
                <Grid item className={classes.iconMenus}>
                  <IconButton
                    size="small"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      setNavAnchorEl(navAnchorEl ? null : e.currentTarget)
                    }}
                  >
                    <MenuIcon size={16} color={COLOR_BLACK} />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item>
                  <Tabs className={classes.tabs} value={tabValue} indicatorColor="primary" textColor="primary">
                    <Tooltip title={translations.basicInfoTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.basicInfoTabLabel}
                        onClick={() => router.push(basicRoute)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.tasksTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.tasksTabLabel}
                        onClick={() => router.push(tasksRoute)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.todoListTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.todoListTabLabel}
                        onClick={() => router.push(todosRoute)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.calendarTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.calendarTabLabel}
                        onClick={() => router.push(calendarRoute)}
                      />
                    </Tooltip>
                    <Tooltip title={translations.kanbanTabLabel}>
                      <Tab
                        className={classes.tab}
                        label={translations.kanbanTabLabel}
                        onClick={() => router.push(kanbanRoute)}
                      />
                    </Tooltip>
                  </Tabs>
                </Grid>
              )}
              <Grid container item wrap="nowrap" className={classes.iconMenus}>
                {isKanbanRoute(router.pathname) && (
                  <Grid item>
                    <BoardColumnChooser boardId={task.id} boardType="task" buttonVariant="iconButton" />
                  </Grid>
                )}
                {Boolean(menuItems.length) && (
                  <Tooltip title={translations.menuAriaLabel}>
                    <Grid item>
                      <IconButton
                        aria-label={translations.menuAriaLabel}
                        size="small"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          setMenuAnchorEl(menuAnchorEl ? null : e.currentTarget)
                        }}
                      >
                        <MoreVertical size={16} color={COLOR_BLACK} />
                      </IconButton>
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Menu
        id="task-burger-menu"
        anchorEl={navAnchorEl}
        open={Boolean(navAnchorEl)}
        onClose={closeNav}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant="selectedMenu"
        disablePortal
        keepMounted
      >
        <Tooltip title={translations.basicInfoTabLabel}>
          <MenuItem
            onClick={() => router.push(basicRoute)}
            selected={isBasicRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.basicInfoTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.tasksTabLabel}>
          <MenuItem
            onClick={() => router.push(tasksRoute)}
            selected={isSubtasksRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.tasksTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.todoListTabLabel}>
          <MenuItem
            onClick={() => router.push(todosRoute)}
            selected={isTodoListRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.todoListTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.calendarTabLabel}>
          <MenuItem
            onClick={() => router.push(calendarRoute)}
            selected={isCalendarRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.calendarTabLabel}
          </MenuItem>
        </Tooltip>
        <Tooltip title={translations.kanbanTabLabel}>
          <MenuItem
            onClick={() => router.push(kanbanRoute)}
            selected={isKanbanRoute(router.pathname)}
            className={classes.navListItem}
          >
            {translations.kanbanTabLabel}
          </MenuItem>
        </Tooltip>
      </Menu>

      <Menu
        id="project-three-dotted-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant="menu"
        disablePortal
        keepMounted
      >
        {menuItems.map((menuItem: any) => {
          const { icon, primaryText, secondaryText = null, ...listItemProps } = menuItem
          return (
            <ListItem key={menuItem.id} {...listItemProps} button className={classes.menuListItem}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItem>
          )
        })}
      </Menu>
    </>
  )
}

const defaultTaskNavbarTranslations = {
  menuAriaLabel: 'Open menu',
  managePermissionsBtnLabel: 'Manage Permissions',
  archiveTaskBtnLabel: 'Archive task',
  deleteTaskBtnLabel: 'Delete task',
  deleteTaskSecondaryText: 'Permanently removes the task from database.',
  basicInfoTabLabel: 'Basic info',
  tasksTabLabel: 'Tasks',
  kanbanTabLabel: 'Kanban',
  todoListTabLabel: 'To-dos',
  calendarTabLabel: 'Calendar',
  noStartDate: 'No start',
  noEndDate: 'No end',
  addStartDate: 'Add start',
  addEndDate: 'Add end',
  planTitle: 'Schedule:',
  enableTimeComponentLabel: 'Enable scheduling in hours and minutes',
  deleteTaskSuccessText: 'Task deleted, redirecting . . .',
  deleteTaskFailureText: 'Failed to delete task',
  deleteTaskConfirmTitle: 'Delete this task',
  deleteTaskConfirmText:
    'Continuing this action will delete all data linked with this task including all subtasks. After this it can not be restored',
  archiveTaskSuccessText: 'Task archived',
  archiveTaskFailureText: 'Failed to archive task',
  archiveTaskConfirmTitle: 'Archive this task',
  archiveTaskConfirmText:
    'Archiving this task also archives all the resources linked with this task. However you can unarchive task later',
  boardMenuAriaLabel: 'Open board menu',
  boardMenuSubHeader: "Board column's visibility",
  backButtonLabel: 'Go to project',
  notificationsLabel: 'Notifications',
}

const TaskNavBarWithI18n = (props: TaskNavBarProps) => {
  const { translations: t } = useI18n('task')
  const translations = (t?.taskNavBar || {}) as StringMap

  const appNavBarTranslations = useI18n('translation').translations?.appNavBar || {}
  const {
    tasksLabel = defaultTaskNavbarTranslations.tasksTabLabel,
    todosLabel = defaultTaskNavbarTranslations.todoListTabLabel,
    notificationsLabel = defaultTaskNavbarTranslations.notificationsLabel,
  } = appNavBarTranslations

  const {
    managePermissionsBtnLabel = defaultTaskNavbarTranslations.managePermissionsBtnLabel,
    archiveTaskBtnLabel = defaultTaskNavbarTranslations.archiveTaskBtnLabel,
    deleteTaskBtnLabel = defaultTaskNavbarTranslations.deleteTaskBtnLabel,
    deleteTaskSecondaryText = defaultTaskNavbarTranslations.deleteTaskSecondaryText,
    basicInfoTabLabel = defaultTaskNavbarTranslations.basicInfoTabLabel,
    kanbanTabLabel = defaultTaskNavbarTranslations.kanbanTabLabel,
    noStartDate = defaultTaskNavbarTranslations.noStartDate,
    noEndDate = defaultTaskNavbarTranslations.noEndDate,
    addStartDate = defaultTaskNavbarTranslations.addStartDate,
    addEndDate = defaultTaskNavbarTranslations.addEndDate,
    planTitle = defaultTaskNavbarTranslations.planTitle,
    enableTimeComponentLabel = defaultTaskNavbarTranslations.enableTimeComponentLabel,
    deleteTaskSuccessText = defaultTaskNavbarTranslations.deleteTaskSuccessText,
    deleteTaskFailureText = defaultTaskNavbarTranslations.deleteTaskFailureText,
    deleteTaskConfirmTitle = defaultTaskNavbarTranslations.deleteTaskConfirmTitle,
    deleteTaskConfirmText = defaultTaskNavbarTranslations.deleteTaskConfirmText,
    archiveTaskSuccessText = defaultTaskNavbarTranslations.archiveTaskSuccessText,
    archiveTaskFailureText = defaultTaskNavbarTranslations.archiveTaskFailureText,
    archiveTaskConfirmTitle = defaultTaskNavbarTranslations.archiveTaskConfirmTitle,
    archiveTaskConfirmText = defaultTaskNavbarTranslations.archiveTaskConfirmText,
    boardMenuAriaLabel = defaultTaskNavbarTranslations.boardMenuAriaLabel,
    boardMenuSubHeader = defaultTaskNavbarTranslations.boardMenuSubHeader,
    calendarTabLabel = defaultTaskNavbarTranslations.calendarTabLabel,
    menuAriaLabel = defaultTaskNavbarTranslations.menuAriaLabel,
    backButtonLabel = defaultTaskNavbarTranslations.backButtonLabel,
  } = translations

  return (
    <TaskNavBar
      {...props}
      translations={{
        menuAriaLabel,
        managePermissionsBtnLabel,
        archiveTaskBtnLabel,
        deleteTaskBtnLabel,
        deleteTaskSecondaryText,
        basicInfoTabLabel,
        tasksTabLabel: tasksLabel,
        kanbanTabLabel,
        todoListTabLabel: todosLabel,
        calendarTabLabel,
        noStartDate,
        noEndDate,
        addStartDate,
        addEndDate,
        planTitle,
        enableTimeComponentLabel,
        deleteTaskSuccessText,
        deleteTaskFailureText,
        deleteTaskConfirmTitle,
        deleteTaskConfirmText,
        archiveTaskSuccessText,
        archiveTaskFailureText,
        archiveTaskConfirmTitle,
        archiveTaskConfirmText,
        boardMenuAriaLabel,
        boardMenuSubHeader,
        backButtonLabel,
        notificationsLabel,
      }}
    />
  )
}

const isBasicRoute = (pathname: string) => pathname.includes('basic')
const isSubtasksRoute = (pathname: string) => pathname.includes('subtasks')
const isKanbanRoute = (pathname: string) => pathname.includes('kanban')
const isTodoListRoute = (pathname: string) => pathname.includes('todos')
const isCalendarRoute = (pathname: string) => pathname.includes('calendar')

const getActiveTabIndex = (pathname: string) => {
  if (isBasicRoute(pathname)) return 0
  if (isSubtasksRoute(pathname)) return 1
  if (isTodoListRoute(pathname)) return 2
  if (isCalendarRoute(pathname)) return 3
  if (isKanbanRoute(pathname)) return 4
  throw new Error('Invalid pathname')
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  infoContainer: { marginTop: -6 },
  menuListItem: { width: 314 },
  navListItem: {
    width: 240,
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  navbarContainer: {
    background: COLOR_LIGHT,
    width: ({ isFullWidth }) => (isFullWidth ? '100%' : 1440),
  },
  tabsContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  stickyFlexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: 1,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    height: 18,
  },
  tab: {
    maxWidth: 160,
    minWidth: 96,
    minHeight: 32,
    fontSize: 13,
    padding: `2px 12px`,

    '& .MuiTab-wrapper': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  tabs: {
    '& .MuiTabs-indicator': {
      top: 36,
    },
  },
  iconMenus: {
    paddingRight: 8,
    paddingTop: 6,
    '& button': {
      background: theme.palette.divider,
      borderRadius: 7,
      padding: 4,
      marginLeft: 8,
    },
  },
}))

type TaskNavBarProps = {
  task: TaskViewModel
  isFullWidth?: boolean
  translations?: typeof defaultTaskNavbarTranslations
}

type StyleProps = {
  isFullWidth: boolean
}

export default TaskNavBarWithI18n
