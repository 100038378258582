import React, { Ref } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import TextField from '@material-ui/core/TextField'
import Popper from '../../components/Popper'
import { Divider, makeStyles, Theme } from '@material-ui/core'
import { ChevronDown, X } from 'react-feather'
import { CalendarEventData, CalendarTaskData, CalendarTodoData } from '../utils'
import { StringMap } from '../../types/common'
import { useI18n } from '../../hooks'
import EventRecurringWidget from './EventRecurringWidget'

const CalendarForm = ({
  canAddCalendarEvents,
  canAddTasks,
  canAddTodos,
  endDate,
  startDate,
  onAddCalendarEvent,
  onAddAsTask,
  onAddAsTodo,
  closeForm,
  titleInputRef,
}: CalendarFormProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const [description, setDescription] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const [recurringEventEnabled, setRecurringEventEnabled] = React.useState<boolean>(false)
  const [recurringEventData, setRecurringEventData] = React.useState<{
    recurranceType: string | null
    recurranceEndDate: string | null
    startDate: string | null
    endDate: string | null
  }>({
    recurranceType: '',
    recurranceEndDate: null,
    startDate: null,
    endDate: null,
  })

  const buttonRef = React.useRef<HTMLButtonElement | null>(null)
  const isReadOnly = !canAddTasks && !canAddTodos && !canAddCalendarEvents
  const toggleMenu = () => setOpen((open) => !open)
  const closeMenu = () => setOpen(false)

  const reset = () => {
    setTitle('')
    setDescription('')
    setRecurringEventEnabled(false)
    setRecurringEventData({
      recurranceType: null,
      recurranceEndDate: null,
      startDate: null,
      endDate: null,
    })
    closeForm(true)
    closeMenu()
  }

  const handleAddCalendarEvent = () => {
    reset()
    if (!startDate) throw new Error('start date is required in calendar event')
    if (!endDate) throw new Error('end date is required in calendar event')
    const newCalendarEvent: CalendarEventData = {
      title,
      description,
      startDate,
      endDate,
    }
    if (recurringEventEnabled) {
      if (recurringEventData.startDate) newCalendarEvent.startDate = recurringEventData.startDate
      if (recurringEventData.endDate) newCalendarEvent.endDate = recurringEventData.endDate
      newCalendarEvent.recurranceType = recurringEventData.recurranceType
      newCalendarEvent.recurranceEndDate = recurringEventData.recurranceEndDate
    }
    onAddCalendarEvent(newCalendarEvent)
  }

  const handleAddTask = () => {
    reset()
    onAddAsTask({
      title,
      description,
      plannedEndDate: endDate || '',
      plannedStartDate: startDate || '',
    })
  }

  const handleAddTodo = () => {
    reset()
    onAddAsTodo({
      title,
      description,
      dueDate: endDate,
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    defaultSaveButton?.onClick()
  }

  const showAddTask = canAddTasks && !recurringEventEnabled
  const showAddTodo = canAddTodos && !recurringEventEnabled

  const saveMenuItems = [
    {
      id: 'add_as_event',
      label: translations.addAsEventBtnLabel,
      onClick: handleAddCalendarEvent,
      visible: canAddCalendarEvents,
    },
    {
      id: 'add_as_task',
      label: translations.addAsTaskBtnLabel,
      onClick: handleAddTask,
      visible: showAddTask,
    },
    {
      id: 'add_as_todo',
      label: translations.addAsTodoBtnLabel,
      onClick: handleAddTodo,
      visible: showAddTodo,
    },
  ]

  const visibleMenuItems = saveMenuItems.filter((item) => item.visible)
  const defaultSaveButton = visibleMenuItems[0]

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <IconButton onClick={reset} size="small">
          <X color="#616161" />
        </IconButton>
      </div>
      <form onSubmit={onSubmit}>
        <Input
          inputRef={titleInputRef}
          className={classes.titleInput}
          placeholder={translations.titlePlaceholder}
          value={title}
          onChange={({ target }) => setTitle(target.value)}
          disabled={isReadOnly}
        />
        <div className={classes.description}>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={translations.descriptionPlaceholder}
            variant="outlined"
            multiline
            fullWidth
          />
        </div>

        <Divider />
        {startDate && (
          <section className={classes.repeatEventSection}>
            <EventRecurringWidget
              isRecurringEvent={recurringEventEnabled}
              startDate={startDate}
              endDate={endDate}
              recurranceType={recurringEventData.recurranceType}
              recurranceEndDate={recurringEventData.recurranceEndDate}
              onChange={(props) => {
                setRecurringEventEnabled(Boolean(props.recurranceType))
                setRecurringEventData(props)
              }}
            />
          </section>
        )}
        <Divider />

        <ButtonGroup
          variant="contained"
          color="primary"
          disabled={isReadOnly}
          aria-label="split button"
          className={classes.buttonGroup}
        >
          {defaultSaveButton && (
            <Button type="submit" style={{ minWidth: 132 }}>
              {defaultSaveButton.label}
            </Button>
          )}
          {visibleMenuItems.length > 1 && (
            <Button
              ref={buttonRef}
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select event type"
              aria-haspopup="menu"
              onClick={toggleMenu}
            >
              <ChevronDown size={20} />
            </Button>
          )}
        </ButtonGroup>
      </form>
      <Popper
        open={open}
        onClose={closeMenu}
        anchorEl={buttonRef.current}
        popperOptions={{ placement: 'bottom-end' }}
        className={classes.popper}
      >
        <MenuList>
          {visibleMenuItems.map(({ id, label, onClick }) => (
            <MenuItem key={id} onClick={onClick}>
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Popper>
    </div>
  )
}

const useTranslations = (defaults: CalendarFormTranslations = defaultTranslations): CalendarFormTranslations => {
  const { translations: t } = useI18n('translation')
  const translations = (t?.calendarForm || {}) as StringMap
  const {
    addAsTaskBtnLabel = defaults.addAsTaskBtnLabel,
    addAsTodoBtnLabel = defaults.addAsTodoBtnLabel,
    addAsEventBtnLabel = defaults.addAsEventBtnLabel,
    titlePlaceholder = defaults.titlePlaceholder,
    descriptionPlaceholder = defaults.descriptionPlaceholder,
  } = translations

  return {
    addAsTaskBtnLabel,
    addAsTodoBtnLabel,
    addAsEventBtnLabel,
    titlePlaceholder,
    descriptionPlaceholder,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: { minWidth: 560, padding: theme.spacing(2, 3) },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleInput: {
    width: '100%',
    fontSize: 22,
    '& input::placeholder': {
      color: theme.palette.grey[900],
    },
  },
  description: { marginTop: 20, marginBottom: 20 },
  popper: {
    minWidth: 200,
    boxShadow: theme.shadows[5],

    '& .MuiListItem-button': {
      minWidth: 248,
    },
  },
  repeatEventSection: {
    padding: theme.spacing(1, 0),
  },
  repeatEventSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.grey[700],
  },
  buttonGroup: {
    marginTop: 20,
  },
}))

const defaultTranslations = {
  addAsTaskBtnLabel: 'Add as task',
  addAsTodoBtnLabel: 'Add as todo',
  addAsEventBtnLabel: 'Add as event',
  titlePlaceholder: 'Enter title',
  descriptionPlaceholder: 'Enter description',
}

type CalendarFormTranslations = typeof defaultTranslations

type CalendarFormProps = {
  canAddCalendarEvents: boolean
  canAddTodos: boolean
  canAddTasks: boolean
  startDate: string | null
  endDate: string | null
  closeForm: (bool: boolean) => void
  onAddCalendarEvent: (event: CalendarEventData) => void
  onAddAsTodo: (todo: CalendarTodoData) => void
  onAddAsTask: (task: CalendarTaskData) => void
  titleInputRef: Ref<HTMLInputElement | null>
  translations?: CalendarFormTranslations
}
export default CalendarForm
