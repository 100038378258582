import { Theme, makeStyles } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { LanguageSwitch } from '../../components/LanguageSwitch'

const InvitationEmailLanguageSwitch = ({
  shouldShowLanguageSwitch,
  language,
  onChangeLanguage,
  translations,
}: InvitationEmailLanguageSwitchProps) => {
  const classes = useStyles()
  if (!shouldShowLanguageSwitch) return null
  return (
    <div className={classes.invitationLanguageSwitch}>
      <LanguageSwitch onChangeLanguage={onChangeLanguage} value={language} />
      <Alert severity="info" data-test="invitation-language-info" variant="outlined">
        <AlertTitle>{translations.note}</AlertTitle>
        {translations.invitationLanguageDescription}
      </Alert>
    </div>
  )
}

type InvitationEmailLanguageSwitchProps = {
  language: string
  onChangeLanguage: (language: string) => void
  shouldShowLanguageSwitch: boolean
  translations: {
    invitationLanguageDescription: string
    note: string
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  invitationLanguageSwitch: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    '& .MuiAlert-root': {
      border: '1px solid #f0f0f0',
      width: '100%',
    },
  },
}))

export default InvitationEmailLanguageSwitch
